import React from 'react'
import TipNTapLogo from '../../assets/images/LeftSideImage.png'
import styles from "./LeftSideContent.module.css"

const LeftSideContent = () => {
  return (
    <div className={styles.logoContainer} >
      <div className={styles.logotapntip}>
      <img src={TipNTapLogo} alt='logo' style={{width:"100%", height:"100%"}} />
      </div>
    </div>
  )
}

export default LeftSideContent