import { createSlice } from "@reduxjs/toolkit";
import { refreshTokenAction } from "../../api/api";

const AuthRefreshTokenSlice = createSlice({
    name: 'auth',
    initialState: {
      accessToken: null,
      refreshToken: null,
      status: 'idle', 
      error: null,
    },
    reducers: {
      setTokens: (state, action) => {
        state.accessToken = action.payload.accessToken;
        state.refreshToken = action.payload.refreshToken;
      },
      logout: (state) => {
        state.accessToken = null;
        state.refreshToken = null;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(refreshTokenAction.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(refreshTokenAction.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.accessToken = action.payload.accessToken;
          state.refreshToken = action.payload.refreshToken;
        })
        .addCase(refreshTokenAction.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.payload;
        });
    },
  });
  
//   export const { setTokens, logout } = authSlice.actions;
  export default AuthRefreshTokenSlice.reducer;