// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.boxSizeSubHeader{
    width: 24%;
    margin-bottom: 25px;
    background-color: #fff;
    height: auto;
    border-radius: 8px; 
    box-shadow: 0px 4px 8px var(--box-shadow-bg-color);
}`, "",{"version":3,"sources":["webpack://./src/components/BoxSubHeader/boxsubheader.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,mBAAmB;IACnB,sBAAsB;IACtB,YAAY;IACZ,kBAAkB;IAClB,kDAAkD;AACtD","sourcesContent":[".boxSizeSubHeader{\n    width: 24%;\n    margin-bottom: 25px;\n    background-color: #fff;\n    height: auto;\n    border-radius: 8px; \n    box-shadow: 0px 4px 8px var(--box-shadow-bg-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
