import { createSlice } from '@reduxjs/toolkit';
import { getBankDetailParticularUser } from '../../api/api';

const GetBankDetailParticularUserSlice = createSlice({
  name: 'bank',
  initialState: {
    bankDetails: null,
    loading: false,
    error: null,
  },
  reducers: {
    resetBankState: (state) => {
      state.bankDetails = null;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBankDetailParticularUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getBankDetailParticularUser.fulfilled, (state, action) => {
        state.loading = false;
        state.bankDetails = action.payload;
      })
      .addCase(getBankDetailParticularUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetBankState } = GetBankDetailParticularUserSlice.actions;

export default GetBankDetailParticularUserSlice.reducer;
