import axios from 'axios';
import { BASE_URL_USER } from '../api/BaseURL/BaseURL';

const apiClient = axios.create({
  baseURL: `${BASE_URL_USER}`,
});

apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('accesstoken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const refreshToken = localStorage.getItem('refreshToken');

        const response = await axios.post(`${BASE_URL_USER}/api/v2/auth/refreshtoken`, {
          refresh_token: refreshToken,
        });
        const newAccessToken = response.data.access_token;
        localStorage.setItem('accesstoken', newAccessToken);
        originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
        return apiClient(originalRequest);
      } catch (refreshError) {
        localStorage.removeItem("accesstoken");
        window.location.reload();
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export default apiClient;
