import React from 'react';

const NotFound = () => {
  return (
    <div style={{ textAlign: 'center',backgroundColor:'#fff',height:'100vh',margin:'0 auto',paddingTop:'100px' }}>
      <h1 style={{fontSize:'25px',color:'red',fontWeight:'600'}}>404</h1>
      <p style={{fontSize:'35px', color:'red',fontWeight:'600'}}>Page Not Found</p>
    </div>
  );
};

export default NotFound;
