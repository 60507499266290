import React, {  useEffect, useState } from "react";
import SideBar from "../../components/SideBar/SideBar";
import Header from "../../components/Header/Header";
import Select from "react-select";
import "./managestaff.css";
import searchIcon from "../../assets/images/search.png";
import filterIcon from "../../assets/images/filtericon.png";
import sorticon from "../../assets/images/sorticon.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { activeInactiveStaffUserAction, getCommunityList, sendSMSOnMobileNOToCommunityMember,getCommunityStaffCount,getCommunity1staffTodayTipSummary } from "../../redux/api/api";
import SelectCommunity from "../../components/SelectCommunity/SelectCommunity";
import sendSMSIcon from "../../assets/images/sendSMS.png";
import userinvite from "../../assets/images/userinvite.png";
import upicon from "../../assets/images/Up.png"
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../App.css"
import "../TipHistory/tiphistory.css"
import Footer from "../../components/Footer/Footer";
import PopupDialog from "../../components/Popup/PopupDialog";
import AddUserForm from "../../components/UserForm/AddUserForm";
import DeviceBox from "../../components/BoxSubHeader/BoxSubHeader";

const ManageStaff = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const { dataList } = useSelector((state) => state.ListOfCommunity);
  const [filteredStaffList, setFilteredStaffList] = useState([]);
  const { data } = useSelector((state) => state.memberInfoStore);
  const accessToken = localStorage.getItem("accesstoken");
  const community_id1 = data?.communities[0]?.community_id ?? "NA";
  const community_id2 = localStorage.getItem("selectedCommunity");
  const community_id = community_id2 || community_id1;
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");    
  const [staffTipTodaySummary, setStaffTipTodaySummary] = useState();  
  const [staffCount, setStaffCount] = useState();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: 'name',
    direction: 'ascending',
  });
  const [sortDirection, setSortDirection] = useState("↑");
  const statusOptions = [
    { value: 'Active', label: 'Active' },
    { value: 'Inactive', label: 'Inactive' }
  ];
  const permissionOptions = [
    { value: "ADMIN_PORTAL_ACCESS", label: "Admin Portal Access" },
    { value: "COLLECT_TIP_ACCESS", label: "Collect Tip Access" },
  ];
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; 

  useEffect(() => {
    if (accessToken && community_id) {
      fetchstaffTodayData();
      fetchstaffTodayTipSummaryData();
    }
  }, [accessToken,community_id, dispatch]);

  const fetchstaffTodayData = async () => {
    try {
      const response = await dispatch(getCommunityStaffCount({ accessToken,community_id }));
      if (getCommunityStaffCount.fulfilled.match(response)) {
        setStaffCount(response?.payload);
      } else {
        throw new Error(response?.payload?.message || "Failed to fetch staff info");
      }
    } catch (error) {
      toast.error(error.detail);
      setErrorMessage(error.detail);
    }
  };
  
  const fetchstaffTodayTipSummaryData = async () => {
    try {
      const response = await dispatch(getCommunity1staffTodayTipSummary({ accessToken,community_id }));
      if (getCommunity1staffTodayTipSummary.fulfilled.match(response)) {
        setStaffTipTodaySummary(response?.payload);
      } else {
        throw new Error(response?.payload?.detail || "Failed to fetch staff tip info");
      }
    } catch (error) {
      toast.error(error.detail);
      setErrorMessage(error.detail);
    }
  };

  useEffect(() => {
    if(accessToken && community_id){
      dispatch(getCommunityList({ accessToken, community_id }));
    }
  }, [dispatch, accessToken, community_id]);
  
  useEffect(() => {
    if (dataList?.length > 0) {
      const staffList = dataList?.map((member) => {
        const community = member?.communities[0];
        return {
          member_id: member?.member_id,
          name: member?.name,
          qr_code_url: community?.qr_code_url,
          mobile_number: member?.mobile_number,
          email: member?.email,
          permission: community?.permission || [],
          display_permission:community?.display_permission||[],
          shift: community?.shift || [],
          functional_role: community?.functional_role || "N/A",
          status: community?.status,
          // community_id: community_id,
          community_id:community_id
        };
      });
      const sortedStaffList = sortStaffList(staffList, sortConfig.key, sortConfig.direction);
      setFilteredStaffList(sortedStaffList);
    }
  }, [dataList, sortConfig]);

  const sortStaffList = (staffList, key, direction) => {
    return [...staffList].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === '↑' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === '↑' ? 1 : -1;
      }
      return 0;
    });
  };
  const handleSort = (field) => {
    const direction = sortDirection === "↑" ? "↓" : "↑";

    const sortedData = [...filteredStaffList].sort((a, b) => {
      if (a[field] < b[field]) return direction === "↑" ? -1 : 1;
      if (a[field] > b[field]) return direction === "↑" ? 1 : -1;
      return 0;
    });
    setFilteredStaffList(sortedData);
    setSortDirection(direction);
  };

  const rollbackStatus = (staffId) => {
    setFilteredStaffList((prevList) =>
      prevList.map((staff) =>
        staff?.member_id === staffId ? { ...staff, status: !staff?.status } : staff
      )
    );
  };

  const handleNameClick = (staff) => {
    navigate("/tip-history", { state: { staff } });
  };

  useEffect(() => {
    const filteredList = dataList
      .map((member) => {
        const community = member.communities[0];
        return {
          member_id: member?.member_id,
          name: member?.name,
          qr_code_url: community?.qr_code_url,
          profile_url:member?.profile_url,
          mobile_number: member?.mobile_number,
          email: member?.email,
          permission: community?.permission || [],
          display_permission: community?.display_permission||[],
          shift: community?.shift || [],
          functional_role: community?.functional_role || "N/A",
          status: community?.status,
          community_id: community_id1,
          // community_id:community_id
        };
      })
      .filter((staff) => {
        const matchesSearchTerm =
          staff?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          staff?.mobile_number?.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesStatusFilter =
          selectedStatuses?.length === 0 ||
          selectedStatuses?.some((status) =>
            (staff?.status && status?.value === "Active") || (!staff?.status && status?.value === "Inactive")
          );
        const matchesPermissionFilter =
          selectedPermissions?.length === 0 ||
          selectedPermissions?.every((permission) =>
            staff.permission?.includes(permission.value)
          );

        return matchesSearchTerm && matchesStatusFilter && matchesPermissionFilter;
      });
    setFilteredStaffList(filteredList);
  }, [dataList, selectedStatuses, selectedPermissions, searchTerm]);

  const handleStatusChange = (selectedOptions) => {
    setSelectedStatuses(selectedOptions || []);
  };
  
  const handleChangeStatus = async (staffId) => {
    const selectedStaff = filteredStaffList.find((staff) => staff.member_id === staffId);
    if (!accessToken || !community_id || !selectedStaff?.member_id) {
      toast.error("Missing required data for status update.");
      return;
    }

    setFilteredStaffList((prevList) =>
      prevList.map((staff) =>
        staff?.member_id === staffId ? { ...staff, status: !staff?.status } : staff));
    try {
      const result = await dispatch(
        activeInactiveStaffUserAction({
          accessToken,
          community_id,
          member_id: selectedStaff?.member_id,
        }));
      if (activeInactiveStaffUserAction.fulfilled.match(result)) {
        toast.success("Status updated successfully");
      } else {
        toast.error("Error updating status: "+ result.payload);   
        rollbackStatus(staffId);
      }
    } catch (error) {
      rollbackStatus(staffId);
    }
  };

  const handlePermissionChange = (selectedOptions) => {
    setSelectedPermissions(selectedOptions || []); 
  };

  const sendSMSToCommunityMember = async (mobileNo) => {
    try {
      const response = await dispatch(
        sendSMSOnMobileNOToCommunityMember({
          accessToken,
          mobileNo
        }));
      if (response.error) {
        toast.error("Failed to send SMS.");
        setErrorMessage("Failed to send SMS.");
        return;
      }
      toast.success("Link sended successfully");
    } catch (error) {
      toast.error("An unexpected error occurred.");
      setErrorMessage("An unexpected error occurred.");
    }
  };

  const handleHomeClick = () => {
    navigate("/dashboard");
  };
  const handleClear = () => {
    setSearchTerm("");
  };

  const LoginUserData = filteredStaffList.find((item) => item.mobile_number === data?.mobile_number)

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentListStaff = filteredStaffList.slice(
    indexOfFirstItem,
    indexOfLastItem);
  const totalPages = Math.ceil(filteredStaffList.length / itemsPerPage);

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handleOpenDialog = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handleCloseDialog = () => {
    setIsPopupOpen(false);
  };

  return (
    <>
    {dataList ? 
    <>
    <div className="StaffMainPage mainLayoutPage">
      <ToastContainer />
      <SideBar />
      <div className="dashboard-container borderFormat">
        <div className="header">
          <div >
            <div className="titleheading-content">Staff
              <div className="subtitle">
                <div className="home-text" onClick={handleHomeClick}>
                  <img src={upicon} alt="IconUp" className="home-text"/> Home /</div>
                <div className="current-page-text">Staff</div>
              </div>
            </div>
            <div className="tabletScreenHeader">
              <Header />
            </div>
          </div>
          <div className="dropdownMain mainDropdownTablet">
            <div className="mediumScreenHeader">

              <div className="header-button" > <SelectCommunity /></div>
              <button className="onboard-button" onClick={handleOpenDialog}>
                <span>Onboard New Staff</span>
                <img src={userinvite} alt="userIcon" />
              </button>
            </div>
          </div>
        </div>

        <div className="top-line"></div>
        <div className="staffTableList">
          <div style={{display:'flex', justifyContent:'space-between'}}>
          <DeviceBox header="Total Staff" subHeading="This Community"  number={staffCount?.total_staff || 0} className="staffHeadSub" width="19%"/>
          <DeviceBox header="Active Staff" subHeading="This Community"  number={staffCount?.active_staff || 0} width="19%"/>
          <DeviceBox 
              header="Top Performer" 
              subHeading="For Today" 
              number={
                staffTipTodaySummary?.top_performing_staff?.[0]?.name ? (
                  <span title={staffTipTodaySummary?.top_performing_staff[0].name} >
                    {staffTipTodaySummary?.top_performing_staff[0]?.name?.slice(0, 4)}
                    {staffTipTodaySummary?.top_performing_staff[0]?.name?.length > 4 ? '...' : ''} 
                    (${(staffTipTodaySummary?.top_performing_staff[0]?.total_tip / 100)})
                  </span>
                ) : 'N/A'
              }
              width="19%" 
            />
          <DeviceBox header="Average Tip" subHeading="Average Tip Per Staff, for Today"  number={`$${staffTipTodaySummary?.average_tip_per_staff/100 || 0}`} width="19%"/>
          <DeviceBox header="Total Tip" subHeading="Collected Today"  number={`$${staffTipTodaySummary?.total_tip/100|| 0}`} width="19%"/>
          </div>
          <div>
            <div className="header-search-contaner">
              <div className="filter-contaner">
                <span className="titleheading-content">Staff
                </span>
                <span className="staffs-badge">
                  {staffCount?.total_staff || "0"}
                </span>
                <span className="titleheading-subcontent"> • Hover mouse to select staff and click to know more...</span >
              </div>
              <div className="header-icons">
                <img src={searchIcon} alt="serchicon" style={{ width: '20px', height: '20px' }} onClick={() => setIsSearchVisible((prev) => !prev)}  />
                <img src={filterIcon} alt="filterIcon" style={{ width: '20px', height: '20px' }} onClick={() => setIsFilterVisible((prev) => !prev)} />
                <img src={sorticon} alt="sorticon" style={{ width: '20px', height: '20px' }} onClick={() => handleSort('name')} />
              </div>
            </div>
          </div>
          <div className="line"></div>
          {isFilterVisible && (
            <div className="filters">
              <div className="filter-group">
                <label htmlFor="statusFilter" className="filter-label">Filter by Status:</label>
                <Select
                styles={{height:'40px'}}
                isMulti
                options={statusOptions}
                className="custom-dropdown"
                classNames={{
                  control: () => "dropdown-control",
                  placeholder: () => "dropdown-placeholder",
                }}
                onChange={handleStatusChange}
                placeholder="Select statuses..."/>
              </div>

              <div className="filter-group" style={{ width: '50%', marginLeft: '10px' }}>
                <label htmlFor="permissionFilter" className="filter-label">Filter by Permissions:</label>                
                 <Select
                isMulti
                options={permissionOptions}
                className="custom-dropdown"
                classNames={{
                  control: () => "dropdown-control",
                  placeholder: () => "dropdown-placeholder",
                }}
                onChange={handlePermissionChange}
                placeholder="Select permissions" />
              </div>
            </div>)}
            {isSearchVisible && (
              <div className="search-container">
                  <label htmlFor="statusFilter" className="filter-label"  style={{paddingTop:'10px'}}>Search by Name/Mobile:</label>
                  <input type="text"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="form-control"
                  style={{width:'170px', height:'40px', marginLeft:'15px', borderRadius:'8px'}} />
                {searchTerm && (
                 <button
                 type="button"
                 className="clear-button-inside"
                 onClick={handleClear}
                 aria-label="Clear search" >
                 &times;
               </button>
                )}
              </div>
            )}
         <div className="table-responsive desktopView">
            <table className="table tablebordered">
              <thead className="table-header">
                <tr>
                  <th scope="col" className="arrowSortingIcon" style={{width:'12.3vw'}} > 
                      <span style={{marginLeft:'10px'}}> Name, Role
                  </span>
                  </th>
                  <th scope="col" className="arrowSortingIcon"> <span>Mobile
                  </span></th>
                  <th scope="col" className="arrowSortingIcon"> <span>Email
                  </span></th>
                  <th scope="col"><span >Permission  </span></th>
                  <th scope="col" className="arrowSortingIcon" > <span>Status
                  </span></th>
                  <th scope="col" className="arrowSortingIcon" ><span > Action 
                  </span></th>
                </tr>
              </thead>
              <tbody>
                {currentListStaff?.map((staff, index) => (
                  <tr key={staff.member_id}>
                    <td style={{width:'3px',verticalAlign:'top'}}>{staff.status === true ? <><span className="green-dot" title="Active"></span></> : <span className="red-dot" title="Inactive"></span>}</td>
                    <td className="nameStaffLink"  onClick={() => handleNameClick(staff)} >
                      <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '2px', alignItems: 'center' }}>
                        {staff.name || "NA"}
                      </div>
                    <div className="td-default tableAligment">{staff?.functional_role ?? "NA"}</div>
                    </td>
                    <td  onClick={() => handleNameClick(staff)} >                       
                      <div className="td-default">{staff?.mobile_number ?? "NA"}</div>
                    </td>
                    <td  onClick={() => handleNameClick(staff)} >                       
                      <div className="td-default">{staff?.email ?? "NA"}</div>
                    </td>
                    <td className="td-default"  onClick={() => handleNameClick(staff)} >{staff?.display_permission?.join(", ") ?? "NA"}</td>
                    <td className="td-default"  onClick={() => handleNameClick(staff)} >
                      {staff.status ? 'Active' : 'Inactive'}
                    </td>
                    {staff?.mobile_number === LoginUserData?.mobile_number ?
                      <td>  
                        <div style={{display:'flex', gap:'50px', justifyContent:'flex-start', position:'relative',right:'15px'}}>                  
                      <span onClick={() => sendSMSToCommunityMember(staff?.mobile_number)}>
                        <img src={sendSMSIcon} alt="sendSMSIcon" style={{ width: '20px', height: '20px', cursor: 'pointer', marginLeft: '25px'}} />
                      </span>
                      <div>You Are</div>
                      </div>   
                      </td> :
                      <td>
                        <div style={{display:'flex', gap:'50px', justifyContent:'flex-start', position:'relative',right:'15px'}}>
                          <span onClick={() => sendSMSToCommunityMember(staff.mobile_number)}>
                            <img src={sendSMSIcon} alt="sendSMSIcon" style={{ width: '20px', height: '20px', cursor: 'pointer', marginLeft: '25px' }} />
                          </span>
                         {staff?.status ? (
                          <div className="action-button" onClick={() => handleChangeStatus(staff?.member_id)}>Disable</div>
                      ) : (
                          <div className="action-button-enable" onClick={() => handleChangeStatus(staff?.member_id)}>Enable</div>
                      )}</div>
                       
                      </td>}
                  </tr>
                ))}
              </tbody>
          </table>
          </div>
          <div className="pagination mb-4">
            <button onClick={handlePreviousPage} disabled={currentPage === 1} className="nextPageButton" style={{backgroundColor:'#00c2d1',borderRadius:'8px'}}>
              Previous
            </button>
            <span style={{ paddingTop: "5px", margin: "0 10px" }}>
                  {`Page ${currentPage} / ${totalPages}`}
                </span>
            <button onClick={handleNextPage} disabled={currentPage === totalPages} className="nextPageButton">
              Next
            </button>
         </div>
        <div>
      </div>
       <Footer />
        </div>
      </div>
        {/* Popup Dialog */}
        <PopupDialog
        open={isPopupOpen}
        onClose={handleCloseDialog}
        title="Onboard New Staff">
        <AddUserForm accessToken={accessToken} community_id={community_id} cancelHandler={handleCloseDialog} />
      </PopupDialog>
    </div>
    </>
    : <div>Something is wrong</div>}
    </>
  );
};

export default ManageStaff;