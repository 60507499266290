import { createSlice } from "@reduxjs/toolkit";
import { getCommunityMember } from "../../api/api";

const GetCommunityMemberSlice = createSlice({
  name: 'getCommunityMember',
  initialState: {
    memberData: null,
    loading: false,
    error: null,
  },
  reducers: {
    resetMember: (state) => {
      state.member = null;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCommunityMember.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCommunityMember.fulfilled, (state, action) => {
        state.loading = false;
        state.memberData = action.payload;
      })
      .addCase(getCommunityMember.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default GetCommunityMemberSlice.reducer;
