import { createSlice } from '@reduxjs/toolkit';
import { communityLogoAction } from '../../api/api';

const CommunityBgImageSlice = createSlice({
  name: 'communityBgImage',
  initialState: {
    loading: false,
    error: null,
    communityBgImageData: null,
  },
  reducers: {}, 
  extraReducers: (builder) => {
    builder
      .addCase(communityLogoAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(communityLogoAction.fulfilled, (state, action) => {
        state.loading = false;
        state.communityBgImageData = action.payload;
      })
      .addCase(communityLogoAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default CommunityBgImageSlice.reducer;
