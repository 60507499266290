import { createSlice } from "@reduxjs/toolkit";
import { getHelpAndSupport } from "../../api/api";

const GetHelpAndSupportSlice = createSlice({
  name: 'helpandsupport',
  initialState: {
    helpData: null,
    loading: false,
    error: null,
  },
  reducers: {
    resetMember: (state) => {
      state.member = null;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHelpAndSupport.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getHelpAndSupport.fulfilled, (state, action) => {
        state.loading = false;
        state.helpData = action.payload;
      })
      .addCase(getHelpAndSupport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default GetHelpAndSupportSlice.reducer;
