import { createSlice } from "@reduxjs/toolkit";
import { getAddressBranch} from "../../api/api";

const GetAddressDetailSlice = createSlice({
  name: 'addressdetail',
  initialState: {
    addressData: null,
    loading: false,
    error: null,
  },
  reducers: {
    resetMember: (state) => {
      state.member = null;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAddressBranch.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAddressBranch.fulfilled, (state, action) => {
        state.loading = false;
        state.addressData = action.payload;
      })
      .addCase(getAddressBranch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default GetAddressDetailSlice.reducer;
