import { createSlice } from "@reduxjs/toolkit";
import { getTipSummaryHistoryAction } from "../../api/api";

const initialState = {
    paymentSummaryHistory: null,
    loading: false,
    error: null,
  };
  
  const tipSummaryHistorySlice = createSlice({
    name: 'payment',
    initialState,
    reducers: {
      
    },
    extraReducers: (builder) => {
      builder
        .addCase(getTipSummaryHistoryAction.pending, (state) => {
          state.loading = true;
          state.error = null; 
        })
        .addCase(getTipSummaryHistoryAction.fulfilled, (state, action) => {
          state.loading = false;
          state.paymentSummaryHistory = action.payload; 
        })
        .addCase(getTipSummaryHistoryAction.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload; 
        });
    },
  });
  
  
  export default tipSummaryHistorySlice.reducer; 