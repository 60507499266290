import { createSlice } from "@reduxjs/toolkit";
import { UpdateUserDetailAction } from "../../api/api";

// Create the slice
const UpdateUserSlice = createSlice({
    name: 'user',
    initialState: {
      updatedData: null,         
      loading: false,     
      error: null,        
    },
    reducers: {
      resetState: (state) => {
        state.updatedData = null;
        state.loading = false;
        state.error = null;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(UpdateUserDetailAction.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(UpdateUserDetailAction.fulfilled, (state, action) => {
          state.loading = false;
          state.updatedData = action.payload;
        })
        .addCase(UpdateUserDetailAction.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        });
    },
  });
  
  
  export default UpdateUserSlice.reducer;