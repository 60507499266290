import { createSlice } from "@reduxjs/toolkit";
import { UpdateMemberInfoAction } from "../../api/api";

const UpdateMemberInfoSlice = createSlice({
    name: 'user',
    initialState: {
      memberInfo: null,
      loading: false,
      error: null,
    },
    reducers: {

    },
    extraReducers: (builder) => {
      builder
        .addCase(UpdateMemberInfoAction.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(UpdateMemberInfoAction.fulfilled, (state, action) => {
          state.loading = false;
          state.memberInfo = action.payload;
        })
        .addCase(UpdateMemberInfoAction.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        });
    },
  });
  
//   export const { clearError } = userSlice.actions; 
  export default UpdateMemberInfoSlice.reducer; 