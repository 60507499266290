// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainHelpANdSupportPage{
    display: flex;
    height: 100vh;
}
.contentSectionHelp{
    background-color: var( --primary-color);
    height: 250px;
    border-radius: 8px;
}`, "",{"version":3,"sources":["webpack://./src/pages/HelpAndSupport/helpandsupport.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;AACjB;AACA;IACI,uCAAuC;IACvC,aAAa;IACb,kBAAkB;AACtB","sourcesContent":[".mainHelpANdSupportPage{\n    display: flex;\n    height: 100vh;\n}\n.contentSectionHelp{\n    background-color: var( --primary-color);\n    height: 250px;\n    border-radius: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
