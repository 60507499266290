import React from "react";
import './footer.css'

const Footer = () => {
    return(
        <div className="footertapNtipdashboard">
        <p>(C) 2024 Requite, Inc | <a href="https://www.requite.tech/" target="_blank" style={{marginLeft:'5px', marginRight:'5px'}}>www.requite.tech</a> | <a href="https://www.requite.tech/terms" target="_blank" style={{marginLeft:'5px', marginRight:'5px'}}>Terms of Use</a> | <a href="https://www.requite.tech/privacy-policy" target="_blank" style={{marginLeft:'5px'}}>Privacy Policy</a></p>
        </div>
    )
}

export default Footer