import { createSlice } from '@reduxjs/toolkit';
import { getPayoutListHistoryAction } from '../../api/api';

const PayoutListHistorySlice = createSlice({
  name: 'payoutList',
  initialState: {
    payoutHistoryData: null,
    loading: false,
    error: null,
  },
  reducers: {}, 
  extraReducers: (builder) => {
    builder
      .addCase(getPayoutListHistoryAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPayoutListHistoryAction.fulfilled, (state, action) => {
        state.loading = false;
        state.payoutHistoryData = action.payload;
      })
      .addCase(getPayoutListHistoryAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default PayoutListHistorySlice.reducer;
