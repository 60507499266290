// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.date-range-picker {
  display: flex;
  justify-content: 20px;
  gap: 10px;
}

.date-input {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.date-input label {
  font-size: var(--font-size-paragraph-14);
  font-weight: bold;
  color: #333;
  min-width: 200px;
}

.date-input input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: var(--font-size-paragraph-14);
}
/* date.css */
.react-datepicker-popper {
  z-index: 1050; 
}

.date-range-picker {
  position: relative;
}
.customDate{
  width: 117px;
  height: 40px !important;
  padding: 10px;
  border: 1px solid #dbd4d4;
  border-radius: 6px;
}`, "",{"version":3,"sources":["webpack://./src/components/date/date.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qBAAqB;EACrB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;AACV;;AAEA;EACE,wCAAwC;EACxC,iBAAiB;EACjB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;EAClB,wCAAwC;AAC1C;AACA,aAAa;AACb;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;AACpB;AACA;EACE,YAAY;EACZ,uBAAuB;EACvB,aAAa;EACb,yBAAyB;EACzB,kBAAkB;AACpB","sourcesContent":[".date-range-picker {\n  display: flex;\n  justify-content: 20px;\n  gap: 10px;\n}\n\n.date-input {\n  display: flex;\n  flex-direction: column;\n  gap: 5px;\n}\n\n.date-input label {\n  font-size: var(--font-size-paragraph-14);\n  font-weight: bold;\n  color: #333;\n  min-width: 200px;\n}\n\n.date-input input {\n  padding: 5px;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n  font-size: var(--font-size-paragraph-14);\n}\n/* date.css */\n.react-datepicker-popper {\n  z-index: 1050; \n}\n\n.date-range-picker {\n  position: relative;\n}\n.customDate{\n  width: 117px;\n  height: 40px !important;\n  padding: 10px;\n  border: 1px solid #dbd4d4;\n  border-radius: 6px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
