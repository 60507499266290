import React, { useEffect, useRef, useState } from "react";
import "./stripeaccountpending.css";
import { useDispatch, useSelector } from "react-redux";
import { accountOnBoardingAction, memberInfoAction, refreshTokenAction } from "../../redux/api/api";
import { useNavigate } from "react-router-dom";
import SidebarStripe from "../../components/SideBar/SidebarStripe";
import { Box, CircularProgress } from "@mui/material";
import Footer from "../../components/Footer/Footer";
import { toast } from "react-toastify";

const StripeAccountPending = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const addressInputRef = useRef(null);
  const cityInputRef = useRef(null);
  const [communitiesData, setCommunitiesData] = useState([]);
  const [allverifydata, setAllVerifyData] = useState();
  const [allDataCommunity, setAllDataCommunity] = useState();
  const [loading, setLoading] = useState(true);
  const [communityTypeUrl, setCommunityTypeUrl] = useState()
  const [communitiesInfo, setCommunitiesInfo] = useState({
    name: "",
    email: "",
    community_name: "",
    community_type: "",
    tip_amount: "",
    address: {
      address: "",
      city: "",
      state: "",
      zip_code: "",
      country: "US"
    },
    bankaccount: {
      account_name: "",
      account_number: "",
      routing_number: "",
      account_type: "",
      bank_name: ""
    }
  });
  const [errors, setErrors] = useState({});
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isFieldsEnabled, setIsFieldsEnabled] = useState(false);
  const { data } = useSelector((state) => state.memberInfoStore);
  const accessToken = localStorage.getItem("accesstoken");
  const [commUrl, setCommUrl] = useState()

  useEffect(() => {
    if (accessToken) {
      fetchMemberData();
    }
  }, [accessToken, dispatch]);

  const fetchMemberData = async () => {
    try {
      const response = await dispatch(memberInfoAction({ accessToken }));
      if (memberInfoAction.fulfilled.match(response)) {
        const communities = response.payload.communities;
        setCommunitiesData(communities);
        setAllVerifyData(response.payload);

        if (communities?.length > 0) {
          const firstCommunity = communities[0];
          setAllDataCommunity({
            ...response.payload,
            communities: firstCommunity,
          });
        }
      } else {
        setErrorMessage(response.payload?.message || "Failed to fetch member info");
      }
    } catch (error) {
      setErrorMessage("An unexpected error occurred.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!loading) {
      if (allverifydata?.communities === null) {
        navigate("/account-onboarding");
      }
      else if (
        allverifydata?.communities?.length > 0 &&
        allverifydata.communities.every(
          (community) => community.stripe_setup_url === null
        )
      ) {
        navigate("/dashboard");
      }
      else if (
        allverifydata?.communities?.some(
          (community) => community.stripe_setup_url
        )
      ) {
        navigate("/stripe-pending-onboard");
      }
    }
  }, [allverifydata, loading, navigate]);



  useEffect(() => {
    if (!isFieldsEnabled) {
      setCommunitiesInfo((prevState) => ({
        ...prevState,
        bankaccount: {
          ...prevState.bankaccount,
          account_name: "",
          account_number: "",
          routing_number: "",
          account_type: "",
          bank_name: ""
        },
        address: {
          ...prevState.address,
          address: "",
          city: "",
          state: "",
          zip_code: "",
          country: "US"
        },
      }));
    }
  }, [isFieldsEnabled]);

 useEffect(() => {
  const url = new URL(window.location.href);
  setCommunityTypeUrl(url.origin + url.pathname);
}, []);

  const isTapNTip = communityTypeUrl?.startsWith("https://dev-tapntip.requite.tech")
  const isTapNDonate = communityTypeUrl?.startsWith("https://dev-tapndonate.requite.tech")

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === "community_type") {
      setIsFieldsEnabled(isTapNTip && value === "B2");
    }

    if (name in communitiesInfo.bankaccount) {
      setCommunitiesInfo((prevState) => ({
        ...prevState,
        bankaccount: {
          ...prevState.bankaccount,
          [name]: value,
        },
      }));
    } else if (name in communitiesInfo.address) {
      setCommunitiesInfo((prevState) => ({
        ...prevState,
        address: {
          ...prevState.address,
          [name]: value,
        },
      }));
    } else {
      setCommunitiesInfo((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

const handleRefreshToken = async () => {
    try {
      const refresh_token = localStorage.getItem('refreshToken');
      const response = await dispatch(refreshTokenAction({ refresh_token }));
      if (refreshTokenAction.fulfilled.match(response)) {
          const new_access_token = response.payload?.access_token;
          const new_refresh_token = response.payload?.refresh_token;
          localStorage.removeItem('accesstoken');
          localStorage.setItem('accesstoken', new_access_token);
          localStorage.setItem('refreshToken', new_refresh_token);
          } else {
             console.error("Failed to refresh token:", response.payload?.message || "Unknown error");
          }
          } catch (error) {
              console.error("Failed to refresh token:", error);
      }

  };


const handleSubmit = async (event) => {
  event.preventDefault();
  if (validateForm()) {
    setIsButtonLoading(true);
    const addressPayload =
      communitiesInfo.community_type === "B1"
        ? null : {
            address: communitiesInfo.address.address,
            city: communitiesInfo.address.city,
            state: communitiesInfo.address.state,
            zip_code: communitiesInfo.address.zip_code,
            country: communitiesInfo.address.country,
          };
      const bankAccountPayload =
      communitiesInfo.community_type === "B1"
        ? null : {
            account_name: communitiesInfo.bankaccount.account_name,
            account_number: communitiesInfo.bankaccount.account_number,
            routing_number: communitiesInfo.bankaccount.routing_number,
            account_type: communitiesInfo.bankaccount.account_type,
            bank_name: communitiesInfo.bankaccount.bank_name,
          };
    const payload = {
      name: communitiesInfo.name,
      email: communitiesInfo.email,
      community_name: communitiesInfo.community_name,
      community_type: communitiesInfo.community_type,
      tip_amount: Number(communitiesInfo.tip_amount * 100),
      address: addressPayload,
      bankaccount: bankAccountPayload,
    };
    try {
      const response = await dispatch(accountOnBoardingAction({ accessToken, payload }));
      if (accountOnBoardingAction.fulfilled.match(response)) {
        toast.success("Form submitted successfully!");
        if(response?.meta?.requestStatus === "fulfilled" && response?.payload?.community_type === "B1"){
          navigate("/stripe-pending-onboard")
        } else {
          handleRefreshToken()
          setTimeout(() => {
            navigate("/dashboard");
          }, 3000);
        }
      } else {
        toast.error(response.payload?.detail || "Failed to submit form.");
      }
    } catch (error) {
      toast.error("An error occurred while submitting the form.");
    } finally {
      setLoading(false);
    }

    setTimeout(() => {
      setIsButtonLoading(false);
    }, 2000);
  } else {
    console.error("Validation failed");
  }
};


 const validateForm = () => {
  const newErrors = {};

  if (!communitiesInfo.name) newErrors.name = "Name is required.";
  if (!communitiesInfo.community_name)
    newErrors.community_name = "Business name is required.";
  if (!/^\d+$/.test(communitiesInfo.tip_amount))
    newErrors.tip_amount = "IdealTip amount is required and numeric.";
  if (!communitiesInfo.email) newErrors.email = "Email is required.";
  if (!communitiesInfo.community_type)
    newErrors.community_type = "Community type is required.";

  setErrors(newErrors);
  return Object.keys(newErrors).length === 0;
};


  useEffect(() => {
    const loadGoogleMaps = () => {
      if (window.google) {
        const autocomplete = new window.google.maps.places.Autocomplete(
          addressInputRef.current,
        );

        autocomplete.addListener("place_changed", () => {
          const place = autocomplete.getPlace();

          if (place && place.address_components) {

            const newAddressInfo = {
              address: "",
              city: "",
              state: "",
              zip_code: "",
            };

            place.address_components.forEach((component) => {
              const types = component.types;
              if (types.includes("street_number")) {
                newAddressInfo.address = component.long_name + " " + (newAddressInfo.address || "");
              }
              if (types.includes("route")) {
                newAddressInfo.address = (newAddressInfo.address || "") + component.long_name;
              }
              if (types.includes("locality")) {
                newAddressInfo.city = component.long_name;
              }
              if (types.includes("administrative_area_level_1")) {
                newAddressInfo.state = component.short_name;
              }
              if (types.includes("postal_code")) {
                newAddressInfo.zip_code = component.long_name;
              }
            });

            setCommunitiesInfo((prevState) => ({
              ...prevState,
              address: {
                ...prevState.address,
                ...newAddressInfo,
              },
            }));
          }
        });
      }
    };

    const scriptExists = document.querySelector(
      `script[src="https://maps.googleapis.com/maps/api/js?key=AIzaSyBAx7C85n8ovh_dkLUO5PF1yDeQKlAfRhc&libraries=places"]`
    );

    if (!scriptExists) {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBAx7C85n8ovh_dkLUO5PF1yDeQKlAfRhc&libraries=places`;
      script.async = true;
      script.onload = loadGoogleMaps;
      document.head.appendChild(script);
    } else {
      loadGoogleMaps();
    }
}, [communitiesInfo.address]);




if (!communityTypeUrl) {
  return <div>Loading...</div>;
}

  return (
    <div className="mainStripeAccountPage mainLayoutPage">
      <div className="sidebar">
        <SidebarStripe />
      </div>
      <div className="dashboard-container borderFormat">
        <div className="headerStripePending">
        </div>
        <div className="heading-content-stripe-pending">Welcome! It takes just a few moments to set up - let's do this together.</div>

        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
          >
            <CircularProgress />
          </Box>
        ) : (
          <div className="mainPendingData">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-sm-4" style={{ padding: "40px" }}>
                  <div className="subheader" style={{paddingLeft:'8px'}}>Account Setup:</div>
                  <div className="form-group p-2">
                    <label htmlFor="name">Name:<span className="requiredField">*</span></label>
                    <input
                      type="text"
                      className={`form-control ${errors.name ? "is-invalid" : ""}`}
                      required
                      name="name"
                      value={communitiesInfo.name}
                      onChange={handleInputChange}
                      placeholder="Your name"
                      style={{height:"40px"}}
                    />
                    {errors.name && (
                      <div className="invalid-feedback">{errors.name}</div>
                    )}
                  </div>

                  <div className="form-group p-2">
                    <label htmlFor="community_name">
                      Doing Business As (DBA):<span className="requiredField">*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        errors.community_name ? "is-invalid" : ""
                      }`}
                      required
                      name="community_name"
                      value={communitiesInfo.community_name}
                      onChange={handleInputChange}
                      placeholder="Business Name"
                      style={{height:"40px"}}
                    />
                    {errors.community_name && (
                      <div className="invalid-feedback">
                        {errors.community_name}
                      </div>
                    )}
                  </div>

                  <div className="form-group p-2">
                    <label htmlFor="IdleTip">
                      IdealTip ($):<span className="requiredField">*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errors.tip_amount ? "is-invalid" : ""}`}
                      required
                      name="tip_amount"
                      value={communitiesInfo.tip_amount}
                      onChange={handleInputChange}
                      style={{height:"40px"}}
                      placeholder="IdealTip ($) is recommended Tip Value" />
                    {errors.tip_amount && (
                      <div className="invalid-feedback">
                        {errors.tip_amount}
                      </div>
                    )}
                  </div>
                  <div className="form-group p-2">
                    <label htmlFor="email">Email:<span className="requiredField">*</span></label>
                    <input
                      type="email"
                      className={`form-control ${errors.email ? "is-invalid" : ""}`}
                      required
                      name="email"
                      value={communitiesInfo.email}
                      onChange={handleInputChange}
                      placeholder="Email address"
                      style={{height:"40px"}}
                    />
                    {errors.email && (
                      <div className="invalid-feedback">{errors.email}</div>
                    )}
                  </div>
                  <div className="form-group p-2">
                    <label htmlFor="community_type">
                      Account used for:<span className="requiredField">*</span>
                    </label>
                    <div className="form-group">
                      <select
                        className="form-control"
                        style={{ height: "40px" }}
                        name="community_type"
                        value={communitiesInfo.community_type}
                        onChange={handleInputChange}
                      >
                        <option value="">Select</option>
                        {isTapNTip && (
                          <option value="B2">TapNTip - Collect Tips</option>
                        )}
                        {isTapNDonate && (
                          <option value="B1">TapNDonate - Collect Donations</option>
                        )}
                      </select>
                    </div>
                    {errors.community_type && (
                      <span className="error-message">{errors.community_type}</span>
                    )}
                  </div>
                </div>
                <div className="col-sm-4"  style={{ padding: "40px" }}>
                  <div className="subheader" style={{paddingLeft:'10px'}}>Bank Account Details</div>

                  <div className="form-group p-2">
                    <label htmlFor="account_name">Account Holder Name:</label>
                    <input
                      type="text"
                      className={`form-control ${errors.account_name ? "is-invalid" : ""}`}
                      name="account_name"
                      required
                      style={{height:"40px"}}
                      value={communitiesInfo.bankaccount.account_name}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^[a-zA-Z\s]*$/.test(value)) {
                          handleInputChange(e);
                          setErrors((prevErrors) => ({ ...prevErrors, account_name: "" }));
                        } else {
                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            account_name: "Only alphabetic characters are allowed.",
                          }));
                        }
                      }}
                      disabled={!isTapNTip}
                      placeholder="Account Holder Name"
                    />
                    {errors.account_name && (
                      <div className="invalid-feedback">{errors.account_name}</div>
                    )}
                  </div>

                  <div className="form-group p-2">
                    <label htmlFor="bankname">Bank Name:</label>
                    <input
                      type="text"
                      className={`form-control ${errors.bank_name ? "is-invalid" : ""}`}
                      name="bank_name"
                      value={communitiesInfo.bankaccount.bank_name}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^[a-zA-Z\s]*$/.test(value)) {
                          handleInputChange(e);
                          setErrors((prevErrors) => ({ ...prevErrors, bank_name: "" }));
                        } else {
                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            bank_name: "Only alphabetic characters are allowed.",
                          }));
                        }
                      }}
                      disabled={!isTapNTip}
                      required
                      placeholder="Bank Name"
                      style={{height:"40px"}}
                    />
                    {errors.bank_name && (
                      <div className="invalid-feedback">{errors.bank_name}</div>
                    )}
                  </div>
                  <div className="form-group p-2">
                    <label htmlFor="accountnumber">Account Number:</label>
                    <input
                      type="text"
                      className={`form-control ${errors.account_number ? "is-invalid" : ""}`}
                      name="account_number"
                      value={communitiesInfo.bankaccount.account_number}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^\d*$/.test(value)) {
                          handleInputChange(e);
                          setErrors((prevErrors) => ({ ...prevErrors, account_number: "" }));
                        } else {
                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            account_number: "Only numeric values are allowed.",
                          }));
                        }
                      }}
                      disabled={!isTapNTip}
                      required
                      placeholder="Bank Account Number"
                      style={{height:"40px"}}
                    />
                    {errors.account_number && (
                      <div className="invalid-feedback">{errors.account_number}</div>
                    )}
                  </div>
                  <div className="form-group p-2">
                    <label htmlFor="name">Routing Number:</label>
                    <input
                      type="text"
                      className={`form-control ${errors.routing_number ? "is-invalid" : ""}`}
                      name="routing_number"
                      value={communitiesInfo.bankaccount.routing_number}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^\d*$/.test(value)) {
                          handleInputChange(e);
                          setErrors((prevErrors) => ({ ...prevErrors, routing_number: "" }));
                        } else {
                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            routing_number: "Only numeric values are allowed.",
                          }));
                        }
                      }}
                      disabled={!isTapNTip}
                      required
                      placeholder="Routing Number"
                      style={{height:"40px"}}
                    />
                    {errors.routing_number && (
                      <div className="invalid-feedback">{errors.routing_number}</div>
                    )}
                  </div>
                  <div className="form-group p-2">
                  <label htmlFor="accounttype">Account Type:</label>
                  <div className="form-group">
                    <select
                      className="form-control"
                      style={{height:'40px'}}
                      name="account_type"
                      required
                      value={communitiesInfo.bankaccount.account_type}
                      disabled={!isTapNTip}
                      onChange={handleInputChange}>
                      <option value="">Select Account Type</option>
                      <option value="SAVING">SAVING</option>
                      <option value="CHECKING">CHECKING</option>
                      <option value="BUSINESS CHECKING">BUSINESS CHECKING</option>
                    </select>
                  </div>
                  {errors.account_type && <span className="error-message">{errors.account_type}</span>}
                </div>
                </div>

                <div className="col-sm-4" style={{ padding: '40px'}}>
                  <div className="subheader" style={{paddingLeft:'8px'}}>Business/ Work Location</div>
                  <div className="form-group p-2">
                    <label htmlFor="address">Address:</label>
                    <input
                      ref={addressInputRef}
                      type="text"
                      className={`form-control ${errors.address ? "is-invalid" : ""}`}
                      name="address"
                      value={communitiesInfo.address.address}
                      required
                      onChange={handleInputChange}
                      disabled={!isTapNTip}
                      placeholder="Unit, Street, Building"
                      style={{height:"40px"}}
                    />
                    {errors.address && (
                      <div className="invalid-feedback">{errors.address}</div>
                    )}
                  </div>

                  <div className="form-group p-2">
                    <label htmlFor="city">
                      City:
                    </label>
                    <input
                      ref={cityInputRef}
                      type="text"
                      className={`form-control ${
                        errors.city ? "is-invalid" : ""
                      }`}
                      name="city"
                      value={communitiesInfo.address.city}
                      onChange={handleInputChange}
                      disabled
                      required
                      placeholder="City Name"
                      style={{height:"40px"}}
                    />
                    {errors.community_name && (
                      <div className="invalid-feedback">
                        {errors.community_name}
                      </div>
                    )}
                  </div>
                  <div className="form-group p-2">
                    <label htmlFor="state">State:</label>
                    <input
                      type="text"
                      className={`form-control ${errors.state ? "is-invalid" : ""}`}
                      name="state"
                      value={communitiesInfo.address.state}
                      onChange={handleInputChange}
                      disabled
                      required
                      style={{height:"40px"}}
                      placeholder="State Name" />
                    {errors.name && (
                      <div className="invalid-feedback">{errors.state}</div>
                    )}
                  </div>

                  <div className="form-group p-2">
                    <label htmlFor="name">Zip Code:</label>
                    <input
                      type="text"
                      className={`form-control ${errors.zip_code ? "is-invalid" : ""}`}
                      name="zip_code"
                      disabled
                      style={{height:"40px"}}
                      value={communitiesInfo.address.zip_code}
                      onChange={handleInputChange}
                      required
                      placeholder="Zip Code"
                    />
                    {errors.zip_code && (
                      <div className="invalid-feedback">{errors.zip_code}</div>
                    )}
                  </div>
                </div>
                <div className="continueButton">
                    <button
                      type="submit"
                      className="buttonSubmitCreateAccount"
                      disabled={isButtonLoading} >
                      {isButtonLoading ? (
                        <CircularProgress size={30} color="inherit" />
                      ) : (
                        "Complete Account Setup"
                      )}
                    </button>
                  </div>

              </div>

              {errorMessage && ( <div className="errorMessageLeft">{errorMessage}</div> )}
            </form>
          </div>
        )}

        <div style={{marginTop:"20px"}}>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default StripeAccountPending;
