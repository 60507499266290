import { createSlice } from "@reduxjs/toolkit";
import { getDeletePayoutAction } from "../../api/api";

const DeletePayoutSlice = createSlice({
    name: 'deletePayout',
    initialState: {
      loading: false,
      success: false,
      error: null,
    },
    reducers: {
      resetDeleteState: (state) => {
        state.loading = false;
        state.success = false;
        state.error = null;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(getDeletePayoutAction.pending, (state) => {
          state.loading = true;
          state.success = false;
          state.error = null;
        })
        .addCase(getDeletePayoutAction.fulfilled, (state) => {
          state.loading = false;
          state.success = true;
          state.error = null;
        })
        .addCase(getDeletePayoutAction.rejected, (state, action) => {
          state.loading = false;
          state.success = false;
          state.error = action.payload;
        });
    },
  });
  
  export const { resetDeleteState } = DeletePayoutSlice.actions;
  export default DeletePayoutSlice.reducer;