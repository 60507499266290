// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.addressTextSection{
  display: flex;
}
.addressLabel{
  padding-right: 20px;
  padding-top: 10px;
  color: var(--black-color);
  font-size: var(--font-size-paragraph-12);
  font-weight: var(--font-weight-600);
  font-family: var(--font-Family);

}    `, "",{"version":3,"sources":["webpack://./src/components/AddressDetail/addressdetail.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;EACE,mBAAmB;EACnB,iBAAiB;EACjB,yBAAyB;EACzB,wCAAwC;EACxC,mCAAmC;EACnC,+BAA+B;;AAEjC","sourcesContent":[".addressTextSection{\n  display: flex;\n}\n.addressLabel{\n  padding-right: 20px;\n  padding-top: 10px;\n  color: var(--black-color);\n  font-size: var(--font-size-paragraph-12);\n  font-weight: var(--font-weight-600);\n  font-family: var(--font-Family);\n\n}    "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
