import { createSlice } from "@reduxjs/toolkit";
import { otpAction } from "../../api/api";

// Slice for OTP login
const otpSlice = createSlice({
    name: 'otp',
    initialState: {
      data: null,
      loading: false,
      error: null,
    },
    reducers: {
      // Optional: Add extra reducers if needed
      resetOtpState: (state) => {
        state.data = null;
        state.loading = false;
        state.error = null;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(otpAction.pending, (state) => {
          state.loading = true;
          state.error = null; 
        })
        .addCase(otpAction.fulfilled, (state, action) => {
          state.loading = false;
          state.data = action.payload;
        })
        .addCase(otpAction.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        });
    },
  });
  
  export default otpSlice.reducer;