import { createSlice } from '@reduxjs/toolkit';
import { getManagePayoutList } from '../../api/api';

const initialState = {
  PayoutList: [], 
  loading: false,
  error: null,
};

const ManagePayoutSlice = createSlice({
  name: 'payout',
  initialState,
  reducers: {}, 
  extraReducers: (builder) => {
    builder
      .addCase(getManagePayoutList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getManagePayoutList.fulfilled, (state, action) => {
        state.loading = false;
        state.PayoutList = action.payload;
      })
      .addCase(getManagePayoutList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default ManagePayoutSlice.reducer;
