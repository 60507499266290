import { createSlice } from "@reduxjs/toolkit";
import { activeInactiveStaffUserAction } from "../../api/api";

const ActiveInactiveStaffUserSlice = createSlice({
    name: 'userstaff',
    initialState: {
      status: 'idle', 
      data: null,
      error: null,
    },
    reducers: {
      // Additional reducers if needed
      clearError(state) {
        state.error = null; 
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(activeInactiveStaffUserAction.pending, (state) => {
          state.status = 'loading';
          state.error = null;
        })
        .addCase(activeInactiveStaffUserAction.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.data = action.payload;
        })
        .addCase(activeInactiveStaffUserAction.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.payload;
        });
    },
  });
  
  export default ActiveInactiveStaffUserSlice.reducer;