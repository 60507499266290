import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./pages/Login/Login";
import ForgetPassword from "./pages/ForgetPassword/ForgetPassword";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import Dashboard from "./pages/Dashboard/Dashboard";
import ManageStaff from "./pages/ManageStaff/ManageStaff";
import ManageDevice from "./pages/ManageDevice/ManageDevice";
import Settings from "./pages/Settings/Settings";
import AddUser from "./pages/ManageStaff/addUser/AddUser";
import MyProfile from "./pages/MyProfile/MyProfile";
import TipHistory from "./pages/TipHistory/TipHistory";
import StripeAccountPending from "./pages/SrtipeAccountPending/StripeAccountPending";
import StripeDetails from "./pages/StripeDetails/StripeDetails";
import DeviceDetail from "./pages/ManageDevice/DeviceDetail/DeviceDetail";
import AddDevices from "./pages/ManageDevice/AddDevice/AddDevice";
import { ToastContainer } from "react-toastify";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import NotFound from "./components/NotFound/NotFound";
import ManagePayout from "./pages/ManagePayout/ManagePayout";
import AddBankAccount from "./pages/MyProfile/AddBankAccount/AddBankAccount";
import TipsSummaryList from "./pages/TipsSummary/TipsSummaryList";
import HelpAndSupport from "./pages/HelpAndSupport/HelpAndSupport";
import PreviewPayout from "./pages/ManagePayout/PreviewPayout/PreviewPayout";
import CreateAccountAddress from "./pages/SrtipeAccountPending/CreateAccountAddress/CreateAccountAddress";
import CreateAccountBankDetail from "./pages/SrtipeAccountPending/CreateAccountBankDetail/CreateAccountBankDetail";


function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/manage-staff"
            element={
              <ProtectedRoute>
                <ManageStaff />
              </ProtectedRoute>
            }
          />
          <Route
            path="/manage-device"
            element={
              <ProtectedRoute>
                <ManageDevice />
              </ProtectedRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <ProtectedRoute>
                <Settings />
              </ProtectedRoute>
            }
          />
          <Route
            path="/add-user"
            element={
              <ProtectedRoute>
                <AddUser />
              </ProtectedRoute>
            }
          />
          <Route
            path="/my-profile"
            element={
              <ProtectedRoute>
                <MyProfile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/tip-history"
            element={
              <ProtectedRoute>
                <TipHistory />
              </ProtectedRoute>
            }
          />
          <Route
            path="/manage-payout"
            element={
              <ProtectedRoute>
                <ManagePayout />
              </ProtectedRoute>
            }
          />
          <Route
            path="/account-onboarding"
            element={
              <ProtectedRoute>
                <StripeAccountPending />
              </ProtectedRoute>
            }
          />
          <Route
            path="/stripe-pending-onboard"
            element={
              <ProtectedRoute>
                <StripeDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/device-detail"
            element={
              <ProtectedRoute>
                <DeviceDetail />
              </ProtectedRoute>
            }
          />
          <Route
            path="/add-devices"
            element={
              <ProtectedRoute>
                <AddDevices />
              </ProtectedRoute>
            }
          />
          <Route
            path="/add-new-bank-account"
            element={
              <ProtectedRoute>
                <AddBankAccount />
              </ProtectedRoute>
            }
          />
           <Route
            path="/tips-summary-list"
            element={
              <ProtectedRoute>
                <TipsSummaryList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/help-and-support"
            element={
              <ProtectedRoute>
                <HelpAndSupport />
              </ProtectedRoute>
            }
          />
          <Route
            path="/preview-payout"
            element={
              <ProtectedRoute>
                <PreviewPayout />
              </ProtectedRoute>
            }
          />
          <Route
            path="/create-account-address"
            element={
              <ProtectedRoute>
                <CreateAccountAddress />
              </ProtectedRoute>
            }
          />
          <Route
            path="/create-account-bank-detail"
            element={
              <ProtectedRoute>
                <CreateAccountBankDetail />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
      <ToastContainer />
    </>
  );
}

export default App;
