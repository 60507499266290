import { createSlice } from '@reduxjs/toolkit';
import { getPartialPayoutAction } from '../../api/api';

const initialState = {
  partialData: null, 
  loading: false, 
  error: null, 
};

const PartialPayoutDataSlice = createSlice({
  name: 'partialPayout',
  initialState,
  reducers: {
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPartialPayoutAction.pending, (state) => {
        state.loading = true;
        state.error = null; 
      })
      .addCase(getPartialPayoutAction.fulfilled, (state, action) => {
        state.loading = false;
        state.partialData = action.payload; 
      })
      .addCase(getPartialPayoutAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; 
      });
  },
});

export default PartialPayoutDataSlice.reducer;
