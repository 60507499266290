import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getallTipsSummary } from "../../redux/api/api";
import "./trendchart.css";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
} from "chart.js";
import {
  subDays,
  format,
  startOfWeek,
  addDays,
  startOfMonth,
  addMonths,
} from "date-fns";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler
);

const LineChart = ({ timePeriod, accessToken, community_id }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [groupedData, setGroupedData] = useState([]);
  const currentDate = new Date();
  const currentDateUTC = new Date(
    Date.UTC(
      currentDate.getUTCFullYear(),
      currentDate.getUTCMonth(),
      currentDate.getUTCDate()
    )
  );
  const startDate = subDays(currentDateUTC, timePeriod - 1);
  const formattedStartDate = startDate.toISOString().split("T")[0];
  const endDate = currentDate.toISOString().split("T")[0];

  const fetchTipsSummary = () => {
    setLoading(true);
    const systemTimeSpanOffset = new Date().getTimezoneOffset();
    
    // dispatch(getallTipsSummary({
    //     accessToken,
    //     community_id,
    //     startDateUTCConvert: formattedStartDate,
    //     endDateUTCConvert: endDate,
    //     timezoonoffset: systemTimeSpanOffset,
    //   })
    // ).finally(() => {
    //   setLoading(false);
    // });
    if(accessToken && community_id){
      dispatch(getallTipsSummary({
        accessToken,
        community_id,
        startDateUTCConvert: formattedStartDate,
        endDateUTCConvert: endDate,
        timezoonoffset: systemTimeSpanOffset,
      }))
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchTipsSummary();
  }, [timePeriod, accessToken, community_id]);

  const { tipsDataSummary } = useSelector(
    (state) => state.GetAllTipsSummaryStore
  );
  useEffect(() => {
    if (tipsDataSummary && tipsDataSummary.length > 0) {
      const grouped = tipsDataSummary.reduce((acc, item) => {
        const date = new Date(item.transaction_ts);
        let groupDate;

        if (timePeriod >= 90) {
          const daysSinceStart = Math.floor(
            (date - startDate) / (1000 * 60 * 60 * 24)
          );
          const groupKey = Math.floor(daysSinceStart / 6) * 6;
          groupDate = format(addDays(startDate, groupKey), "yyyy-MM-dd");
        } else if (timePeriod >= 30) {
          const daysSinceStart = Math.floor(
            (date - startDate) / (1000 * 60 * 60 * 24)
          );
          const groupKey = Math.floor(daysSinceStart / 2) * 2;
          groupDate = format(addDays(startDate, groupKey), "yyyy-MM-dd");
        } else {
          groupDate = format(date, "yyyy-MM-dd");
        }

        if (!acc[groupDate]) {
          acc[groupDate] = {
            date: groupDate,
            tipCount: 0,
            totalFees: 0,
          };
        }

        acc[groupDate].tipCount += 1;
        acc[groupDate].totalFees += item.amount;

        return acc;
      }, {});

      const allDates = [];
      const startDateObj = new Date(formattedStartDate);
      if (timePeriod >= 90) {
        let dayStart = 0;
        while (addDays(startDateObj, dayStart) <= currentDate) {
          const dayStartStr = format(
            addDays(startDateObj, dayStart),
            "yyyy-MM-dd"
          );
          if (!grouped[dayStartStr]) {
            grouped[dayStartStr] = {
              date: dayStartStr,
              tipCount: 0,
              totalFees: 0,
            };
          }
          allDates.push(dayStartStr);
          dayStart += 6;
        }
      } else if (timePeriod >= 30) {
        let dayStart = 0;
        while (addDays(startDateObj, dayStart) <= currentDate) {
          const dayStartStr = format(
            addDays(startDateObj, dayStart),
            "yyyy-MM-dd"
          );
          if (!grouped[dayStartStr]) {
            grouped[dayStartStr] = {
              date: dayStartStr,
              tipCount: 0,
              totalFees: 0,
            };
          }
          allDates.push(dayStartStr);
          dayStart += 2;
        }
      } else {
        for (
          let d = startDateObj;
          d <= currentDate;
          d.setDate(d.getDate() + 1)
        ) {
          const dateStr = format(d, "yyyy-MM-dd");
          if (!grouped[dateStr]) {
            grouped[dateStr] = {
              date: dateStr,
              tipCount: 0,
              totalFees: 0,
            };
          }
          allDates.push(dateStr);
        }
      }

      const sortedData = allDates
        .map((date) => grouped[date])
        .sort((a, b) => new Date(a.date) - new Date(b.date));

      setGroupedData(sortedData);
    }
  }, [tipsDataSummary, timePeriod]);

  if (loading) {
    return <div>Loading...</div>;
  }

  // if (!groupedData || groupedData.length === 0) {
  //   return <div>No data available</div>;
  // }
  if (loading || !groupedData || groupedData.length === 0) {
    return <div>Loading...</div>;
  }

  const labels = groupedData?.map((data, index) => {
    const date = new Date(data.date);
    if (timePeriod >= 90) {
      const startDate = new Date(data.date);
      const endDate = addDays(startDate, 5);
      const label = `${format(startDate, "MMM dd")} - ${format(endDate, "dd")}`;
      if (index % 2 === 0) return label;
      return "";
    }

    if (timePeriod >= 30) {
      const startDate = new Date(data.date);
      const endDate = addDays(startDate, 1);
      const label = `${format(startDate, "MMM dd")} - ${format(endDate, "dd")}`;
      if (index % 2 === 0) return label;
      return "";
    }

    return format(date, "MMM dd");
  });

  const tipsCount = groupedData.map((data) => data.tipCount);
  const totalFees = groupedData.map((data) => data.totalFees);

  const data = {
    labels,
    datasets: [
      {
        type: "line",
        label: "Guests",
        data: totalFees.map((fee) => fee / 100),
        fill: false,
        borderColor: "rgba(0, 174, 129, 1)",
        borderWidth: 2,
        borderDash: [5, 5],
        pointHoverRadius: 6,
        tension: 0.3,
      },
      {
        type: "bar",
        label: "Tips",
        data: totalFees.map((fee) => fee / 100),
        backgroundColor: "rgba(37, 205, 37, 0.3)",
        borderColor: "rgba(37, 205, 37, 0.3)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        mode: "nearest",
        intersect: true,
        callbacks: {
          title: () => "",
          label: function (context) {
            const index = context.dataIndex;
            const count = `Guests: ${tipsCount[index]}`;
            const amt = `Tips: $${totalFees[index] / 100}`;

            return [`${count}`, `${amt}`];
          },
        },
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        titleColor: "#fff",
        bodyColor: "#fff",
        borderColor: "rgba(0, 174, 129, 1)",
        borderWidth: 1,
        displayColors: false,
        bodySpacing: 4,
        padding: 10,
      },
    },
    scales: {
      y: {
        title: {
          display: false,
        },
        beginAtZero: true,
        ticks: {
          callback: (value) => `$${value}`,
          stepSize: 20,
        },
        grid: {
          color: "rgba(200, 200, 200, 0.2)",
        },
      },
      x: {
        grid: {
          display: true,
        },
        ticks: {
          autoSkip: false,
        },
      },
    },
    hover: {
      mode: "nearest",
      intersect: false,
    },
  };

  return (
    <div className="chart-container">
      <Line data={data} options={options} />
    </div>
  );
};

export default LineChart;
