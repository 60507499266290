import { createSlice } from "@reduxjs/toolkit";
import {getTipReceipt } from "../../api/api";

const initialState = {
    tipListData: null,
    loading: false,
    error: null,
  };
  
  const GetTipReceiptSlice = createSlice({
    name: 'tipreceipt',
    initialState,
    reducers: {
      
    },
    extraReducers: (builder) => {
      builder
        .addCase(getTipReceipt.pending, (state) => {
          state.loading = true;
          state.error = null; 
        })
        .addCase(getTipReceipt.fulfilled, (state, action) => {
          state.loading = false;
          state.tipListData = action.payload; 
        })
        .addCase(getTipReceipt.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload; 
        });
    },
  });
  
  
  export default GetTipReceiptSlice.reducer; 