import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import SideBar from "../../../components/SideBar/SideBar";
import Header from "../../../components/Header/Header";
import SelectCommunity from "../../../components/SelectCommunity/SelectCommunity";
import userinvite from "../../../assets/images/userinvite.png";
import upicon from "../../../assets/images/Up.png";
import PopupDialog from "../../../components/Popup/PopupDialog";
import AddUserForm from "../../../components/UserForm/AddUserForm";
import "./addbankaccount.css";
import { useDispatch } from "react-redux";
import { addBankAccountDetailAction } from "../../../redux/api/api";
import Footer from "../../../components/Footer/Footer";

const AddBankAccount = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const community_id = localStorage.getItem("selectedCommunity");
  const accessToken = localStorage.getItem("accesstoken");
  const [formData, setFormData] = useState({
    account_name: "",
    account_number: "",
    routing_number: "",
    account_type: "",
    swift_code: "",
    bank_name: "",
    is_primary: true,
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    validateSingleField(name, value);
  };

  const validateSingleField = (name, value) => {
    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };

      switch (name) {
        case "account_name":
        case "bank_name":
          if (!value.trim()) {
            updatedErrors[name] = `${name.replace("_", " ")} is required.`;
          } else {
            delete updatedErrors[name];
          }
          break;
        case "account_number":
        case "routing_number":
        case "swift_code":
          if (!/^\d*$/.test(value)) {
            updatedErrors[name] = `${name.replace("_", " ")} must contain only numbers.`;
          } else {
            delete updatedErrors[name];
          }
          break;
        case "account_type":
          if (!value) {
            updatedErrors[name] = "Account Type is required.";
          } else {
            delete updatedErrors[name];
          }
          break;
        default:
          break;
      }
      return updatedErrors;
    });
  };



  const validateFields = () => {
    let formErrors = {};

    if (!formData.account_name) {
      formErrors.account_name = "Account Name is required.";
    }

    if (!formData.account_number) {
      formErrors.account_number = "Account Number is required.";
    } else if (!/^\d+$/.test(formData.account_number)) {
      formErrors.account_number = "Account Number must contain only numbers.";
    }

    if (!formData.routing_number) {
      formErrors.routing_number = "Routing Number is required.";
    } else if (!/^\d+$/.test(formData.routing_number)) {
      formErrors.routing_number = "Routing Number must contain only numbers.";
    }

    if (!formData.swift_code) {
      formErrors.swift_code = "Swift Code is required.";
    } else if (!/^\d+$/.test(formData.swift_code)) {
      formErrors.swift_code = "Swift Code must contain only numbers.";
    }

    if (!formData.account_type) {
      formErrors.account_type = "Account Type is required.";
    }

    if (!formData.bank_name) {
      formErrors.bank_name = "Bank Name is required.";
    }

    setErrors(formErrors);

    // Return true if there are no errors
    return Object.keys(formErrors).length === 0;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateFields()) {
      return;
    }

    setLoading(true);
    const payload = { ...formData, is_primary: true };

    try {
      const response = await dispatch(addBankAccountDetailAction({ accessToken, payload }));
      if (addBankAccountDetailAction.fulfilled.match(response)) {
        toast.success("Form submitted successfully!");
        setTimeout(() => {
          navigate("/my-profile");
        }, 1000);
      } else {
        toast.error(response.payload?.detail || "Failed to submit form.");
      }
    } catch (error) {
      toast.error("An error occurred while submitting the form.");
    } finally {
      setLoading(false);
    }
  };

  const handleOpenDialog = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handleCloseDialog = () => {
    setIsPopupOpen(false);
  };

  const handleHomeClick = () => {
    navigate("/dashboard");
  };

  return (
    <div className="mainPageAddBankAccount mainLayoutPage">
      <ToastContainer />
      <SideBar />
      <div className="dashboard-container" style={{marginTop:'10px',marginRight:'10px',marginBottom:'10px', backgroundColor:"#f6f6f3",borderRadius:"20px"}}>
        <div className="header">
          <div>
            <div className="titleheading-content">Profile
              <div className="subtitle">
                <div className="home-text" onClick={handleHomeClick}>
                  <img src={upicon} alt="IconUp" />Home /
                </div>
                <div className="current-page-text">Add Profile</div>
              </div>
            </div>
            <div className="tabletScreenHeader">
              <Header />
            </div>
          </div>
          <div className="dropdownMain mainDropdownTablet">
            <div className="mediumScreenHeader">
              <div className="header-button">
                <SelectCommunity />
              </div>
              <button className="onboard-button" onClick={handleOpenDialog}>
                <span>Onboard New Staff</span>
                <img src={userinvite} alt="userIcon" />
              </button>
            </div>
          </div>
        </div>
        <div className="top-line"></div>
        <div className="mainUserAdd">
          <div className="headingAddUser">Add New Bank Account</div>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group p-2">
                  <label htmlFor="accountname">Account Holder Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="account_name"
                    value={formData.account_name}
                    onChange={handleInputChange}
                    placeholder="Enter your account holder name"
                  />
                  {errors.account_name && <span className="error-message">{errors.account_name}</span>}
                </div>
                <div className="form-group p-2">
                  <label htmlFor="accountnumber">Account Number</label>
                  <input
                    type="text"
                    className="form-control"
                    name="account_number"
                    value={formData.account_number}
                    onChange={handleInputChange}
                    placeholder="Enter your account number"
                  />
                  {errors.account_number && <span className="error-message">{errors.account_number}</span>}
                </div>
                <div className="form-group p-2">
                  <label htmlFor="bankname">Bank Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="bank_name"
                    value={formData.bank_name}
                    onChange={handleInputChange}
                    placeholder="Enter your bank name"
                  />
                  {errors.bank_name && <span className="error-message">{errors.bank_name}</span>}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group p-2">
                  <label htmlFor="routing_number">Routing Number</label>
                  <input
                    type="text"
                    className="form-control"
                    name="routing_number"
                    value={formData.routing_number}
                    onChange={handleInputChange}
                    placeholder="Enter your Routing Number"
                  />
                  {errors.routing_number && <span className="error-message">{errors.routing_number}</span>}
                </div>
                <div className="form-group p-2">
                  <label htmlFor="accounttype">Account Type</label>
                  <div class="form-group">
                    <select
                      className="form-control"
                      style={{height:'50px'}}
                      name="account_type"
                      value={formData.account_type}
                      onChange={handleInputChange}>
                      <option value="">Select Account Type</option>
                      <option value="SAVING">SAVING</option>
                      <option value="CHECKING">CHECKING</option>
                      <option value="BUSINESS CHECKING">BUSINESS CHECKING</option>
                    </select>
                  </div>
                  {errors.account_type && <span className="error-message">{errors.account_type}</span>}
                </div>
                {/* <div className="form-group p-2">
                  <label htmlFor="swiftcode">Swift Code</label>
                  <input
                    type="text"
                    className="form-control"
                    name="swift_code"
                    value={formData.swift_code}
                    onChange={handleInputChange}
                    placeholder="Enter your swift code"
                  />
                  {errors.swift_code && <span className="error-message">{errors.swift_code}</span>}
                </div> */}
              </div>
            </div>
            <div className="row">
              <div className="addUserGroupButton addUserButtonDecoration">
                <div>
                  <button
                    type="submit"
                    className="buttonSubmitForgetPassword"
                    disabled={loading}
                  >
                    {loading ? "Submitting..." : "Submit"}
                  </button>
                </div>
                <div className="m-4">
                  <Link to="/my-profile">
                    <button type="button" className="cancelledForgetPassword">
                      Cancel
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </form>

        <div style={{paddingTop:"20px"}}>
         <Footer />
        </div>

        </div>
        <PopupDialog
          open={isPopupOpen}
          onClose={handleCloseDialog}
          title="Onboard New Staff"
        >
          <AddUserForm
            accessToken={accessToken}
            community_id={community_id}
            cancelHandler={handleCloseDialog}
          />
        </PopupDialog>
      </div>
    </div>
  );
};

export default AddBankAccount;
