import { createSlice } from '@reduxjs/toolkit';
import { loginWithMobileAction } from '../../api/api';


// Initial state
const initialState = {
    loading: false,
    user: null,
    otpSent: false,
    error: null,
  };

const LoginWithMobileSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
      resetAuthState: (state) => {
        // state.loading = false;
        // state.user = null;
        // state.otpSent = false;
        // state.error = null;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(loginWithMobileAction.pending, (state) => {
          state.loading = true;
          state.error = null; 
        })
        .addCase(loginWithMobileAction.fulfilled, (state, action) => {
          state.loading = false;
          state.user = action.payload.user; 
          state.otpSent = true;
        })
        .addCase(loginWithMobileAction.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload; 
        });
    },
});

export default LoginWithMobileSlice.reducer;
