// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.trendChartBar {
  width: 100%;
  position: relative;
  height: 251px;
  padding-top: 15px;
}

.chart-container {
  width: 100%;
  height: 251px;
  margin: 10px 0;
  position: relative;
}

.chartjs-tooltip {
  background-color: rgba(0, 0, 0, 0.7) !important;
  color: #fff !important;
  padding: 10px !important;
  border-radius: 5px !important;
}

.chartjs-tooltip .tooltip-body {
  color: white;
  font-size: 14px;
}

.chartjs-x-axis-labels {
  color: #555;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
}

.chartjs-y-axis-labels {
  color: #555;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
}

.chartjs-chart {
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
`, "",{"version":3,"sources":["webpack://./src/components/TrendChart/trendchart.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;EAClB,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,+CAA+C;EAC/C,sBAAsB;EACtB,wBAAwB;EACxB,6BAA6B;AAC/B;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,sBAAsB;EACtB,mBAAmB;EACnB,yCAAyC;AAC3C","sourcesContent":[".trendChartBar {\n  width: 100%;\n  position: relative;\n  height: 251px;\n  padding-top: 15px;\n}\n\n.chart-container {\n  width: 100%;\n  height: 251px;\n  margin: 10px 0;\n  position: relative;\n}\n\n.chartjs-tooltip {\n  background-color: rgba(0, 0, 0, 0.7) !important;\n  color: #fff !important;\n  padding: 10px !important;\n  border-radius: 5px !important;\n}\n\n.chartjs-tooltip .tooltip-body {\n  color: white;\n  font-size: 14px;\n}\n\n.chartjs-x-axis-labels {\n  color: #555;\n  font-size: 12px;\n  font-weight: bold;\n  text-align: center;\n}\n\n.chartjs-y-axis-labels {\n  color: #555;\n  font-size: 12px;\n  font-weight: bold;\n  text-align: center;\n}\n\n.chartjs-chart {\n  border: 1px solid #ddd;\n  border-radius: 10px;\n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
