// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainPageAddDevice{
    display: flex;
    height: 100vh;
}
.error-message-device{
    color:var(--red-color); font-weight: var(--font-weight-600);
    font-size: var(--font-size-paragraph-14);
    font-family: var(--font-Family);  
    float: left;
    text-transform: capitalize;
}
.errorMessageHeight{
    margin-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/ManageDevice/AddDevice/adddevice.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;AACjB;AACA;IACI,sBAAsB,EAAE,mCAAmC;IAC3D,wCAAwC;IACxC,+BAA+B;IAC/B,WAAW;IACX,0BAA0B;AAC9B;AACA;IACI,mBAAmB;AACvB","sourcesContent":[".mainPageAddDevice{\n    display: flex;\n    height: 100vh;\n}\n.error-message-device{\n    color:var(--red-color); font-weight: var(--font-weight-600);\n    font-size: var(--font-size-paragraph-14);\n    font-family: var(--font-Family);  \n    float: left;\n    text-transform: capitalize;\n}\n.errorMessageHeight{\n    margin-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
