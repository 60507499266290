import { createSlice } from '@reduxjs/toolkit';
import { getEligiblePayout } from '../../api/api';

const initialState = {
  PayoutData: null, 
  loading: false, 
  error: null, 
};

const GetPayoutSlice = createSlice({
  name: 'payouteligible',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getEligiblePayout.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getEligiblePayout.fulfilled, (state, action) => {
        state.loading = false;
        state.PayoutData = action.payload;
      })
      .addCase(getEligiblePayout.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default GetPayoutSlice.reducer;
