import { createSlice } from '@reduxjs/toolkit';
import { addBankAccountDetailAction } from '../../api/api';

const initialState = {
  bankAccount: null,
  loading: false,
  error: null,
};

const AddNewBankAccountSlice = createSlice({
  name: 'bankAccount',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addBankAccountDetailAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addBankAccountDetailAction.fulfilled, (state, action) => {
        state.loading = false;
        state.bankAccount = action.payload;
      })
      .addCase(addBankAccountDetailAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default AddNewBankAccountSlice.reducer;
