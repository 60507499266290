import { createSlice } from '@reduxjs/toolkit';
import { AddAddressAction } from '../../api/api';

const AddAddressSlice = createSlice({
  name: 'addAddress',
  initialState: {
    loading: false,
    addAddressData: null,
    error: null,
  },
  reducers: {
    resetState: (state) => {
      state.loading = false;
      state.data = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(AddAddressAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(AddAddressAction.fulfilled, (state, action) => {
        state.loading = false;
        state.addAddressData = action.payload;
      })
      .addCase(AddAddressAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default AddAddressSlice.reducer;
