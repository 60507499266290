import { createSlice } from "@reduxjs/toolkit";
import { getPayoutChartAction } from "../../api/api";

const PayoutChartSlice = createSlice({
    name: 'payoutchart',
    initialState: {
      payoutChartData: null,
      selectedDate: null,
      loading: false,
      error: null,
    },
    reducers: {
      setSelectedDate(state, action) {
        state.selectedDate = action.payload;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(getPayoutChartAction.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(getPayoutChartAction.fulfilled, (state, action) => {
          state.loading = false;
          state.payoutChartData = action.payload;
        })
        .addCase(getPayoutChartAction.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        });
    },
  });
  
  export const { setSelectedDate } = PayoutChartSlice.actions;
  export default PayoutChartSlice.reducer;