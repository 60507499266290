import React, { useState, useEffect } from "react";
import "./helpandsupport.css";
import SideBar from "../../components/SideBar/SideBar";
import Header from "../../components/Header/Header";
import { useNavigate } from "react-router-dom";
import SelectCommunity from "../../components/SelectCommunity/SelectCommunity";
import { useDispatch, useSelector} from "react-redux";
import {ToastContainer } from "react-toastify";
import PopupDialog from "../../components/Popup/PopupDialog";
import AddUserForm from "../../components/UserForm/AddUserForm";
import upicon from "../../assets/images/Up.png"
import userinvite from "../../assets/images/userinvite.png";
import Footer from "../../components/Footer/Footer";
import { getHelpAndSupport } from "../../redux/api/api";


const HelpAndSupport = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const accessToken = localStorage.getItem("accesstoken");
  const community_id = localStorage.getItem("selectedCommunity");
  const { helpData } = useSelector((state) => state.GetHelpAndSupportStore);
  const [localhelpData, setLocalHelpData] = useState([]);
   
  useEffect(() => {
    if (accessToken) {
       dispatch(getHelpAndSupport({ accessToken}));
    }
  }, [accessToken, dispatch]);
      
 useEffect(() => {
    if (helpData) {
      setLocalHelpData(helpData);
    }
     }, [helpData]);

  const handleHomeClick = () => {
    navigate("/dashboard");
  };
  const handleOpenDialog = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handleCloseDialog = () => {
    setIsPopupOpen(false);
  };


  return (
    <>
    <div className="dashboard mainHelpANdSupportPage">
     <ToastContainer /> 
     <SideBar />
      <div className="dashboard-container" style={{marginTop:'10px',marginRight:'10px',marginBottom:'10px', backgroundColor:"#f6f6f3",borderRadius:"20px"}} >
      <div className="header">
          <div >
            <div className="titleheading-content">Help And Support
              <div className="subtitle">
                <div className="home-text" onClick={handleHomeClick}>
                  <img src={upicon} alt="IconUp" className="home-text"/> Home /</div>
                <div className="current-page-text">HelpSupport</div>
              </div>
            </div>
            <div className="tabletScreenHeader">
              <Header />
            </div>
          </div>
          <div className="dropdownMain mainDropdownTablet">
            <div className="mediumScreenHeader">
              <div className="header-button" > <SelectCommunity /></div>
              <button className="onboard-button" onClick={handleOpenDialog}>
                <span>Onboard New Staff</span>
                <img src={userinvite} alt="userIcon" />
              </button>
            </div>
          </div>
        </div>
        <div className="top-line"></div>
        <div className="row">
            <div className="contentSectionHelp">
                <div style={{padding:'20px'}}>
                    <div  style={{color:'#131313', paddingBottom:'10px'}}> Phone:  <a href={`tel:${localhelpData.mobile_number || 0}`} style={{color: '#000'}}>{localhelpData.mobile_number}</a></div>
                    <div style={{color:'#131313', paddingBottom:'10px'}}>Email: <span><a href={`mailto:${localhelpData.email}`}>{localhelpData.email || "NA"}</a></span></div>
                    <div style={{color:'#131313'}}>Website: <a href="https://www.requite.tech/#footer" target="_blank">https://www.requite.tech</a></div>
                </div>
            </div>
        </div>
       <div style={{marginTop:'20px'}}>
            <Footer />
        </div>
      </div>
       {/* Popup Dialog */}
       <PopupDialog
        open={isPopupOpen}
        onClose={handleCloseDialog}
        title="Add New User">
        <AddUserForm accessToken={accessToken} community_id={community_id} cancelHandler={handleCloseDialog} />
      </PopupDialog>
    </div>
    </>
  );
};

export default HelpAndSupport;
