// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LeftSideContent_logoContainer__UZ-ek {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90.5vh;
  margin-left: 100px;
  margin-right: 100px;
}
.LeftSideContent_logotapntip__fVXcm {
  width: 100%;
}
.LeftSideContent_logoText__NsYBh {
  width: 100%;
  padding-top: 15px;
  display: block;
  text-align: left;
  font-size: var(--font-size-heading-18);
  font-family: var(--font-Family);
  font-weight: 600;
  color: #fff;
}

@media only screen and (max-width: 1024px) {
  .LeftSideContent_logoContainer__UZ-ek {
    height: 32vh;
    position: relative;
    top: 50px;
    margin-left: 0px;
  }
  .LeftSideContent_logotapntip__fVXcm {
    width: 100%;
    text-align: center;
  }
  .LeftSideContent_logoText__NsYBh {
    text-align: center;
  }
}

@media only screen and (max-width: 768px) {
  .LeftSideContent_logoContainer__UZ-ek {
    height: 32vh;
    position: relative;
    top: 50px;
    margin-left: 0px;
  }
  .LeftSideContent_logoContainer__UZ-ek {
    margin-left: 0px;
    margin-right: 0px;
    text-align: center;
  }
  .LeftSideContent_logoText__NsYBh {
    text-align: center;
  }
  .LeftSideContent_logotapntip__fVXcm {
    width: 100%;
    text-align: center;
  }
  .LeftSideContent_logoText__NsYBh {
    text-align: center;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/LeftSideContent/LeftSideContent.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,cAAc;EACd,kBAAkB;EAClB,mBAAmB;AACrB;AACA;EACE,WAAW;AACb;AACA;EACE,WAAW;EACX,iBAAiB;EACjB,cAAc;EACd,gBAAgB;EAChB,sCAAsC;EACtC,+BAA+B;EAC/B,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE;IACE,YAAY;IACZ,kBAAkB;IAClB,SAAS;IACT,gBAAgB;EAClB;EACA;IACE,WAAW;IACX,kBAAkB;EACpB;EACA;IACE,kBAAkB;EACpB;AACF;;AAEA;EACE;IACE,YAAY;IACZ,kBAAkB;IAClB,SAAS;IACT,gBAAgB;EAClB;EACA;IACE,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;EACpB;EACA;IACE,kBAAkB;EACpB;EACA;IACE,WAAW;IACX,kBAAkB;EACpB;EACA;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".logoContainer {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  height: 90.5vh;\n  margin-left: 100px;\n  margin-right: 100px;\n}\n.logotapntip {\n  width: 100%;\n}\n.logoText {\n  width: 100%;\n  padding-top: 15px;\n  display: block;\n  text-align: left;\n  font-size: var(--font-size-heading-18);\n  font-family: var(--font-Family);\n  font-weight: 600;\n  color: #fff;\n}\n\n@media only screen and (max-width: 1024px) {\n  .logoContainer {\n    height: 32vh;\n    position: relative;\n    top: 50px;\n    margin-left: 0px;\n  }\n  .logotapntip {\n    width: 100%;\n    text-align: center;\n  }\n  .logoText {\n    text-align: center;\n  }\n}\n\n@media only screen and (max-width: 768px) {\n  .logoContainer {\n    height: 32vh;\n    position: relative;\n    top: 50px;\n    margin-left: 0px;\n  }\n  .logoContainer {\n    margin-left: 0px;\n    margin-right: 0px;\n    text-align: center;\n  }\n  .logoText {\n    text-align: center;\n  }\n  .logotapntip {\n    width: 100%;\n    text-align: center;\n  }\n  .logoText {\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logoContainer": `LeftSideContent_logoContainer__UZ-ek`,
	"logotapntip": `LeftSideContent_logotapntip__fVXcm`,
	"logoText": `LeftSideContent_logoText__NsYBh`
};
export default ___CSS_LOADER_EXPORT___;
