import { createSlice } from "@reduxjs/toolkit";
import { getCommunityDashboardAction } from "../../api/api";

// Create Slice
const GetDashboardPaymentSlice = createSlice({
    name: 'dashboard',
    initialState: {
      paymentList: [],
      loading: false,
      error: null,
    },
    reducers: {
    //   clearError(state) {
    //     state.error = null;
    //   },
    },
    extraReducers: (builder) => {
      builder
        .addCase(getCommunityDashboardAction.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(getCommunityDashboardAction.fulfilled, (state, action) => {
          state.loading = false;
          state.paymentList = action.payload;
        })
        .addCase(getCommunityDashboardAction.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        });
    },
  });
  
//   export const { clearError } = dashboardSlice.actions;
  export default GetDashboardPaymentSlice.reducer;