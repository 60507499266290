import React, { useEffect, useState } from "react";
import { getAddressBranch } from "../../redux/api/api";
import { useDispatch, useSelector } from "react-redux";
import './addressdetail.css'

const AddressDetail = () => {
  const dispatch = useDispatch();
  const accessToken = localStorage.getItem("accesstoken");
  const community_id = localStorage.getItem("selectedCommunity");
  const { addressData } = useSelector((state) => state.GetAddressDetailStore);
  const [localAddressData, setLocalAddressData] = useState([]);
   
  useEffect(() => {
    if (accessToken && community_id) {
     dispatch(getAddressBranch({ accessToken, community_id }));
    }
  }, [accessToken, community_id, dispatch]);
    
  useEffect(() => {
    if (addressData) {
      setLocalAddressData(addressData);
    }
   }, [addressData]);
   localStorage.setItem("address",localAddressData[0]?.address)
   localStorage.setItem("zip_code",localAddressData[0]?.zip_code)

    return(
        <>
          <div>
            <div className="headerCommonSetting"><h5 className="settingHeading">Address</h5></div>
              <div className="addressTextSection">
                  <div className="addressLabel">Address:</div>
                  <div className="userDetailText">{localAddressData[0]?.address} {localAddressData[0]?.zip_code}</div>
             </div>
          </div>
        </>
    )
}

export default AddressDetail