import React from "react";
import "./boxsubheader.css";

const DeviceBox = ({ header, subHeading, number, icon, width }) => {
  return (
    <div className="boxSizeSubHeader" style={{ width }}>
      <h5 className="deviceBoxHeader">
        {header} {icon && icon()}
      </h5>
      <span className="deviceTotalSubHeading">{subHeading}</span>
      <h5 className="totalNumberDevice">{number}</h5>
    </div>
  );
};

export default DeviceBox;
