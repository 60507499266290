import { createSlice } from '@reduxjs/toolkit';
import { getReaderHistoryAction } from '../../api/api';

const initialState = {
  readerHistory: null,
  loading: false,
  error: null,
};

const ReaderHistorySlice = createSlice({
  name: 'readerHistory',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReaderHistoryAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getReaderHistoryAction.fulfilled, (state, action) => {
        state.loading = false;
        state.readerHistory = action.payload;
      })
      .addCase(getReaderHistoryAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default ReaderHistorySlice.reducer;
