// import { createSlice } from "@reduxjs/toolkit";
// import { loginUser } from "../../api/api";

// const LoginUserSlice = createSlice({
//     name: 'auth',
//     initialState: {
//       user:'',
//       access_token: null,
//       status: 'idle',
//       error: null,
//     },
//     reducers: {
      
//       logout: (state) => {
//         state.user = null;
//         state.token = null;
//       },
//     },
    
//     extraReducers: (builder) => {
//       builder
//         .addCase(loginUser.pending, (state) => {
//           state.status = 'loading';
//         })
//         .addCase(loginUser.fulfilled, (state, action) => {
//           state.status = 'succeeded';
//           state.user = action.payload.user; 
//           state.access_token = action.payload.access_token; 
//         })
//         .addCase(loginUser.rejected, (state, action) => {
//           state.status = 'failed';
//           state.error = action.payload || 'Login failed';
//         });
//     },
//   });

//   export const { logout } = LoginUserSlice.actions;
//   export const selectUser = (state) => state.loginUser.user; 
//   export const selectAccessToken = (state) => state.loginUser.access_token; 

//   export default LoginUserSlice.reducer;