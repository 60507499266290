import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux"; 
import "./adddevice.css";
import SideBar from "../../../components/SideBar/SideBar";
import SelectCommunity from "../../../components/SelectCommunity/SelectCommunity";
import Header from "../../../components/Header/Header";
import { Link, useNavigate } from "react-router-dom";
import { addDeviceAction } from "../../../redux/api/api";
import upicon from "../../../assets/images/Up.png"
import userinvite from "../../../assets/images/userinvite.png";
import PopupDialog from "../../../components/Popup/PopupDialog";
import AddUserForm from "../../../components/UserForm/AddUserForm";
import Footer from "../../../components/Footer/Footer";

const AddDevices = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch(); 
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState("");
  const [errormessage, setErrorMessage] = useState("");
  const selectedCommunityId = localStorage.getItem("selectedCommunity");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const community_id = localStorage.getItem("selectedCommunity");
  const [formData, setFormData] = useState({
    readerName: "",
    softwareUpdateDueOn: "",
    serialNumber: "",
    softwareVersion: "",
    tapAmount:""
  });

  const accessToken = localStorage.getItem("accesstoken");

  useEffect(() => {
    if (selectedCommunityId) {
      setFormData((prevData) => ({
        ...prevData,
        communityId: selectedCommunityId
      }));
    }
  }, [selectedCommunityId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    
    if (errors[name]) {
      setErrors({ ...errors, [name]: "" });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({}); 

    const newErrors = {};
    Object.keys(formData)?.forEach((key) => {
      if (!formData[key]) {
        newErrors[key] = `${key.replace(/([A-Z])/g, ' $1')} is required.`;
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return; 
    }

    const community_id  = selectedCommunityId

    const payload = {
      serial_number: formData?.serialNumber,
      reader_friendly_name: formData?.readerName,
      software_version: formData?.softwareVersion,
      next_software_update_due: formData?.softwareUpdateDueOn,
      tapAmount: parseInt(formData?.tapAmount, 10),
    };
  
    setLoading(true); 
  
    if (!accessToken) {
      setErrorMessage("Access token is missing. Please log in.");
      setLoading(false); 
      return; 
    }
    try {
      const response = await dispatch(addDeviceAction({ accessToken, community_id, payload })).unwrap();
      setSuccess("Device added successfully!");
      navigate("/manage-device")
    
    } catch (error) {
      setErrorMessage(error?.detail || "An error occurred"); 
    } finally {
      setLoading(false);
    }
  };

  const handleHomeClick = () => {
    navigate("/dashboard");
  };

  const handleOpenDialog = () => {
    setIsPopupOpen(!isPopupOpen);
  };
  const handleCloseDialog = () => {
    setIsPopupOpen(false);
  };

  const backToDevice = () => {
    navigate("/manage-device")
  }

  return (
    <>
      <div className="mainPageAddDevice mainLayoutPage">
        <div className="sidebar">
          <SideBar />
        </div>
        <div className="dashboard-container" style={{marginTop:'10px',marginRight:'10px',marginBottom:'10px', backgroundColor:"#f6f6f3",borderRadius:"20px"}}>
         <div className="header">
          <div >
            <div className="titleheading-content">Device
              <div className="subtitle">
                <div className="home-text" onClick={handleHomeClick}>
                  <img src={upicon} alt="backHomeIcon"/> Home /</div>
                <div className="current-page-text" onClick={backToDevice}>Device</div>
                <div className="current-page-text"> /Add</div>

              </div>
            </div>
            <div className="tabletScreenHeader">
              <Header />
            </div>
          </div>
          <div className="dropdownMain mainDropdownTablet">
            <div className="mediumScreenHeader">

              <div className="header-button" > <SelectCommunity /></div>
              <button className="onboard-button" onClick={handleOpenDialog}>
                <span>Onboard New Staff</span>
                <img src={userinvite} alt="UserIcon" />
              </button>
            </div>
          </div>
        </div>

          <div className="mainUserAdd mt-4">
            <div className="headingAddUser">Add Device</div>
            <form onSubmit={handleSubmit}>
              <div className="row" style={{ minHeight: "250px" }}>
                <div className="col-sm-6">
                  <div className="form-group p-2 errorMessageHeight">
                    <label htmlFor="readerName">Reader Name </label>
                    <input
                      type="text"
                      className="form-control"
                      name="readerName"
                      value={formData?.readerName || "NA"}
                      onChange={handleChange}
                      placeholder="Enter Reader Name"
                    />
                    {errors?.readerName && <div className="error-message-device">{errors?.readerName}</div>}
                  </div>
                  <div className="form-group p-2 errorMessageHeight">
                    <label htmlFor="softwareUpdateDueOn">S/W Update Due On</label>
                    <input
                      type="text"
                      className="form-control"
                      name="softwareUpdateDueOn"
                      value={formData?.softwareUpdateDueOn || "NA"}
                      onChange={handleChange}
                      placeholder="Enter S/W Update Due On"
                    />
                    {errors?.softwareUpdateDueOn && <div className="error-message-device">{errors?.softwareUpdateDueOn}</div>}
                  </div>
                  <div className="form-group p-2 errorMessageHeight">
                    <label htmlFor="readerFixedTipAmt">Tip Amount (cent)</label>
                    <input
                      type="text"
                      className="form-control"
                      name="tapAmount"
                      value={formData.tapAmount || "NA"}
                      onChange={handleChange}
                      placeholder="Fixed Tip Amount this reader will accept."
                    />
                    {errors?.softwareVersion && <div className="error-message-device">{errors?.softwareVersion}</div>}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group p-2 errorMessageHeight">
                    <label htmlFor="serialNumber">Serial Number</label>
                    <input
                      type="text"
                      className="form-control"
                      name="serialNumber"
                      value={formData?.serialNumber || "NA"}
                      onChange={handleChange}
                      placeholder="Enter Serial Number"
                    />
                    {errors?.serialNumber && <div className="error-message-device">{errors?.serialNumber}</div>}
                  </div>
                  <div className="form-group p-2 errorMessageHeight">
                    <label htmlFor="softwareVersion">Software Version</label>
                    <input
                      type="text"
                      className="form-control"
                      name="softwareVersion"
                      value={formData?.softwareVersion || "NA"}
                      onChange={handleChange}
                      placeholder="Enter Software Version" />
                    {errors?.softwareVersion && <div className="error-message-device">{errors?.softwareVersion}</div>}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="addUserGroupButton addUserButtonDecoration">
                  <div>
                    <button
                      type="submit"
                      className="buttonSubmitForgetPassword"
                      disabled={loading}
                    >
                      {loading ? "Submitting..." : "Submit"}
                    </button>
                  </div>
                  <div className="m-4">
                    <Link to="/manage-device">
                      <button type="button" className="cancelledForgetPassword">
                        Cancel
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              {success && <div className="success-message">{success}</div>}
              {errormessage && <div className="error-message">{errormessage}</div>}
            </form>
          </div>
          <div style={{marginTop:'20px'}}>
            <Footer />
          </div>
        </div>
        <PopupDialog
        open={isPopupOpen}
        onClose={handleCloseDialog}
        title="Add New User">
        <AddUserForm accessToken={accessToken} community_id={community_id} cancelHandler={handleCloseDialog} />
      </PopupDialog>
      </div>
    </>
  );
};

export default AddDevices;
