// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.footertapNtipdashboard{
    width: 100%;
    height: 38px;
    padding-top: 20px;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/footer.css"],"names":[],"mappings":";AACA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,yBAAyB;IACzB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;AACtB","sourcesContent":["\n.footertapNtipdashboard{\n    width: 100%;\n    height: 38px;\n    padding-top: 20px;\n    background-color: #ffffff;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    border-radius: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
