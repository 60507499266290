import { createSlice } from "@reduxjs/toolkit";
import { getCommunityTipSummaryChartTrendAction } from "../../api/api";

const initialState = {
    communityTrendSummaryData: null,
    loading: false,
    error: null,
  };
  
  const GetCommunityTipSummarySlice = createSlice({
    name: 'summaryTrend',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
      builder
        .addCase(getCommunityTipSummaryChartTrendAction.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(getCommunityTipSummaryChartTrendAction.fulfilled, (state, action) => {
          state.loading = false;
          state.communityTrendSummaryData = action.payload;
        })
        .addCase(getCommunityTipSummaryChartTrendAction.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        });
    },
  });
  
  export const { actions: communityTipSummaryActions, reducer: communityTipSummaryReducer } = GetCommunityTipSummarySlice;
  
  export default GetCommunityTipSummarySlice.reducer;