import { createSlice } from '@reduxjs/toolkit';
import { addBankAccountDetailCommunityAction } from '../../api/api';

const initialState = {
  communityBankAccount: null,
  loading: false,
  error: null,
};

const AddCommunityBankAccountSlice = createSlice({
  name: 'bankAccountcommunity',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addBankAccountDetailCommunityAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addBankAccountDetailCommunityAction.fulfilled, (state, action) => {
        state.loading = false;
        state.communityBankAccount = action.payload;
      })
      .addCase(addBankAccountDetailCommunityAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default AddCommunityBankAccountSlice.reducer;
