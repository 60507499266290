import React, { useEffect, useState } from "react";
import SideBar from "../../components/SideBar/SideBar";
import SelectCommunity from "../../components/SelectCommunity/SelectCommunity";
import { useNavigate } from "react-router-dom";
import userinvite from "../../assets/images/userinvite.png";
import upicon from "../../assets/images/Up.png";
import AddUserForm from "../../components/UserForm/AddUserForm";
import PopupDialog from "../../components/Popup/PopupDialog";
import "./managepayout.css";
import {
  getCommunityList,
  getDeletePayoutAction,
  getEligiblePayout,
  getPayoutListHistoryAction,
} from "../../redux/api/api";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../../components/Footer/Footer";
import {
  convertToUSFormat,
  convertToUTC,
  convertToUTCDateOnly,
  convertToUTCUSA,
} from "../../Utility/Utility";
import { toast, ToastContainer } from "react-toastify";
import downloadcircle from "../../assets/images/downloadcircle.png";
import DatePicker from "react-datepicker";

const ManagePayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const accessToken = localStorage.getItem("accesstoken");
  const community_id = localStorage.getItem("selectedCommunity");
  const { PayoutData } = useSelector((state) => state.GetPayoutStore);
  const { payoutHistoryData, loading } = useSelector(
    (state) => state.PayoutListHistoryStore
  );
  const [payoutList, setPayoutList] = useState();
  const [tilsDataPayout, setTilsDataPayout] = useState();
  const [expandedRow, setExpandedRow] = useState(null);
  const [expandedRowStaff, setExpandedRowStaff] = useState(null);
  const [filteredData, setFilteredData] = useState(payoutHistoryData);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [searchMobile, setSearchMobile] = useState("");
  const today = new Date();
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(today.getDate() - 30);
  const [startDate, setStartDate] = useState(thirtyDaysAgo);
  const [endDate, setEndDate] = useState(today);
  const systemTimeSpanOffset = new Date().getTimezoneOffset();

  useEffect(() => {
    setFilteredData(payoutHistoryData);
  }, [payoutHistoryData]);

  const handleApplyFilter = () => {
    if (!startDate || !endDate) {
      alert("Please select both Start Date and End Date before applying the filter.");
      return;
    }
  
    if (!(startDate instanceof Date && !isNaN(startDate)) || 
        !(endDate instanceof Date && !isNaN(endDate))) {
      console.error("Invalid date(s) selected");
      return;
    }
  
    if (startDate > endDate) {
      console.error("Start date cannot be later than end date");
      return;
    }
  
    const formattedStartDate = startDate?.toISOString().split("T")[0];
    const formattedEndDate = endDate?.toISOString().split("T")[0];
  
    const filtered = payoutHistoryData?.filter((item) => {
      const payoutDate = new Date(convertToUSFormat(item.created_ts))
        ?.toISOString()
        .split("T")[0];
  
      return payoutDate >= formattedStartDate && payoutDate <= formattedEndDate;
    });
  
    setFilteredData(filtered);
  };
  

  const handleSearchChange = (e) => {
    setSearchMobile(e.target.value);
  };

  const toggleRowStaff = (id) => {
    setExpandedRowStaff((prevExpandedRowStaff) => {
      if (prevExpandedRowStaff === id) {
        return null;
      }
      setExpandedRow(null);
      return id;
    });
  };

  // useEffect(() => {
  //   if (accessToken && community_id) {
  //     const formattedStartDate = startDate
  //       ? startDate.toISOString().split("T")[0]
  //       : null;
  //     const formattedEndDate = endDate
  //       ? endDate.toISOString().split("T")[0]
  //       : null;

  //     dispatch(getPayoutListHistoryAction({
  //         accessToken,
  //         community_id,
  //         startDate: formattedStartDate,
  //         endDate: formattedEndDate,
  //         timezoonoffset: systemTimeSpanOffset,
  //       })
  //     );
  //   }
  // }, [accessToken, community_id, dispatch]);



  useEffect(() => {
    if (accessToken && community_id && startDate && endDate) {
      // Calculate the difference in months
      const monthDifference = 
        (endDate.getFullYear() - startDate.getFullYear()) * 12 + 
        (endDate.getMonth() - startDate.getMonth());
      
      if (monthDifference >= 1) {
        const formattedStartDate = startDate.toISOString().split("T")[0];
        const formattedEndDate = endDate.toISOString().split("T")[0];
  
        dispatch(
          getPayoutListHistoryAction({
            accessToken,
            community_id,
            startDate: formattedStartDate,
            endDate: formattedEndDate,
            timezoonoffset: systemTimeSpanOffset,
          })
        );
      }
    }
  }, [accessToken, community_id, startDate, endDate, dispatch]);
  

  useEffect(() => {
    if (payoutHistoryData) {
      setPayoutList(payoutHistoryData);
    }
  }, [payoutHistoryData]);

  useEffect(() => {
    if (accessToken && community_id) {
      dispatch(getEligiblePayout({ accessToken, community_id }));
    }
  }, [accessToken, community_id, dispatch]);

  useEffect(() => {
    if (PayoutData) {
      setTilsDataPayout(PayoutData);
    }
  }, [PayoutData]);

  useEffect(() => {
    if (accessToken && community_id) {
      dispatch(getCommunityList({ accessToken, community_id }));
    }
  }, [dispatch, accessToken, community_id]);

  const handleOpenDialog = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handleCloseDialog = () => {
    setIsPopupOpen(false);
  };

  const handleHomeClick = () => {
    navigate("/dashboard");
  };

  const paidOn = PayoutData?.last_payout_summary?.last_update_ts;
  const paidAmount = PayoutData?.last_payout_summary?.net / 100;
  const paidDistributionRule =
    PayoutData?.last_payout_summary?.distribution_rule;

  const totalPendingPayout = PayoutData?.net_tip_amount / 100;
  const FromData = PayoutData?.tip_from_date;
  const ToData = PayoutData?.tip_to_date;

  const initiatePayout = (totalPendingPayout, FromData, ToData) => {
    navigate("/preview-payout", {
      state: { totalPendingPayout, FromData, ToData },
    });
  };

  const filteredAndActiveData = filteredData?.filter(
    (item) => item.status !== "delete"
  );
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentPayoutList = filteredAndActiveData?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalItems = filteredAndActiveData?.length || 0;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  useEffect(() => {
    setCurrentPage(1);
  }, [filteredData]);

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const Loader = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}>
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        Loading...
      </span>
    </div>
  );

  const handleDelete = (payout_id) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this payout?"
    );
    if (isConfirmed) {
      dispatch(
        getDeletePayoutAction({ accessToken, community_id, payout_id })
      ).then(() => {
        setFilteredData((prevData) =>
          prevData?.filter((item) => item?.payout_id !== payout_id)
        );
        toast("Payout deleted successfully!", {
          autoClose: 3000,
          type: "success",
        });
      });
    }
  };

  const downloadSelectedData = () => {
    if (!filteredData || filteredData?.length === 0) {
      toast("No data to download for the selected date range.");
      return;
    }
    const headers = [
      "Payout Id",
      "Status",
      "Date/Time of Payment",
      "From Date",
      "To Date",
      "Total Amount Paid",
      "Payout Type",
      "Payout Strategy",
    ];
    const csvRows = filteredData.map((item, index) => [
      index + 1,
      item?.payout_id || "NA",
      item?.status || "NA",
      convertToUTC(item?.created_ts || "NA"),
      convertToUTCDateOnly(item?.tip_from_date || "NA"),
      convertToUTCDateOnly(item?.tip_to_date || "NA"),
      `$${item?.net / 100 || "0"}`,
      `Manual`,
      item?.distribution_rule || "NA",
    ]);

    const csvContent = [
      headers?.join(","),
      ...csvRows.map((row) => row?.join(",")),
    ].join("\n");

    // Create CSV file and download
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `tips_summary_${startDate}_to_${endDate}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  console.log("payout history detail", currentPayoutList)
  return (
    <>
      <ToastContainer />
      <div className="payoutMainPage mainLayoutPage">
        <SideBar />
        <div className="dashboard-container borderFormat">
          <div className="header">
            <div>
              <div className="titleheading-content">
                Payout
                <div className="subtitle">
                  <div className="home-text" onClick={handleHomeClick}>
                    <img src={upicon} alt="upIcon" /> Home /
                  </div>
                  <div className="current-page-text"> Manage payout</div>
                </div>
              </div>
            </div>
            <div className="dropdownMain mainDropdownTablet">
              <div className="mediumScreenHeader">
                <div className="header-button">
                  {" "}
                  <SelectCommunity />
                </div>
                <button className="onboard-button" onClick={handleOpenDialog}>
                  <span>Onboard New Staff</span>
                  <img src={userinvite} alt="logoUser" />
                </button>
              </div>
            </div>
          </div>

          <div className="top-line"></div>
          <div className="tilsOfPayout">
            <div className="TileOnePayout">
              <div className="HeadingOnePayout">Eligible Payout</div>
              <div className="subHeadingPayout textSubHeadingPay">
                This Community
              </div>
              <div className="createPayoutSection">
                <span className="payoutAmount">
                  <b>${totalPendingPayout || 0}</b>
                </span>
                <span className="createButton">
                  <button
                    className="onboard-button"
                    onClick={() =>
                      initiatePayout(totalPendingPayout, FromData, ToData)
                    }
                    disabled={totalPendingPayout <= 0} // Disable button if totalPendingPayout <= 0
                  >
                    Initiate Payout
                  </button>
                </span>
              </div>
              <div></div>
            </div>
            <div className="TileSecondPayout">
              <div className="HeadingTwoPayout">Last Payout</div>
              <div className="subHeadingPayout textSubHeadingPay">
                Most Recent
              </div>
              <div className="payoutDetailSection">
                <div>
                  <span>
                    <b>Paid On:</b>
                  </span>{" "}
                  {paidOn || "NA"}
                </div>
                <div className="payoutDetailAmount">
                  <span>
                    <b>Amount:</b>
                  </span>{" "}
                  ${paidAmount || 0}
                </div>
                <div className="payoutDetailPaid">
                  <span>
                    <b>Payout Method:</b>
                  </span>{" "}
                  {paidDistributionRule || "NA"}
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="header-search-contaner">
              <div className="countContainer">
                <span className="titleheading-content">Payout History</span>
                <span className="staffs-badge">
                  {PayoutData?.total_payouts_count || "0"}
                </span>
                <span className="titleheading-subcontent">
                  {" "}
                  • Browse and select a payout to know more...
                </span>
              </div>
              <div className="header-icons"></div>
            </div>
          </div>

          <div className="top-line" style={{ marginTop: "10px" }}></div>

          <div
            className="mainTableHeaderTipsAll"
            style={{ paddingTop: "15px" }}>
            <div className="deviceFilterSection">
              <div className="date-range-picker">
                <div style={{ display: "flex", alignItems: "center" }}>
                  Choose Date Range: From
                </div>
                <div>
                  <DatePicker
                    className="customDate"
                    selected={startDate ?? "NA"}
                    onChange={(date) => {
                      setStartDate(date);
                    }}
                    maxDate={today}
                    dateFormat="MM/dd/yyyy"
                    placeholderText="Select start date"
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>To</div>
                <div>
                  <DatePicker
                    className="customDate"
                    selected={endDate ?? "NA"}
                    onChange={(date) => setEndDate(date)}
                    maxDate={today}
                    dateFormat="MM/dd/yyyy"
                    placeholderText="Select end date"
                  />
                </div>
              </div>
              <div className="buttonAndDownload">
                <div>
                  <button className="applyButton" onClick={handleApplyFilter}>
                    Apply
                  </button>
                </div>
                <div
                  className="downloadIconAllTips"
                  onClick={downloadSelectedData}>
                  <img
                    src={downloadcircle}
                    alt="downloadcircle"
                    style={{ width: "30px", height: "30px", cursor: "pointer" }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="listOfPayoutHistory">
              <div className="table-responsive deviceTable">
              <table className="table tablebordered table-hover mt-2">
              <thead className="table-header" style={{borderRadius:'12px !important'}}>
                <tr>
                  <th style={{width:'8%'}}></th>
                  <th scope="col">Payout Record</th>
                  <th scope="col">Status</th>
                  <th scope="col">Date/Time of Payment</th>
                  <th scope="col">Total Amount Paid</th>
                  <th scope="col">Payout Type</th>
                  <th scope="col">Payout Strategy</th>
                </tr>
              </thead>
              {loading ? (
                <tbody>
                  <tr>
                    <td colSpan="7" style={{ textAlign: 'center' }}>
                      <Loader />
                    </td>
                  </tr>
                </tbody>
              ) : (
              <tbody>
              {currentPayoutList?.length > 0 ? (
                      currentPayoutList?.map((item, index) => (
                        <React.Fragment key={index + 1}>
                          <tr>
                            <td className="td-default" style={{width:'8%'}}>
                              {item?.status === "initiated" && (
                                <button
                                  type="button"
                                  className="btn btn-outline-danger  btn-sm"
                                  onClick={() => handleDelete(item?.payout_id)}>
                                  {" "}
                                  Delete{" "}
                                </button>
                              )}
                            </td>
                            <td className="td-default">
                              <span style={{ marginLeft: "10px" }}>
                                {item?.payout_id?.split("-").pop() || "NA"}
                              </span>
                              <br></br>
                              <span
                                onClick={() => toggleRowStaff(index + 1)}
                                className="moreAndLessText">
                                {expandedRowStaff === index + 1
                                  ? "- Paid to: "
                                  : " + Paid to:"}
                              </span>
                            </td>
                            <td className="td-default"  style={{textTransform:"capitalize"}}>
                              {item?.status || "NA"}
                            </td>
                            <td className="td-default">
                              {convertToUTCUSA(item?.created_ts || "NA")}
                            </td>
                            <td className="td-default">
                              ${item?.net / 100 || 0}
                            </td>
                            <td className="td-default">Manual</td>
                            <td className="td-default"  style={{textTransform:"capitalize"}}>
                              {item?.distribution_rule || "NA"}
                            </td>
                          </tr>
                          {expandedRowStaff === index + 1 && (
                            <>
                              <tr
                                className="table-header"
                                style={{ maxHeight: "436px" }}>
                                <th scope="col" style={{ width: "10%" }}></th>
                                <th scope="col">Name</th>
                                <th scope="col">Mobile Number</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Status</th>
                                <th scope="col">
                                  <input
                                    type="text"
                                    placeholder="Search by mobile"
                                    value={searchMobile}
                                    className="form-control custom-placeholder"
                                    onChange={handleSearchChange}
                                    style={{
                                      height: "30px",
                                      width: "150px",
                                      borderRadius: "8px",
                                      fontSize: "12px",
                                    }}
                                  />
                                </th>
                                <th scope="col"></th>
                              </tr>
                              {item?.members
                                ?.filter((member) =>
                                  member?.mobile_number
                                    ?.toLowerCase()
                                    .includes(searchMobile.toLowerCase()))
                                .map((memberDetail, detailIndex1) => (
                                  <tr
                                    key={detailIndex1}
                                    style={{ cursor: "pointer" }}>
                                    <td
                                      className="td-default-device"
                                      style={{ width: "10%" }}></td>
                                    <td className="td-default-device">
                                      {memberDetail?.name || "NA"}
                                    </td>
                                    <td className="td-default-device">
                                      {memberDetail?.mobile_number || "NA"}
                                    </td>
                                    <td className="td-default-device">
                                      ${memberDetail?.net / 100 || "NA"}
                                    </td>
                                    <td className="td-default-device" style={{textTransform:'capitalize'}}>
                                      {memberDetail?.status || 0}
                                    </td>
                                    <td className="td-default-device"></td>
                                    <td className="td-default-device"></td>
                                  </tr>
                                ))}
                            </>
                          )}
                        </React.Fragment>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan="10"
                          style={{ textAlign: "center", padding: "10px" }}>
                          Data Not Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                )}
              </table>
            </div>
          </div>

          <div className="pagination mt-4">
            <button
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
              className="nextPageButton"
              style={{ backgroundColor: "#00c2d1", borderRadius: "8px" }}>
              Previous
            </button>
            <span style={{ paddingTop: "5px", margin: "0 10px" }}>
              {`Page ${currentPage} / ${totalPages}`}
            </span>
            <button
              onClick={handleNextPage}
              className="nextPageButton"
              disabled={
                currentPayoutList?.length === 0 ||
                filteredData?.length <= itemsPerPage ||
                currentPage === totalPages
              }>
              Next
            </button>
          </div>
          <div style={{ paddingTop: "20px" }}>
            <Footer />
          </div>
        </div>
        <PopupDialog
          open={isPopupOpen}
          onClose={handleCloseDialog}
          title="Onboard New Staff">
          <AddUserForm
            accessToken={accessToken}
            community_id={community_id}
            cancelHandler={handleCloseDialog}
          />
        </PopupDialog>
      </div>
    </>
  );
};

export default ManagePayout;
