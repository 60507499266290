import { createSlice } from '@reduxjs/toolkit';
import { UpdateCommunityTipAmountAction } from '../../api/api';

const initialState = {
  loading: false,
  error: null,
  success: false,
};

const UpdateCommunityTipAmountSlice = createSlice({
  name: 'tipAmount',
  initialState,
  reducers: {
    // resetState: (state) => {
    //   state.loading = false;
    //   state.error = null;
    //   state.success = false;
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(UpdateCommunityTipAmountAction.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(UpdateCommunityTipAmountAction.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(UpdateCommunityTipAmountAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.success = false;
      });
  },
});

// export const { resetState } = tipAmountSlice.actions;
export default UpdateCommunityTipAmountSlice.reducer;
