import { createSlice } from '@reduxjs/toolkit';
import { getCommunityList } from '../../api/api';

const communityListSlice = createSlice({
  name: 'community',
  initialState: {
    dataList: [],
    loading: false,
    error: null,
  },
  reducers: {
    // You can add additional reducers here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCommunityList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCommunityList.fulfilled, (state, action) => {
        state.loading = false;
        state.dataList = action.payload;
      })
      .addCase(getCommunityList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default communityListSlice.reducer;
