import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getSettingAction, memberInfoAction } from '../../redux/api/api';
import "./selectcommunity.css"

const SelectCommunity = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const [communitiesData, setCommunitiesData] = useState([]);
  const [allverifydata, setAllVerifyData] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [allDataCommunity, setAllDataCommunity] = useState();
  const [selectedCommunity, setSelectedCommunity] = useState(
    localStorage.getItem('selectedCommunity') || '');
  const [loading, setLoading] = useState(false); 
  const { settingData  } = useSelector((state) => state.SettingStore);
  const community_id = localStorage.getItem('selectedCommunity')
  const accessToken = localStorage.getItem('accesstoken');

  useEffect(() => {
    if (accessToken) {
      fetchMemberData();
    }
  }, [accessToken, dispatch]);

  localStorage.setItem("manage_by", settingData?.payout_config?.managed_by)

  useEffect(() => {
    if (accessToken && community_id) {
      dispatch(getSettingAction({ accessToken, community_id }));
    }
  }, [accessToken, community_id, dispatch]);
  

  const fetchMemberData = async () => {
    try {
      const response = await dispatch(memberInfoAction({ accessToken }));
      if (memberInfoAction.fulfilled.match(response)) {
        const communities = response.payload.communities;
        setCommunitiesData(communities);
        setAllVerifyData(response?.payload);
        const storedCommunityId = localStorage.getItem('selectedCommunity');
        if (storedCommunityId) {
          setSelectedCommunity(storedCommunityId);
        } else {
          const defaultCommunity = communities[0];
          setSelectedCommunity(defaultCommunity?.community_id);
          localStorage.setItem('selectedCommunity', defaultCommunity.community_id); 
        }
        setAllDataCommunity({
          ...response.payload,
          communities: communities[0],
        });
      } else {
        setErrorMessage(response?.payload?.detail || 'Failed to fetch member info');
      }
    } catch (error) {
      setErrorMessage('An unexpected error occurred.');
    }
  };

  const handleCommunityChange = (e) => {
    const selectedId = e.target.value;
    setSelectedCommunity(selectedId);
    localStorage.setItem('selectedCommunity', selectedId);
    const selectedCommunityData = allverifydata?.communities?.find(
      (item) => item?.community_id === selectedId);
    const newObj = {
      ...allverifydata,
      communities: selectedCommunityData,
    };
    setAllDataCommunity(newObj);
    navigate('/dashboard'); 
    window.location.reload(); 
  };

  const selectedCommunityData = communitiesData?.find(
    (item) => item?.community_id === selectedCommunity);
  localStorage.setItem("brandAssets",selectedCommunityData?.brand_assets_s3_url)

  const selectedCOmmunityLogo = communitiesData?.find((item) => item?.community_id === selectedCommunity)
  localStorage.setItem("communityLogo",selectedCOmmunityLogo?.logo_url)

  return (
    <div className="selectedCom">
      {loading && ( 
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}
      <div className="selectedCommunity">
      {communitiesData?.length === 1 ? (
          <div className="single-community-text">
            <p>{communitiesData[0]?.community_name || "NA"}</p>
          </div>
        ) : (
      <select className="form-select form-select1"
            aria-label="Select Community"
            value={selectedCommunity}
            onChange={handleCommunityChange}
            disabled={loading}>
            {communitiesData?.filter(
                (item) =>
                  item.status === true && 
                  ((item.permission.includes("COLLECT_TIP_ACCESS") &&
                    item.permission.includes("ADMIN_PORTAL_ACCESS")) ||
                    item.permission.includes("ADMIN_PORTAL_ACCESS"))
              )
              .map((item) => (
                <option key={item?.community_id} value={item?.community_id}>
                  {item?.community_name || "NA"}
                </option>
              ))}
          </select>)}
      </div>
    </div>
  );
};

export default SelectCommunity;
