import React from 'react';
import "./fallbackerrormessage.css"
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        console.error("Error:", error, info);
    }

    render() {
        if (this.state.hasError) {
            return <div>
                <h1 className="globalErrorHandle">Something went wrong.</h1>;
                <p className="globalErrorHandle">Please try after some time</p>
            </div> 
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
