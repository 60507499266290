import { createSlice } from "@reduxjs/toolkit";
import { getallTipsSummary } from "../../api/api";

const GetAllTipsSummarySlice = createSlice({
    name: 'alltips',
    initialState: {
      tipsDataSummary: [],
      loading: false,
      error: null,
    },
    reducers: {
      resetTips: (state) => {
        state.tipsData = [];
        state.loading = false;
        state.error = null;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(getallTipsSummary.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(getallTipsSummary.fulfilled, (state, action) => {
          state.loading = false;
          state.tipsDataSummary = action.payload;
        })
        .addCase(getallTipsSummary.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload || 'Failed to fetch data';
        });
    },
  });
  
  export default GetAllTipsSummarySlice.reducer;