import React from 'react'
import TipNTapLogo from '../../assets/images/TapNtipLogo.png'
import styles from "./RightSideContent.module.css"

const RightSideContent = () => {
  return (
    <div className={styles.logoContainer} >
      <div className={styles.logotapntip}>
      <img src={TipNTapLogo} alt='logo' />
      </div>
      <div className={styles.logoText}>Empowering Service with Seamless Tipping</div>
    </div>
  )
}

export default RightSideContent