import React, { useState, useEffect } from "react";
import "./Login.css";
import RightSideContent from "../../components/RightSideContent/RightSideContent";
import Refreshimage from "../../assets/images/refresh.png"
import LeftSideImage from '../../assets/images/LeftSideImage.png'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  loginWithMobileAction,
  memberInfoAction,
  otpAction,
} from "../../redux/api/api";




const countryCodes = [{ code: "+1", label: "USA (+1)" }, { code: "+91", label: "India (+91)" }];

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation(); 
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [otpiddata, setOtpIdData] = useState(null);
  const [accesstoken, setAccessToken] = useState();
  const [permissionError,setPermissionError] = useState("")
  const [selectedCommunity, setSelectedCommunity] = useState(false);
  const [loginForm, setLoginForm] = useState({
    mobile_number: "",
    country_code: "+1", 
    purpose: "LOGIN",
  });
  const [memberDataInfo, setMemberDataInfo] = useState()
  const [errorDisable, setErrorDisable] = useState()
  const [otpData, setOtpData] = useState({
    otp_id: "",
    otp_code: "",
  });
  
  const { otpSent, loading } = useSelector((state) => state.LoginWithMobile);
  const [resendTimer, setResendTimer] = useState(false);
  const [remainingTime, setRemainingTime] = useState(15);
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [timerId, setTimerId] = useState(null);
  const loginLeftSideBanner = localStorage.getItem("brandAssets");
  const backgroundImageLogin = loginLeftSideBanner && loginLeftSideBanner !== "null" 
    ? loginLeftSideBanner 
    : LeftSideImage;


  useEffect(() => {
    if (location.pathname === "/") {
      // localStorage.clear();
      localStorage.removeItem("accesstoken");
      localStorage.removeItem("refreshToken");
      // localStorage.removeItem("communityLogo");
    }
  }, [location]);


  useEffect(() => {
    if (otpiddata) {
      setOtpData((prev) => ({ ...prev, otp_id: otpiddata }));
      localStorage.removeItem('selectedCommunity');
    }
  }, [otpiddata]);

  useEffect(() => {
    if (remainingTime > 0 && otpSent) {
      const id = setInterval(() => {
        setRemainingTime((prev) => prev - 1);
      }, 1000);
      setTimerId(id);

      return () => clearInterval(id);
    } else if (remainingTime === 0) {
      setResendTimer(true);
      setShowOtpInput(false);
    }
  }, [remainingTime, otpSent]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "mobile_number" && !/^\d*$/.test(value)) return;
    setLoginForm((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (errorMessage) setErrorMessage("");
  };

  const handleOtpChange = (e) => {
    const { name, value } = e.target;
    setOtpData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!loginForm.mobile_number || loginForm.mobile_number.length !== 10) {
      setErrorMessage("Please enter a valid 10-digit mobile number.");
      return;
    }
    const fullMobileNumber = `${loginForm.country_code}${loginForm.mobile_number}`;
    try {
      const resultAction = await dispatch(loginWithMobileAction({ ...loginForm, mobile_number: fullMobileNumber }));
      if (loginWithMobileAction.fulfilled.match(resultAction)) {
        setOtpIdData(resultAction?.payload?.otp_id);
        setSuccessMessage("OTP sent successfully!");
        setRemainingTime(15);
        setResendTimer(false);
        setShowOtpInput(true);
        setTimeout(() => {
          setSuccessMessage("");
        }, 5000);
      } else {
        setErrorMessage(resultAction.payload?.detail || "Failed to send OTP");
      }
    } catch (err) {
      setErrorMessage("An unexpected error occurred.");
    }
  };

  const handleResendOtp = async () => {
    try {
      const resultAction = await dispatch(loginWithMobileAction(loginForm));
      if (loginWithMobileAction.fulfilled.match(resultAction)) {
        setOtpIdData(resultAction?.payload?.otp_id);
        setSuccessMessage("OTP resent successfully!");
         // Reset timer
         clearInterval(timerId); 
         setRemainingTime(15);
         setResendTimer(false);
         setShowOtpInput(true);
        setTimeout(() => {
          setSuccessMessage("");
        }, 5000);
      } else {
        setErrorMessage(resultAction.payload?.detail || "Failed to resend OTP");
      }
    } catch (err) {
      setErrorMessage("An unexpected error occurred.");
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await dispatch(otpAction(otpData));
      if (otpAction.fulfilled.match(response)) {
        setAccessToken(response?.payload?.access_token);
        localStorage.setItem("accesstoken", response?.payload?.access_token);
        localStorage.setItem("refreshToken", response?.payload?.refresh_token);
        fetchMemberData()
      } else {
        setErrorMessage(response.payload?.detail || "Failed to verify OTP");
      }
    } catch (error) {
      setErrorMessage("An unexpected error occurred.");
    }
  };



  const fetchMemberData = async () => {
    try {
      const response = await dispatch(memberInfoAction({ accesstoken }));
      if (memberInfoAction.fulfilled.match(response)) {
        if(response.payload.communities == null && response.payload.member_id !== null){
           navigate("/account-onboarding");
        }
        // const permissions = response.payload.communities[0]?.permission.join(", ")
        // const permissions = response.payload.communities.some(item => item.permission.join(", ") === "ADMIN_PORTAL_ACCESS")

        const allPermissions = response.payload.communities
          .filter((x) => x.status) 
          .reduce((acc, community) => {
            return acc.concat(community.permission); 
          }, []);
        const permissions = allPermissions.join(", ");
        const statusCheck = response.payload.communities.some(community => community.status === true);

        await setMemberDataInfo(response.payload.communities[0].stripe_setup_url);

         if(response.payload.communities[0].stripe_setup_url){
             navigate('/stripe-pending-onboard');
         } else if((!permissions.includes("ADMIN_PORTAL_ACCESS")) || statusCheck ===  false){
            setErrorDisable("You do not have permission to access")
            setTimeout(() => {
              window.location.reload()
            },1000)
            return;
         } else if((permissions.includes("ADMIN_PORTAL_ACCESS")) && statusCheck === true){
           navigate("/dashboard");
         } 
      } else {
        setErrorMessage(response.payload?.detail || 'Failed to fetch member info');
      }
    } catch (error) {
      setErrorMessage('An unexpected error occurred.');
    }
  };
  
  return (
    <div className="loginContainer">
        <div
          style={{
            width: "50%",
            backgroundImage: `url(${backgroundImageLogin})`, 
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
            height: "100vh",
          }}
        >
        </div>
        <div className="rightContainer">
           <div style={{width:"90%", marginLeft:"5%", height:"80%"}}> 
             <RightSideContent />
             <div style={{width:"100%", display:"flex", justifyContent:"center"}}>
             <div className="loginForm">
              <div className="signIn">Sign In</div>
              <form style={{width:"97%"}} onSubmit={handleSubmit}>
                <div style={{ paddingTop: "10px",paddingLeft:'10px',paddingRight:'10px' }}>
                  <div className="mb-3" style={{padding:'10px',paddingRight:'10pxs'}}>
                    <label htmlFor="mobile_number" className="loginLabeltext">
                     Mobile Number:
                    </label>
                    <div className="countryCodeSection">
                      <select
                        name="country_code"
                        className="form-control countryCode"
                        value={loginForm.country_code}
                        onChange={handleInputChange}
                      >
                        {countryCodes.map((item) => (
                          <option key={item.code} value={item.code}>
                            {item.label}
                          </option>
                        ))}
                      </select>
                      <input
                        type="text"
                        name="mobile_number"
                        className="form-control mobile_number"
                        placeholder="Enter mobile number"
                        value={loginForm.mobile_number}
                        onChange={handleInputChange}
                        maxLength={10}
                        style={{ flex: 1, width: "310px",height: "47px"}}
                      />
                    </div>
                  </div>
                  {!otpSent && (
                    <div className="firstSendOTPButton" style={{paddingBottom:'10%'}}>
                    <button type="submit" className="buttonSubmit">
                      {loading ? "Sending OTP..." : "Send OTP"}
                    </button>
                    </div>
                  )}
                </div>
              </form>

              {otpSent && (
                <form onSubmit={handleOtpSubmit} style={{  width:"89%" }}>
                  <div className="mb-2">
                    
                  {resendTimer && (
                    <div type="button" className="resendOtpButton"  onClick={handleResendOtp}>
                    <img src={Refreshimage} alt="resend"  style={{cursor:'pointer'}}   onClick={handleResendOtp}/> Resend
                    </div>
                  )}
                  {remainingTime > 0 && otpSent && (
                    <div className="resendtext" >
                    Resend in <span className="resendtimer">{remainingTime}{" "} Sec</span>                    
                    </div>
                  )}
                    <label htmlFor="otp_code" className="loginLabeltext">
                    Verification
                    </label>
                    <input
                      type="text"
                      name="otp_code"
                      className="form-control mobile_number"
                      placeholder="Enter the OTP"
                      value={otpData.otp_code}
                      maxLength={6}
                      onChange={handleOtpChange}
                    />
                  </div>

                  {!selectedCommunity && (
                    <button type="submit" className="buttonSubmit" style={{width:"99.8%"}}>
                      Verify OTP
                    </button>
                  )}
                </form>
              )}
              <div className="errorMessage">{errorMessage || (permissionError && permissionError) || errorDisable}</div>
              <div className="successMessage">{successMessage}</div>

              <div className="home-text" style={{width:"89%", marginLeft:"5%",position:'relative',top:'3%'}}>
                  By proceeding to use this portal for login, you agree to receive text messages from Requite Platform and TapNTip application. 
                  The solution uses your mobile number as your unique Login ID and to verify that you have legitimate access to this solution. 
                  Message & data rates may apply. Message frequency varies.
              </div>
              
             </div>
             </div>
            </div>
        </div>  
      <footer className="footer"> (C) 2024 Requite, Inc | <a href="https://www.requite.tech/" target="_blank" style={{color:"white", marginLeft:'5px', marginRight:'5px'}}>www.requite.tech</a> | <a href="https://www.requite.tech/terms" target="_blank" style={{color:'white', marginLeft:'5px', marginRight:'5px'}}>Terms of Use</a> | <a href="https://www.requite.tech/privacy-policy" target="_blank" style={{color:'white', marginLeft:'5px'}}>Privacy Policy</a></footer>         
    </div>
  );
};

export default Login;
