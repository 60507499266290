import { createSlice} from '@reduxjs/toolkit';
import { updatePayoutConfigSettingCommunityMember } from '../../api/api';

// Initial state
const initialState = {
    loading: false,
    error: null,
    UpdateConfigData: null, 
  };

  
const UpdatedSettingPayoutConfigSlice = createSlice({
    name: 'communityMember',
    initialState,
    reducers: {}, 
    extraReducers: (builder) => {
      builder
        .addCase(updatePayoutConfigSettingCommunityMember.pending, (state) => {
          state.loading = true;
          state.error = null;
          state.UpdateConfigData = null;
        })
        .addCase(updatePayoutConfigSettingCommunityMember.fulfilled, (state, action) => {
          state.loading = false;
          state.UpdateConfigData = action.payload; 
          state.error = null;
        })
        .addCase(updatePayoutConfigSettingCommunityMember.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload; 
        });
    },
  });
  
  export default UpdatedSettingPayoutConfigSlice.reducer;