// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/drop-icon-1.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }
  
  .loader {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } */
  .selectedCom .form-select
   {
    --bs-form-select-bg-img: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) !important;
    background-color: var(--drop-down-bg-color) !important;
    color: var(--primary-color) !important;
   }

   .selectedCom .form-select:focus{
    border-color: transparent !important;
    outline: 0;
    box-shadow: none !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/SelectCommunity/selectcommunity.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;KA6BK;EACH;;IAEE,2EAA4E;IAC5E,sDAAsD;IACtD,sCAAsC;GACvC;;GAEA;IACC,oCAAoC;IACpC,UAAU;IACV,2BAA2B;AAC/B","sourcesContent":["/* .loader-container {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100vw;\n    height: 100vh;\n    background-color: rgba(0, 0, 0, 0.5);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    z-index: 9999;\n  }\n  \n  .loader {\n    border: 8px solid #f3f3f3;\n    border-top: 8px solid #3498db;\n    border-radius: 50%;\n    width: 60px;\n    height: 60px;\n    animation: spin 1s linear infinite;\n  }\n  \n  @keyframes spin {\n    0% {\n      transform: rotate(0deg);\n    }\n    100% {\n      transform: rotate(360deg);\n    }\n  } */\n  .selectedCom .form-select\n   {\n    --bs-form-select-bg-img: url(../../assets/images/drop-icon-1.png) !important;\n    background-color: var(--drop-down-bg-color) !important;\n    color: var(--primary-color) !important;\n   }\n\n   .selectedCom .form-select:focus{\n    border-color: transparent !important;\n    outline: 0;\n    box-shadow: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
