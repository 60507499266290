import React, { useState, useEffect, useRef } from "react";
import "./tiphistory.css";
import Header from "../../components/Header/Header";
import SideBar from "../../components/SideBar/SideBar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SelectCommunity from "../../components/SelectCommunity/SelectCommunity";
import { useDispatch, useSelector } from "react-redux";
import {
  getCommunityList,
  getPaymentListAction,
  getTipSummaryHistoryAction,
  UpdateUserDetailAction,
  getCommunityPaymentDocForMember,
  sendSMSOnMobileNOToCommunityMember,
  getBankDetailParticularUser,
  getCommunityMember,
  getTipReceipt,
  getPayoutStaffIndividualListAction,
} from "../../redux/api/api";
import sendSMSIcon from "../../assets/images/sendSMS.png";
import upicon from "../../assets/images/Up.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../App.css";
import Footer from "../../components/Footer/Footer";
import userinvite from "../../assets/images/userinvite.png";
import PopupDialog from "../../components/Popup/PopupDialog";
import AddUserForm from "../../components/UserForm/AddUserForm";
import downloadcircle from "../../assets/images/downloadcircle.png";
import AddressDetail from "../../components/AddressDetail/AddressDetail";
import tapNTipLogo from "../../assets/images/tapNtipBlackLogo.png";
import printerIcon from "../../assets/images/printer_icon.png";
import { useReactToPrint } from "react-to-print";
import headerRequiteLogo from "../../assets/images/requite.png";
import {  convertToUTCDate,  convertToUTCTime,  convertToUTCUSA} from "../../Utility/Utility";
import arrowleft from "../../assets/images/arrow-left.png";
import DatePicker from "react-datepicker";

const TipHistory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const initialStaff = state?.staff || {};
  const [isEditing, setIsEditing] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("tipHistory");
  const [expandedRow, setExpandedRow] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const paymentList = useSelector((state) => state.GetPaymentListStore);
  const { tipListData } = useSelector((state) => state.GetTipReceiptStore);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const itemsPerPage = 10;
  const [currentTipHistoryList, setCurrentTipHistoryList] = useState([]);
  const currentMonthFirstDate = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    1
  );
  const currentMonthLastDate = new Date(
    new Date().getFullYear(),
    new Date().getMonth() + 1,
    0
  );
  const [fromDate, setFromDate] = useState(currentMonthFirstDate);
  const [toDate, setToDate] = useState(currentMonthLastDate);
  const [errors, setErrors] = useState({});
  const [updateUserData, setUpdateUserData] = useState({
    mobile_number: initialStaff?.mobile_number || "",
    functional_role: initialStaff?.functional_role || "",
  });
  const accessToken = localStorage.getItem("accesstoken");
  const community_id = localStorage.getItem("selectedCommunity");
  const member_id = state?.staff?.member_id;
  const paymentSummaryHistory = useSelector((state) => state.tipSummaryHistoryStore);
  const { bankDetails } = useSelector((state) => state.GetBankDetailParticularUserStore);
  const { memberData } = useSelector((state) => state.GetCommunityMemberStore);
  const { individualStaffData } = useSelector((state) => state.IndividualStaffPayoutStore);
  const [tipDataReceipt, setTipDataReceipt] = useState();
  const [formDataInfo, setFormDataInfo] = useState();
  const [memberDataState, setMemberDataState] = useState();
  const managed_by = localStorage.getItem("manage_by");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isModalOpenHistory, setModalOpenHistory] = useState(false);
  const componentRef = useRef();
  const [currentPagePayout, setCurrentPagePayout] = useState(1); 
  const [limit, setLimit] = useState(10); 
  const [totalPagesPayout, setTotalPagesPayout] = useState(1); 
  const [paginatedData, setPaginatedData] = useState([]);
  const itemPerPagePayout = 10; 

  const [individualPayoutData, setIndividualPayoutData] = useState([]);
  const today = new Date();
  const fourteenDaysAgo = new Date();
  fourteenDaysAgo.setDate(fourteenDaysAgo.getDate() - 14);
  const [startDateData, setStartDateData] = useState(new Date(fourteenDaysAgo));
  const [endDateData, setEndDateData] = useState(new Date(today));
  const formattedStartDate = startDateData?.toISOString().split("T")[0];
  const formattedEndDate = endDateData?.toISOString().split("T")[0];

  
  // useEffect(() => {
  //   if (activeTab === 'tipHistory') {
  //     setStartDateData(startDateData); 
  //     setEndDateData(endDateData); 
  //   } else if (activeTab === 'payoutHistory') {
  //     setStartDateData(startDateData); 
  //     setEndDateData(endDateData); 
  //   }
  // }, [activeTab]);

  useEffect(() => {
    if (memberData) {
      setMemberDataState(memberData);
    }
  }, [memberData]);

  useEffect(() => {
    if (memberDataState?.member_id === initialStaff?.member_id) {
      const matchData = {
        member_id: memberDataState?.member_id,
        name: memberDataState?.name,
        mobile_number: memberDataState?.mobile_number,
        email: memberDataState?.email,
        profile_url: memberDataState?.profile_url,
        permission: memberDataState?.communities[0]?.permission,
        display_permissions:
          memberDataState?.communities[0]?.display_permission,
        shift: memberDataState?.communities[0]?.shift,
        status: memberDataState?.communities[0]?.status,
        functional_role: memberDataState?.communities[0]?.functional_role,
        qr_code_url: memberDataState?.communities[0]?.qr_code_url,
      };

      setFormDataInfo(matchData);
    }
  }, [memberDataState]);


  const handleCheckboxChange = (permission) => {
    const updatedPermissions = formDataInfo?.permission?.includes(permission)
      ? formDataInfo?.permission?.filter((perm) => perm !== permission)
      : [...formDataInfo?.permission, permission];

    setFormDataInfo((prevData) => ({
      ...prevData,
      permission: updatedPermissions,
    }));
  };

  useEffect(() => {
    if(accessToken && community_id && member_id){
      dispatch(getBankDetailParticularUser({ accessToken, community_id, member_id }));
    }
   
  }, [dispatch, accessToken, community_id, member_id]);

  const convertLocalToUTC = (localDate) => {
    const date = new Date(localDate);
    return date.toISOString().split("T")[0];
  };

  const currentDate = new Date();
  const startDate = convertLocalToUTC(currentDate);
  const endDate = new Date();
  const endDateUTCConvert = convertLocalToUTC(endDate);
  const newStartDate = (endDateUTCConvert - startDate) / (1000 * 60 * 60 * 24);

  const validateForm = () => {
    const newErrors = {};
    if (!formDataInfo.functional_role) {
      newErrors.functional_role = "Functional role is required";
    }
    if (formDataInfo?.permission.length === 0) {
      newErrors.permissions = "At least one permission is required";
    }
    if (Object.keys(newErrors).length > 0) {
      const errorMessages = Object.values(newErrors).join(", ");
      toast.error(errorMessages);
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    const payload = {
      name:formDataInfo.name,
      email:formDataInfo.email,
      mobile_number:formDataInfo.mobile_number,
      functional_role:formDataInfo.functional_role,
      permission: formDataInfo.permission,
      status: formDataInfo.status,
      shift: formDataInfo.shift,
    };
    try {
      const response = await dispatch(
        UpdateUserDetailAction({ accessToken, community_id, payload })
      );
      if (UpdateUserDetailAction.fulfilled.match(response)) {
        if (response?.meta?.requestStatus === "fulfilled") {
          setFormDataInfo({
            name:formDataInfo?.name,
            email:formDataInfo?.email,
            mobile_number:formDataInfo?.mobile_number,
            functional_role:formDataInfo?.functional_role,
            permission: formDataInfo?.permission,
            status: formDataInfo?.status,
            shift: formDataInfo?.shift,
          });
          setIsEditing(false);
          setErrorMessage("");
        }
        toast.success("Form submitted successfully!");
        dispatch(getCommunityList({
            accessToken,
            community_id,
            page_number: 1,
            limit: 10,
          })
        );
      } else {
        toast.error(response.payload?.detail || "Failed to submit form.");
        setErrorMessage(response.payload?.detail || "Failed to submit form.");
      }
    } catch (error) {
      toast.error("Error updating user: " + error);
      setErrorMessage("An unexpected error occurred.");
    }
  };

  useEffect(() => {
    if(accessToken && community_id){
      dispatch(getCommunityList({ accessToken, community_id, page_number: 1, limit: 10 }));
    }
  }, [dispatch, accessToken, community_id]);

  const toggleRow = (id) => {
    setExpandedRow((prevExpandedRow) => (prevExpandedRow === id ? null : id));
  };

  const systemTimeSpanOffset = new Date().getTimezoneOffset();


  useEffect(() => {
    if(accessToken && community_id && member_id){
      dispatch(
        getCommunityPaymentDocForMember({
          accessToken,
          community_id,
          startDateUTCConvert: getStartInUTC(),
          endDateUTCConvert: getEndInUTC(),
          timezoonoffset: systemTimeSpanOffset,
          member_id: member_id,
        })
      );
    }
  }, [dispatch, accessToken, community_id, member_id, systemTimeSpanOffset]);

  useEffect(() => {
    if(accessToken && community_id && member_id){
    dispatch(
      getTipSummaryHistoryAction({
        accessToken,
        community_id,
        member_id,
        timezoonoffset: systemTimeSpanOffset,
      })
    );
  }
  }, [dispatch, accessToken, community_id, member_id, updateUserData]);

  const convertUTCToLocal = (utcDate) => {
    const date = new Date(`${utcDate}T00:00:00Z`);
    return date.toLocaleDateString("en-CA");
  };

  const getStartInUTC = () => {
    const startOfMonth = new Date(fromDate);
    return startOfMonth.toISOString().split("T")[0];
  };
  const getEndInUTC = () => {
    const endOfMonth = new Date(toDate);
    return endOfMonth.toISOString().split("T")[0];
  };

  const sendSMSToCommunityMember = async (mobileNo) => {
    try {
      if (mobileNo.length >= 10) {
        const response = await dispatch(
          sendSMSOnMobileNOToCommunityMember({
            accessToken,
            mobileNo,
          })
        );
        if (response.error) {
          setErrorMessage("Failed to fetch payment documents.", response?.detail);
          return;
        }
        toast("Download link sent successfully")
         
      }
    } catch (error) {
      setErrorMessage("An unexpected error occurred.");
    }
  };

  const filterDataByDate = () => {
    if (!startDateData || !endDateData) {
      return paymentList.paymentList || [];
    }
    const startDate = new Date(startDateData);
    const endDate = new Date(endDateData);

    return (paymentList.paymentList || []).filter((item) => {
      const itemDate = new Date(item.date);
      return itemDate >= startDate && itemDate <= endDate;
    });
  };

  // Pagination logic
  const totalPages = Math.ceil(filterDataByDate().length / itemsPerPage);

  const paginateData = () => {
    const filteredData = filterDataByDate();
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    return filteredData.slice(indexOfFirstItem, indexOfLastItem);
  };


  useEffect(() => {
    if (!startDateData || !endDateData) return;

    setIsLoading(true);
    setTimeout(() => {
      setCurrentPage(1);
      setCurrentTipHistoryList(paginateData());
      setIsLoading(false);
    }, 1000);
  }, [startDateData, endDateData, paymentList]);



  useEffect(() => {
    const filteredData = filterDataByDate();
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    setCurrentTipHistoryList(currentData);
  }, [currentPage, paymentList]);

  useEffect(() => {
    const applyDateFilter = () => {
      if (!fromDate || !toDate) {
        return;
      }
      const filteredList = paymentList?.paymentList.filter((staff) => {
        const staffDate = new Date(staff.date);
        return staffDate >= fromDate && staffDate <= toDate;
      });
      setCurrentTipHistoryList(
        filteredList.slice(
          (currentPage - 1) * itemsPerPage,
          currentPage * itemsPerPage
        )
      );
    };
    applyDateFilter();
  }, [fromDate, toDate, paymentList, currentPage, itemsPerPage]);

  const handleCancelEdit = () => {
    setIsEditing(false);
    // setFormDataInfo({
    //   functional_role: formDataInfo?.functional_role || "",
    //   permissions: formDataInfo?.permission || [],
    //   status: formDataInfo?.status,
    // });
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const tipsCollected =
    (paymentSummaryHistory?.paymentSummaryHistory?.tip_collected || 0) / 100;
  const tipPooled =
    (paymentSummaryHistory?.paymentSummaryHistory?.tip_pooled || 0) / 100;
  const walletBalance =
    (paymentSummaryHistory?.paymentSummaryHistory?.wallet_balance || 0) / 100;
  const payoutReceived =
    (paymentSummaryHistory?.paymentSummaryHistory?.payout_received || 0) / 100;

  const handleHomeClick = () => {
    navigate("/dashboard");
  };

  const backToStaff = () => {
    navigate("/manage-staff");
  };

  const handleOpenDialog = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handleCloseDialog = () => {
    setIsPopupOpen(false);
  };

  useEffect(() => {
    if(accessToken && community_id && member_id){
      dispatch(getCommunityMember({ accessToken, community_id, member_id }));
    }
  }, [dispatch, accessToken, community_id, member_id]);

  const qrCodeUrl = formDataInfo?.qr_code_url;

  useEffect(() => {
    setCurrentTipHistoryList(paginateData());
  }, [currentPage, paymentList]);

  // Handle pagination
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

useEffect(() => {
  const systemTimeSpanOffset = new Date().getTimezoneOffset();
  if(activeTab === "tipHistory"){
  if(accessToken && community_id && member_id){
  dispatch(getPaymentListAction({
      accessToken,
      community_id,
      member_id,
      start_date: formattedStartDate,
      end_date: formattedEndDate,
      timezoonoffset: systemTimeSpanOffset,
    }));
  }
}
}, [dispatch, accessToken, community_id, member_id, startDateData, endDateData, activeTab]);


  const convertToCSV = (data) => {
    const headers = [
      "Date",
      "Tip Collection Medium",
      "Tip Collected",
      "Total Guests Tipped",
      "Details",
    ];
    const rows = data.map((item) => [
      item.date || "NA",
      item.payment_methods.join(", ") || "NA",
      item.total_tip_amount / 100 || "NA",
      item.total_tips || "NA",
      item.details.map((detail) => `${detail.time || "NA"} - ${detail.payment_read_method || "NA"}`).join(", ") || "NA",
    ]);
    const csvContent = [headers, ...rows].map((e) => e.join(",")).join("\n");
    return csvContent;
  };

  const downloadCSV = () => {
    const filteredData = filterDataByDate();
    const csvContent = convertToCSV(filteredData);
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "filtered_tip_history.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleTipHistoryDownload = () => {
    if (!startDateData || !endDateData) {
      alert("Please select a date range before downloading.");
      return;
    }
    downloadCSV();
  };

  const handleRowClick = (detail) => {
    const systemTimeSpanOffset = new Date().getTimezoneOffset();

    dispatch(
      getTipReceipt({
        accessToken,
        community_id,
        tip_id: detail.tip_id,
        timezoonoffset: systemTimeSpanOffset,
      })
    );
    setModalOpenHistory(true);
  };

  useEffect(() => {
    if (tipListData) {
      setTipDataReceipt(tipListData);
    }
  }, [tipListData]);

  const handleSavePdf = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Qr_Code_${community_id || "Unknown"}.pdf`,
  });


  useEffect(() => {
    if(activeTab === "payoutHistory"){
     dispatch(getPayoutStaffIndividualListAction({
        accessToken,
        community_id,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        timezoonoffset: systemTimeSpanOffset,
      }));
    }
  }, [dispatch, accessToken, community_id,startDateData ,endDateData, activeTab]);
  

  useEffect(() => {
    if (individualStaffData) {
      const totalPagesPayoutOne = Math.ceil(individualStaffData.length / itemPerPagePayout);
      setTotalPagesPayout(totalPagesPayoutOne);
  
      const startIndex = (currentPagePayout - 1) * itemPerPagePayout;
      const endIndex = startIndex + itemPerPagePayout;
      setPaginatedData(individualStaffData.slice(startIndex, endIndex));
    }
  }, [individualStaffData, currentPagePayout]);


const handlePreviousPagePayout = () => {
  if (currentPagePayout > 1) {
    setCurrentPagePayout((prevPage) => prevPage - 1);
  }
};

const handleNextPagePayout = () => {
  if (currentPagePayout < totalPagesPayout) {
    setCurrentPagePayout((prevPage) => prevPage + 1);
  }
};

  

  useEffect(() => {
    if (activeTab === "payoutHistory") {
      const today = new Date().toISOString().split("T")[0];
      setStartDateData(startDateData);
      setEndDateData(endDateData);
    }
  }, [activeTab]);

  useEffect(() => {
    if (individualStaffData) {
      const filteredData = individualStaffData.filter((item) => {
        const paymentDate = new Date(item?.created_ts);
        const startDate = new Date(startDateData);
        const endDate = new Date(endDateData);

        return (
          paymentDate >= startDate &&
          paymentDate <= new Date(endDate.getTime() + 24 * 60 * 60 * 1000) 
        );
      });

      setIndividualPayoutData(filteredData || []);
      setTotalPagesPayout(
        filteredData?.length ? Math.ceil(filteredData.length / limit) : 1
      );
    }
  }, [individualStaffData, startDateData, endDateData]);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };



  console.log("payout data", individualStaffData)
  return (
    <>{initialStaff ? 
    <div className="TipHistoryMainPage mainLayoutPage">
      <ToastContainer />
      <SideBar />
      <div className="dashboard-container borderFormat">
        <div className="header">
          <div>
            <div className="titleheading-content">
              Staff
              <div className="subtitle">
                <div className="home-text" onClick={handleHomeClick}>
                  <img src={upicon} alt="upIcon" className="home-text" /> Home /
                </div>
                <div className="home-text" onClick={backToStaff}>
                  Staff /
                </div>
                <div className="current-page-text"> {state?.staff?.name}</div>
              </div>
            </div>
            <div className="tabletScreenHeader">
              <Header />
            </div>
          </div>
          <div className="dropdownMain mainDropdownTablet">
            <div className="mediumScreenHeader">
              <div className="header-button">
                {" "}
                <SelectCommunity />
              </div>
              <button className="onboard-button" onClick={handleOpenDialog}>
                <span>Onboard New Staff</span>
                <img src={userinvite} alt="userIcon" />
              </button>
            </div>
          </div>
        </div>

        <div className="top-line"></div>
        <div className="container12">
          <div className="columnleft">
            <div className="subColumnLeft">
              <div className="userDetailHeading">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div className="subheader">Staff Info</div>
                  <div style={{ display: "flex" }}>
                    <img
                      src={sendSMSIcon}
                      alt="sendSMSIcon"
                      className="sendSMS"
                      onClick={() =>
                        sendSMSToCommunityMember(formDataInfo?.mobile_number)
                      }
                    />
                    <div>
                      <div>
                        {isEditing ? (
                          <div className="saveCancel">
                            <button
                              onClick={handleSubmit}
                              className="editBtnUser saveBtnAlign"
                              style={{
                                position: "relative",
                                right: "20px",
                                marginLeft: "15px",
                              }}
                            >
                              Save
                            </button>
                            <button  onClick={handleCancelEdit} className="btn btn-secondary">
                              Cancel
                            </button>
                          </div>
                        ) : (
                          <button onClick={handleEditClick} className="editBtnUser">
                            Edit
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div  className="row" style={{ height: "100px",paddingTop:'10px', overflow: "hidden" }}>
                <div className="pictureSectionStaffFirst">
                  <div className="staffProfilePic">
                    <img
                      src={
                        formDataInfo?.profile_url ||
                        "https://bootdey.com/img/Content/avatar/avatar6.png"
                      }
                      alt="profileImage"
                      className="StaffDetailProfile"
                    />
                  </div>
                  <div className="mainStaffInfoDetail">
                    <div
                      className="staffInfoDetail"
                      style={{ display: "flex", paddingTop: "0px" }}
                    >
                      <div className="staffDataLabel textInfoDecoration">
                        Name:
                      </div>
                      <div className="StaffDetailValue textInfoDecorationValue">
                        {formDataInfo?.name}
                      </div>
                    </div>
                    <div className="staffInfoDetail textInfoDecoration">
                      <div className="staffDataLabel">Mobile Number:</div>
                      <div className="StaffDetailValue textInfoDecorationValue">
                        {formDataInfo?.mobile_number || "NA"}
                      </div>
                    </div>
                    <div className="staffInfoDetail textInfoDecoration">
                      <div className="staffDataLabel">Email:</div>
                      <div className="StaffDetailValue textInfoDecorationValue">
                        {formDataInfo?.email || "NA"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="pictureSectionStaffSecond">
                  <div className="staffQRCode">
                    {qrCodeUrl ? (
                      <div onClick={() => setModalOpen(true)} style={{ cursor: "pointer" }}>
                        <img  src={qrCodeUrl}  alt="QR Code" className="qr-code-image" style={{ maxWidth: "100px", height: "auto" }} />
                      </div>
                    ) : (
                      "NA"
                    )}

                    {isModalOpen && (
                      <div
                        className="popup-overlay1"
                        onClick={() => setModalOpen(false)}
                      >
                        <div
                          className="popup-modal1"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <div className="modal-header"></div>
                          <img
                            src={qrCodeUrl}
                            alt="QR Code"
                            style={{ maxWidth: "300px", height: "auto" }}
                          />
                          <button
                            className="close-button1"
                            onClick={() => setModalOpen(false)}
                          >
                            &times;
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="mainQRCodeSub">
                    <form onSubmit={handleSubmit}>
                      <div className="staffDetailQRCode">
                        <div
                          className="staffInfoDetail"
                          style={{ paddingTop: "0px" }}
                        >
                          <div className="staffDataLabelPermission textInfoDecoration" style={{display:'flex',justifyContent:'left',alignItems:'center'}}>
                            Community Role:
                          </div>
                          <div className="StaffDetailValuePermission">
                            <input
                              type="text"
                              className="form-control"
                              name="functional_role"
                              value={formDataInfo?.functional_role}
                              onChange={(e) =>
                                setFormDataInfo({
                                  ...formDataInfo,
                                  functional_role: e.target.value,
                                })
                              }
                              placeholder="Enter your functional role"
                              style={{
                                fontSize: "14px",
                                color: "gray",
                                height: "40px",
                              }}
                              disabled={!isEditing}
                            />
                            {errors.functional_role && (
                              <p style={{ color: "red" }}>
                                {errors.functional_role}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="staffInfoDetail active-and-inactive-staff">
                          <div  className="staffDataLabelPermission textInfoDecoration" style={{ width: "50%" }} >
                            Status:
                          </div>
                          <div className="StaffDetailValuePermission active-and-inactive-staff-checkbox">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="status"
                                id="flexRadioDefault1"
                                checked={formDataInfo?.status === true}
                                disabled={!isEditing}
                                onChange={() =>
                                  setFormDataInfo({
                                    ...formDataInfo,
                                    status: true,
                                  })
                                } />
                              <span className="textInfoDecoration">
                                In Use (Active)
                              </span>
                            </div>
                          </div>
                          <div className="StaffDetailValuePermission active-and-inactive-staff-checkbox">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="status"
                                id="flexRadioDefault2"
                                checked={formDataInfo?.status === false}
                                disabled={!isEditing}
                                onChange={() =>
                                  setFormDataInfo({
                                    ...formDataInfo,
                                    status: false,
                                  })
                                }
                              />
                              <span className="textInfoDecoration">
                                Disable (Not in Use)
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="staffInfoDetail active-and-inactive-staff">
                          <div className="staffDataLabelPermission textInfoDecoration active-and-inactive-staff-top" style={{ width: "50%" }} >
                            Permission:
                          </div>
                          <div className="StaffDetailValuePermission active-and-inactive-staff-checkbox">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="ADMIN_PORTAL_ACCESS"
                                id="portalaccess"
                                disabled={!isEditing}
                                checked={formDataInfo?.permission?.includes(
                                  "ADMIN_PORTAL_ACCESS"
                                )}
                                onChange={() =>
                                  handleCheckboxChange("ADMIN_PORTAL_ACCESS")
                                }
                              />
                              <span className="textInfoDecoration">
                                Portal Access
                              </span>
                            </div>
                          </div>
                          <div className="StaffDetailValuePermission active-and-inactive-staff-checkbox">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="COLLECT_TIP_ACCESS"
                                id="mobileaccess"
                                disabled={!isEditing}
                                checked={formDataInfo?.permission?.includes(
                                  "COLLECT_TIP_ACCESS"
                                )}
                                onChange={() =>
                                  handleCheckboxChange("COLLECT_TIP_ACCESS")
                                }
                              />
                              <span className="textInfoDecoration">
                                Mobile Access
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div className="timSummarTile">
              <div className="subheader">Tip Summary</div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="userDetailText">
                    <span>print duration of this data</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="userDetailText">
                    <span>User's Collection</span>: ${tipsCollected || 0}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="userDetailText">
                    <span>Pooled for User</span>: ${tipPooled || 0}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="userDetailText">
                    <span>Tips Credited</span>: ${payoutReceived || 0}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="userDetailText">
                    <span>Pending Tips Credit to User</span>: $
                    {walletBalance || 0}
                  </div>
                </div>
              </div>
            </div>

            <div className="rightSideTipHistoryChart">
              {managed_by === "requiteplatform" ? (
                <div className="pt-3">
                  <div className="subheader">Bank Account Details</div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="userDetailText">
                        <span>Account Number</span>:{" "}
                        {bankDetails?.account_number || "NA"}
                      </div>
                    </div>
                    <div className="col-lg-5">
                      <div className="userDetailText">
                        <span>Bank Holder Name</span>:{" "}
                        {bankDetails?.account_name || "NA"}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6">
                      <div className="userDetailText">
                        <span>Bank Name</span>:{bankDetails?.bank_name || "NA"}
                      </div>
                    </div>
                    <div className="col-lg-5">
                      <div className="userDetailText">
                        <span>Routing Number</span>:{" "}
                        {bankDetails?.routing_number || "NA"}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="userDetailText">
                        <span>Account Type</span>:{" "}
                        {bankDetails?.account_type || "NA"}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="subheader">Bank Account Details</div>
                  <div className="BankDetailNotFound">Data Not Found</div>
                </div>
              )}
            </div>

            <div className="bankDetailTile">
              <div className="addressHistorySection">
                <AddressDetail />
              </div>
            </div>
          </div>

          <div className="columnright">
            <div className="subTipHistoryLeftSide">
              <div className="mainTabPanel">
                <div className="d-flex justify-content-lg-start" style={{ width: "51%" }}>
                  <ul className="nav nav-pills nav-fill">
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          activeTab === "tipHistory" ? "active" : ""
                        }`}
                        aria-current="page"
                        onClick={() => setActiveTab("tipHistory")}
                        style={{
                          height: "43px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor:'pointer'
                        }}
                      >
                        Tip History
                      </a>
                    </li>
                    <li className="nav-item" style={{ paddingLeft: "10px" }}>
                      <a
                        className={`nav-link ${
                          activeTab === "payoutHistory" ? "active" : ""
                        }`}
                        aria-current="page"
                        onClick={() => handleTabClick("payoutHistory")}
                        style={{
                          height: "43px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor:'pointer'
                        }}
                      >
                        Payout History
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="filterTipHistoryDesktop">
                  <div className="tipHistorySub">
                    <div className="date-filters">
                    <div className="dateSectionHistory">
                      <div style={{ marginRight: "8px" }}>
                        <div className="date-range-picker" style={{ width: "100%",paddingLeft:'7px' }}>
                          <div className="dateRangeHistory">Date:</div>
                          <div className="date-input">
                            <DatePicker
                              className="customDate"
                              selected={startDateData}
                              onChange={(date) => {
                                if (date && (!endDateData || date <= endDateData)) {
                                  setStartDateData(date);
                                }
                              }}
                              maxDate={today}
                              dateFormat="MM/dd/yyyy"
                              placeholderText="Select start date"
                            />
                          </div>
                          <div style={{ display: "flex", alignItems: "center" }}>To</div>
                          <div className="date-input">
                            <DatePicker
                                className="customDate"
                                selected={endDateData}
                                onChange={(date) => {
                                  if (date && (!startDateData || date >= startDateData)) {
                                    setEndDateData(date);
                                  }
                                }}
                                minDate={startDateData} 
                                maxDate={today}
                                dateFormat="MM/dd/yyyy"
                                placeholderText="Select end date" />
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    </div>
                    <span>
                      <img src={downloadcircle} alt="downloadcircle" onClick={handleTipHistoryDownload}
                        style={{ width: "30px", height: "30px", position: "relative", left: "17px", top: "2px",
                          cursor:
                            startDateData && endDateData
                              ? "pointer"
                              : "not-allowed",
                          opacity: startDateData && endDateData ? 1 : 0.5,
                        }} />
                    </span>
                  </div>
                </div>
              </div>
              <div className="tabContent tabletViewTabContent">
                {activeTab === "tipHistory" && (
                  <div className="tipHistoryTab">
                    <div className="table-height">
                      {isLoading ? (
                        <div>Loading...</div>
                      ) : (
                        <table className="table tablebordered">
                          <thead className="table-header">
                            <tr>
                              <th scope="col" className="tableDataTh">
                                <span style={{ marginLeft: "10px" }}>Date</span>
                              </th>
                              <th scope="col" className="tableDataTh">
                                Tip Collection Medium
                              </th>
                              <th scope="col" className="tableDataTh">
                                Tip Collected
                              </th>
                              <th scope="col" className="tableDataTh">
                                Total Guests Tipped
                              </th>
                              <th scope="col" className="tableDataTh"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentTipHistoryList.length > 0 ? (
                              currentTipHistoryList.map((tip, index) => (
                                <React.Fragment key={index + 1}>
                                  <tr>
                                    <td className="td-default">
                                      <span style={{ marginLeft: "10px" }}>
                                        {convertToUTCDate(tip?.date || "NA")}
                                      </span>
                                    </td>
                                    <td className="td-default">
                                      <div>
                                        {tip.payment_methods.includes(
                                          "QRCode" ) && (  <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            height="30px"
                                            viewBox="0 -960 960 960"
                                            width="30px"
                                            fill="#5f6368" >
                                            <path d="M520-120v-80h80v80h-80Zm-80-80v-200h80v200h-80Zm320-120v-160h80v160h-80Zm-80-160v-80h80v80h-80Zm-480 80v-80h80v80h-80Zm-80-80v-80h80v80h-80Zm360-280v-80h80v80h-80ZM173.33-653.33h133.34v-133.34H173.33v133.34ZM120-600v-240h240v240H120Zm53.33 426.67h133.34v-133.34H173.33v133.34ZM120-120v-240h240v240H120Zm533.33-533.33h133.34v-133.34H653.33v133.34ZM600-600v-240h240v240H600Zm80 480v-120h-80v-80h160v120h80v80H680ZM520-400v-80h160v80H520Zm-160 0v-80h-80v-80h240v80h-80v80h-80Zm40-200v-160h80v80h80v80H400Zm-190-90v-60h60v60h-60Zm0 480v-60h60v60h-60Zm480-480v-60h60v60h-60Z" />
                                          </svg>
                                        )}
                                        {tip.payment_methods.includes(
                                          "Card"
                                        ) && (
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            height="30px"
                                            viewBox="0 -960 960 960"
                                            width="30px"
                                            fill="#5f6368"
                                          >
                                            <path d="M880-733.33v506.66q0 27-19.83 46.84Q840.33-160 813.33-160H146.67q-27 0-46.84-19.83Q80-199.67 80-226.67v-506.66q0-27 19.83-46.84Q119.67-800 146.67-800h666.66q27 0 46.84 19.83Q880-760.33 880-733.33ZM146.67-634h666.66v-99.33H146.67V-634Zm0 139.33v268h666.66v-268H146.67Zm0 268v-506.66 506.66Z" />
                                          </svg>
                                        )}
                                      </div>
                                    </td>
                                    <td className="td-default">
                                      ${tip?.total_tip_amount / 100 || "NA"}
                                    </td>
                                    <td className="td-default">
                                      {tip?.total_tips || "NA"}
                                    </td>
                                    <td className="td-default">
                                      <span
                                        onClick={() => toggleRow(index + 1)}
                                        className="moreAndLessText"
                                      >
                                        {expandedRow === index + 1 ? "Show Less"  : "View More"}
                                      </span>
                                    </td>
                                  </tr>
                                  {expandedRow === index + 1 && (
                                    <>
                                      <tr className="table-header" style={{ maxHeight: "436px" }}>
                                        <th style={{ width: "10%" }}></th>
                                        <th className="moreAndLessHeading moreThHeading">
                                          <span className="expendMoreRow">
                                            Time
                                          </span>
                                        </th>
                                        <th className="moreAndLessHeading moreThHeading">
                                          <span className="expendMoreRow">
                                            Tip Collection Medium
                                          </span>
                                        </th>
                                        <th className="moreAndLessHeading moreThHeading">
                                          <span className="expendMoreRow">
                                            Tip Collected
                                          </span>
                                        </th>
                                        <th className="moreAndLessHeading moreThHeading">
                                          <span className="expendMoreRow">
                                            Rating
                                          </span>
                                        </th>
                                        <th className="moreAndLessHeading moreThHeading">
                                          <span className="expendMoreRow">
                                            Transaction ID
                                          </span>
                                        </th>
                                        <th className="moreAndLessHeading moreThHeading">                                      
                                          <span className="expendMoreRow">
                                            Tip Receipt
                                          </span>
                                        </th>
                                      </tr>
                                      {tip.details.map(
                                        (detail, detailIndex) => (
                                          <tr
                                            key={detailIndex}
                                            onClick={() =>
                                              handleRowClick(detail)
                                            }
                                            style={{ cursor: "pointer" }}
                                          >
                                            <td style={{ width: "10%" }}></td>
                                            <td>
                                              <span className="expendMoreRow td-default">
                                                {detail.time || "NA"}
                                              </span>
                                            </td>
                                            <td>
                                              <span className="expendMoreRow td-default">
                                                {detail.payment_read_method ||
                                                  "NA"}
                                              </span>
                                            </td>
                                            <td>
                                              <span className="expendMoreRow td-default">
                                                $
                                                {detail.tip_amount / 100 ||
                                                  "NA"}
                                              </span>
                                            </td>
                                            <td>
                                              <span className="expendMoreRow td-default">
                                                {detail.rating || 0}
                                              </span>
                                            </td>
                                            <td>
                                              <span className="expendMoreRow td-default">
                                                {detail.tip_id.split("-").pop()}
                                              </span>
                                            </td>
                                            <td>
                                              <span className="expendMoreRow td-default">
                                                <img
                                                  src={arrowleft}
                                                  alt="arrowleft"
                                                  style={{
                                                    width: "20px",
                                                    height: "20px",
                                                  }}
                                                />
                                              </span>
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </>
                                  )}
                                </React.Fragment>
                              ))
                            ) : (
                              <tr>
                                <td
                                  colSpan="5"
                                  style={{
                                    textAlign: "center",
                                    padding: "10px",
                                  }}
                                >
                                  Data Not Found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      )}
                    </div>
                    {isModalOpenHistory && tipListData && (
                      <div
                        className="popup-overlay-history"
                        onClick={() => setModalOpenHistory(false)}
                      >
                        <div
                          className="popup-modal-history"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <div className="modal-header-history"></div>
                          <div className="popupHistoryContainer" ref={componentRef} >
                            <div className="popupHeader">
                              <div>
                                {tipListData?.community?.payfor ===
                                  "Business" ||
                                tipListData?.community?.payfor ===
                                  "Businessuser" ? (
                                  <img
                                    src={
                                      tipListData?.community?.community_logo_url || tapNTipLogo
                                    }
                                    alt="Logo"
                                    className="logoTipHistoryCommunity"
                                  />
                                ) : (
                                  <img
                                    src={tapNTipLogo}
                                    alt="logo"
                                    style={{
                                      width: "151.08px",
                                      height: "42.59px",
                                      marginLeft: "0px",
                                    }}
                                  />
                                )}
                              </div>
                              <div className="pt-2">
                                {tipListData?.community?.payfor ===
                                  "Business" ||
                                tipListData?.community?.payfor ===
                                  "Businessuser" ? (
                                  <div>
                                    <span style={{ fontWeight: "normal" }}>
                                      Powered by{" "}
                                    </span>
                                    <img
                                      src={headerRequiteLogo}
                                      alt="RequiteLogo"
                                      style={{
                                        width: "80.08px",
                                        height: "20px",
                                        opacity: 0.9,
                                        marginLeft: "0px",
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <div
                                    className="profile-name"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span style={{fontWeight: "normal", marginRight: "5px" }}>
                                      For
                                    </span>
                                    {tipListData?.community?.name}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div>
                              <hr />
                            </div>
                            <div className="tipReceiptBox">
                              {tipListData?.community?.payfor ===
                                "Solopreneur" && (
                                <div>
                                  <div className="tipHistoryCommunity">
                                    {tipListData?.community?.name}
                                  </div>
                                  <div className="payFor">
                                    {tipListData?.community?.payfor}
                                  </div>
                                  <div className="historyParaTip">
                                    {" "}
                                    {tipListData?.community?.name} is a
                                    preferred service provider on our system.{" "}
                                  </div>
                                </div>
                              )}

                              {tipListData?.community?.payfor ===
                                "Businessuser" && (
                                <div>
                                  <div className="tipHistoryCommunity">
                                    {tipListData?.community?.name}
                                  </div>
                                  <div className="payFor">
                                    {tipListData?.community?.community_name}
                                  </div>
                                  <div className="historyParaTip">
                                    {" "}
                                    {tipListData?.community?.name} is a
                                    preferred service provider on our system.{" "}
                                  </div>
                                </div>
                              )}
                              {tipListData?.community?.payfor ===
                                "Business" && (
                                <div>
                                  <div className="tipHistoryCommunity">
                                    {tipListData?.community?.community_name}
                                  </div>
                                  <div className="payFor">
                                    {tipListData?.community?.payfor}
                                  </div>
                                  <div className="historyParaTip">
                                    {" "}
                                    Address:{
                                      tipListData?.community?.address
                                    }{" "}
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="mt-2 mb-3">
                              <h4>Tip Receipt</h4>
                            </div>
                            <div className="tipDetailHistory">
                              <div className="historyTipReceipt">
                                <div>Payment Method:</div>
                                <div>
                                  {tipListData?.payment_read_method.includes(
                                    "QRCode"
                                  ) && (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      height="30px"
                                      viewBox="0 -960 960 960"
                                      width="30px"
                                      fill="#5f6368"
                                    >
                                      <path d="M520-120v-80h80v80h-80Zm-80-80v-200h80v200h-80Zm320-120v-160h80v160h-80Zm-80-160v-80h80v80h-80Zm-480 80v-80h80v80h-80Zm-80-80v-80h80v80h-80Zm360-280v-80h80v80h-80ZM173.33-653.33h133.34v-133.34H173.33v133.34ZM120-600v-240h240v240H120Zm53.33 426.67h133.34v-133.34H173.33v133.34ZM120-120v-240h240v240H120Zm533.33-533.33h133.34v-133.34H653.33v133.34ZM600-600v-240h240v240H600Zm80 480v-120h-80v-80h160v120h80v80H680ZM520-400v-80h160v80H520Zm-160 0v-80h-80v-80h240v80h-80v80h-80Zm40-200v-160h80v80h80v80H400Zm-190-90v-60h60v60h-60Zm0 480v-60h60v60h-60Zm480-480v-60h60v60h-60Z" />
                                    </svg>
                                  )}
                                  {tipListData?.payment_read_method.includes(
                                    "Card"
                                  ) && (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      height="30px"
                                      viewBox="0 -960 960 960"
                                      width="30px"
                                      fill="#5f6368"
                                    >
                                      <path d="M880-733.33v506.66q0 27-19.83 46.84Q840.33-160 813.33-160H146.67q-27 0-46.84-19.83Q80-199.67 80-226.67v-506.66q0-27 19.83-46.84Q119.67-800 146.67-800h666.66q27 0 46.84 19.83Q880-760.33 880-733.33ZM146.67-634h666.66v-99.33H146.67V-634Zm0 139.33v268h666.66v-268H146.67Zm0 268v-506.66 506.66Z" />
                                    </svg>
                                  )}
                                </div>
                              </div>
                              <div className="historyTipReceipt pt-1">
                                <div>Transaction ID:</div>
                                <div>
                                  <b>
                                    {tipListData?.tip_id.replace(
                                      /[^\w\s]/gi,
                                      ""
                                    )}
                                  </b>
                                </div>
                              </div>
                              <div className="historyTipReceipt pt-1">
                                <div>Transaction Date:</div>
                                <div>
                                  <b>
                                    {convertToUTCDate(
                                      tipListData?.transaction_ts
                                    )}
                                  </b>
                                </div>
                              </div>
                              <div className="historyTipReceipt pt-1">
                                <div>Transaction time:</div>
                                <div>
                                  <b>
                                    {convertToUTCTime(
                                      tipListData?.transaction_ts
                                    )}
                                  </b>
                                </div>
                              </div>
                              <div className="historyTipReceipt pt-1">
                                <div>
                                  <b>Transaction status:</b>
                                </div>
                                <div style={{ color: "#28a745 " }}>
                                  <b>{tipListData?.transaction_status}</b>
                                </div>
                              </div>
                            </div>
                            <div>
                              <hr />
                            </div>
                            <div>
                              <h6 className="mt-0 mb-3">Tip Details</h6>
                            </div>
                            <div>
                              <div className="historyTipReceipt">
                                <div>Tip Paid:</div>
                                <div>
                                  <b>${tipListData?.amount / 100}</b>
                                </div>
                              </div>
                            </div>
                            <div>
                              <hr />
                            </div>
                            <div className="receiptThanks">
                              {tipListData?.community?.payfor ===
                                "Solopreneur" && (
                                <div>
                                  <span className="thanksForSuport">
                                    Thank you for your support !
                                  </span>
                                  <h6 className="mt-0 mb-3 thanksForSupport">
                                    Solopreneur
                                  </h6>
                                </div>
                              )}
                              {tipListData?.community?.payfor ===
                                "Businessuser" && (
                                <div>
                                  <span className="thanksForSupport">
                                    Thank you for supporting your community !
                                  </span>
                                  <h6 className="mt-0 mb-3 thanksForSupport">
                                    {tipListData?.community?.community_name}
                                  </h6>
                                </div>
                              )}
                              {tipListData?.community?.payfor ===
                                "Business" && (
                                <div>
                                  <span className="thanksForSupport">
                                    Thank you for supporting your community !
                                  </span>
                                  <h6 className="mt-0 mb-3 thanksForSupport">
                                    {tipListData?.community?.community_name}
                                  </h6>
                                </div>
                              )}
                            </div>
                            <div>
                              <hr />
                            </div>
                            <div onClick={handleSavePdf} className="printerTipHistory">
                              <img src={printerIcon} alt="printerIcon" width={30} height={30} />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="pagination">
                      <button
                        onClick={handlePreviousPage}
                        disabled={currentPage === 1}
                        className="nextPageButton"
                        style={{
                          backgroundColor: "#00c2d1",
                          borderRadius: "8px",
                        }}
                      >
                        Previous
                      </button>
                      <span style={{ position: "relative", top: "7px" }}>
                        Page {currentPage} / {totalPages}
                      </span>
                      <button
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages || totalPages <= 1}
                        className="nextPageButton"
                      >
                        Next
                      </button>
                    </div>
                  </div>
                )}

                {activeTab === "payoutHistory" && (
                  <div className="payoutHistoryTab">
                    <div className="table-height">
                      {isLoading ? (
                        <div>Loading...</div>
                      ) : (
                        <table className="table tablebordered">
                          <thead className="table-header">
                            <tr>
                              <th scope="col" className="tableDataTh">
                                Member Id
                              </th>
                              <th scope="col" className="tableDataTh">
                                Status
                              </th>
                              <th scope="col" className="tableDataTh">
                                Date/Time of Payment
                              </th>
                              <th scope="col" className="tableDataTh">
                                Total Amount Paid
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {paginatedData?.length > 0 ? (
                              paginatedData?.map((item, index) => (
                                <tr key={index}>
                                  <td className="td-default">
                                    {item?.payout_member_id?.split("-").pop()}
                                  </td>
                                  <td className="td-default">{item?.status}</td>
                                  <td className="td-default">
                                    {convertToUTCUSA(item?.created_ts)}
                                  </td>
                                  <td className="td-default">
                                    ${item?.net / 100}
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td
                                  colSpan="5"
                                  style={{
                                    textAlign: "center",
                                    padding: "10px",
                                  }}
                                >
                                  Data Not Found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      )}
                    </div>
                    <div className="pagination">
                    <button
                        onClick={handlePreviousPagePayout}
                        disabled={currentPagePayout === 1}
                        className="nextPageButton"
                        style={{
                          backgroundColor: "#00c2d1",
                          borderRadius: "8px",
                        }}
                      >
                        Previous
                      </button>
                      <span style={{ position: "relative", top: "7px" }}>
                        Page {currentPagePayout} / {totalPagesPayout}
                      </span>
                      <button
                        onClick={handleNextPagePayout}
                        disabled={currentPagePayout === totalPagesPayout}
                        className="nextPageButton"
                        style={{
                          backgroundColor: currentPagePayout === totalPagesPayout ? "#ccc" : "#00c2d1",
                        }}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <Footer />
        </div>
      </div>
      <PopupDialog
        open={isPopupOpen}
        onClose={handleCloseDialog}
        title="Onboard New Staff"
      >
        <AddUserForm
          accessToken={accessToken}
          community_id={community_id}
          cancelHandler={handleCloseDialog}
        />
      </PopupDialog>
    </div>
    :<div>Loading...</div>}</>
  );
};

export default TipHistory;
