import { createSlice } from "@reduxjs/toolkit";
import { getCommunityReaderAction } from "../../api/api";

const GetCommunityReaderSlice = createSlice({
    name: 'communityReader',
    initialState: {
      deviceList: null,
      loading: false,
      error: null,
    },
    reducers: {
    },
    extraReducers: (builder) => {
      builder
        .addCase(getCommunityReaderAction.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(getCommunityReaderAction.fulfilled, (state, action) => {
          state.loading = false;
          state.deviceList = action.payload;
        })
        .addCase(getCommunityReaderAction.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        });
    },
  });
  
  export default GetCommunityReaderSlice.reducer;