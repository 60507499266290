import React from "react";
import "./resetPassword.css";
import LeftSideContent from "../../components/LeftSideContent/LeftSideContent";
import { Link } from "react-router-dom";

const ResetPassword = () => {
  return (
    <>
      <div className="forgetPasswordContainer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
              <LeftSideContent />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
              <div className="resetPasswordFormContainer">
                <div className="forgetPassword">Reset Password</div>
                <form>
                  <div className="forgetPasswordForm">
                    <div className="m-3">
                      <label htmlFor="password" className="loginLabel">
                        Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        aria-describedby="password"
                        placeholder="Enter password"
                      />
                    </div>
                    <div className="m-3">
                      <label htmlFor="password" className="loginLabel">
                        Confirm Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        aria-describedby="confirmPassword"
                        placeholder="Confirm your password"
                      />
                    </div>
                    <div className="groupButton btnDecoration">
                      <button type="submit" className="buttonSubmit">
                        Sign In
                      </button>
                    </div>
                    <div className="resetFooter">
                      <span className="resetFooterText">
                        Have you already reset the password ?
                      </span>{" "}
                      <Link to="/"> Sign in</Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
