import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import SideBar from "../../../components/SideBar/SideBar";
import Header from "../../../components/Header/Header";
import upicon from "../../../assets/images/Up.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../../../components/Footer/Footer";
import { AddAddressAction } from "../../../redux/api/api";

const CreateAccountAddress = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { laddAddressData, loading } = useSelector((state) => state.AddAddressStore);
  const accessToken = localStorage.getItem("accesstoken");
  const community_id = localStorage.getItem("communityId");

  const [addAddressForm, setAddAddressForm] = useState({
    address:"",
    city:"",
    state:"",
    zip_code:"",
    country:"US"
  })

  const handleInputChange = (e) => {
    const { name, value } = e.target;
  if (name === "zip_code" && !/^\d*$/.test(value)) {
    return; 
  }

  setAddAddressForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

//   const handleFormSubmit = (e) => {
//     e.preventDefault();
//     const payload = {...addAddressForm}; 
//     dispatch(AddAddressAction({ accessToken, payload }));
//   };


  const handleFormSubmit = async (e) => {
      e.preventDefault();
      const payload = {...addAddressForm}; 
      try {
        const response = await dispatch(AddAddressAction({ accessToken, community_id, payload }));
        if (AddAddressAction.fulfilled.match(response)) {
          toast.success("Form submitted successfully!");
          setTimeout(() => {
            navigate("/create-account-bank-detail");
          }, 2000);
        } else {
          toast.error(response?.payload?.detail || "Failed to submit form.");
        }
      } catch (error) {
        toast.error("An error occurred while submitting the form.");
      }
    };

    return(
    <div className="mainPageAddBankAccount mainLayoutPage">
      <ToastContainer />
      <SideBar />
      <div className="dashboard-container" style={{marginTop:'10px',marginRight:'10px',marginBottom:'10px', backgroundColor:"#f6f6f3",borderRadius:"20px"}}>
        <div className="header">
          <div>
            <div className="titleheading-content">Let's Create Account
              <div className="subtitle">
                <div className="current-page-text">Add Address</div>
              </div>
            </div>
            <div className="tabletScreenHeader">
              <Header />
            </div>
          </div>
        </div>
        <div className="top-line"></div>
        <div className="mainUserAdd">
          <form onSubmit={handleFormSubmit}>
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group p-2">
                  <label htmlFor="address">Address:</label>
                  <input
                    type="text"
                    className="form-control"
                    name="address"
                    required
                    value={addAddressForm.address}
                    onChange={handleInputChange}
                    placeholder="Enter your address"
                  />
                </div>
                <div className="form-group p-2">
                  <label htmlFor="city">City:</label>
                  <input
                    type="text"
                    className="form-control"
                    name="city"
                    required
                    value={addAddressForm.city}
                    onChange={handleInputChange}
                    placeholder="Enter Your City"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group p-2">
                  <label htmlFor="zip_code">Zip Code:</label>
                  <input
                    type="text"
                    className="form-control"
                    name="zip_code"
                    pattern="\d*"
                    required
                    value={addAddressForm.zip_code}
                    onChange={handleInputChange}
                    placeholder="Enter Your Zip Code"
                  />
                </div>
                <div className="form-group p-2">
                  <label htmlFor="state">State:</label>
                  <input
                    type="text"
                    className="form-control"
                    name="state"
                    required
                    value={addAddressForm.state}
                    onChange={handleInputChange}
                    placeholder="Enter Your State"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="addUserGroupButton addUserButtonDecoration">
                <div>
                  <button type="submit" className="buttonSubmitForgetPassword" disabled={loading}>
                    {loading ? "Continue..." : "Continue"} 
                  </button>
                </div>
                <div className="m-4">
                  <Link to="/create-account-bank-detail">
                    <button type="button" className="cancelledForgetPassword">
                      Skip
                    </button>
                  </Link>
                </div> 
              </div>
            </div>
          </form>
        </div>
       
        <div style={{marginTop:"20px"}}>
          <Footer />
        </div>

      </div>
    </div>
    )
}

export default CreateAccountAddress