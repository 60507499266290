import { createSlice } from "@reduxjs/toolkit";
import { getPaymentListAction } from "../../api/api";

const initialState = {
    paymentList: [],
    loading: false,
    error: null,
  };
  
  const GetPaymentListSlice = createSlice({
    name: 'payment',
    initialState,
    reducers: {
      
    },
    extraReducers: (builder) => {
      builder
        .addCase(getPaymentListAction.pending, (state) => {
          state.loading = true;
          state.error = null; 
        })
        .addCase(getPaymentListAction.fulfilled, (state, action) => {
          state.loading = false;
          state.paymentList = action.payload; 
        })
        .addCase(getPaymentListAction.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload; 
        });
    },
  });
  
  
  export default GetPaymentListSlice.reducer; 