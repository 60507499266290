import { createSlice } from "@reduxjs/toolkit";
import { addDeviceAction } from "../../api/api";

const addDeviceSlice = createSlice({
    name: 'addDevice',
    initialState: {
      data: null,
      loading: false,
      error: null,
    },
    reducers: {
      resetState: (state) => {
        state.data = null;
        state.loading = false;
        state.error = null;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(addDeviceAction.pending, (state) => {
          state.loading = true;
          state.error = null; 
        })
        .addCase(addDeviceAction.fulfilled, (state, action) => {
          state.loading = false;
          state.data = action.payload; 
        })
        .addCase(addDeviceAction.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload; 
        });
    },
  });
  
  export default addDeviceSlice.reducer;