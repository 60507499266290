import { createSlice } from "@reduxjs/toolkit";
import { accountStatusInfoAction } from "../../api/api";

const accountStatusSlice = createSlice({
    name: 'accountStatus',
    initialState: {
      data: null,          
      loading: false,      
      error: null,
    },
    reducers: {
      resetAccountStatus: (state) => {
        state.data = null;
        state.loading = false;
        state.error = null;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(accountStatusInfoAction.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(accountStatusInfoAction.fulfilled, (state, action) => {
          state.loading = false;
          state.data = action.payload;
        })
        .addCase(accountStatusInfoAction.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        });
    },
  });
  
export default accountStatusSlice.reducer;