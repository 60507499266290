import { createSlice } from "@reduxjs/toolkit";
import { memberInfoAction } from "../../api/api";

const memberInfoSlice = createSlice({
    name: 'memberInfo',
    initialState: {
      data: null,
      loading: false,
      error: null,
    },
    reducers: {
      // Add any synchronous actions if needed here
    //   resetMemberInfo: (state) => {
    //     state.data = null;
    //     state.loading = false;
    //     state.error = null;
    //   },
    },
    extraReducers: (builder) => {
      builder
        .addCase(memberInfoAction.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(memberInfoAction.fulfilled, (state, action) => {
          state.loading = false;
          state.data = action.payload;
        })
        .addCase(memberInfoAction.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        });
    },
  });
  
  export default memberInfoSlice.reducer;