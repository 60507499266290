import React, { useEffect, useState, useRef } from "react";
import "./header.css";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import profileImage from "../../assets/images/user-profile-pic.png";

const Header = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const dropdownRef = useRef(null); 

  const {data} = useSelector((state) => state.memberInfoStore)

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleSignOut = () => {
    localStorage.clear();
    navigate("/");
    window.location.reload();

  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="profile-menu" ref={dropdownRef}>
      <img
        src={profileImage}
        alt="profileImage"
        onClick={toggleDropdown}
        className="profile-image"
        style={{ cursor: "pointer" }}
      />

      {isDropdownOpen && (
        <div className="listOfProfile">
          <div className="subProfile">
            <div className="imageProfile">
              <img
                src={data?.profile_url || profileImage}
                alt="profileImage"
                onClick={toggleDropdown}
                className="profile-image"
                style={{ cursor: "pointer" }}
              />
            </div>
            <div className="profileContent">
              <div>
                <b>
                 {data?.name || "NA"}
                </b>
              </div>
              <div>{data?.email}</div>
            </div>
          </div>
          <ul>
            <li>
              <Link to="/my-profile">My Profile</Link>
            </li>
       
            {/* <li>
              <Link to="/account-setting">Account Settings</Link>
            </li> */}
            <li>
              <Link to="/" onClick={handleSignOut}>
                Sign Out
              </Link>
            </li>
          </ul>
        </div>
      )}
    </div>
    
  );
};

export default Header;
