import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./date.css";

const DatePickerComp = ({ onDateRangeChange }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleStartDateChange = (date) => {
    setStartDate(date);
    onDateRangeChange(date, endDate);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    onDateRangeChange(startDate, date); 
  };

  return (
    <div className="date-range-picker">
      <div style={{ display: "flex", alignItems: "center" }}>From</div>
      <div>
        <DatePicker
          className="customDate"
          selected={startDate}
          onChange={handleStartDateChange} 
          maxDate={new Date()} 
          dateFormat="MM/dd/yyyy" 
          placeholderText="mm/dd/yyyy" 
        />
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>To</div>
      <div>
        <DatePicker
          className="customDate"
          selected={endDate}
          onChange={handleEndDateChange}
          minDate={startDate} 
          maxDate={new Date()} 
          dateFormat="MM/dd/yyyy" 
          placeholderText="mm/dd/yyyy"
        />
      </div>
    </div>
  );
};

export default DatePickerComp;
