import React, { useEffect, useState } from "react";
import "./previewpayout.css";
import PopupDialog from "../../../components/Popup/PopupDialog";
import AddUserForm from "../../../components/UserForm/AddUserForm";
import SideBar from "../../../components/SideBar/SideBar";
import { useLocation, useNavigate } from "react-router-dom";
import userinvite from "../../../assets/images/userinvite.png";
import upicon from "../../../assets/images/Up.png";
import SelectCommunity from "../../../components/SelectCommunity/SelectCommunity";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import { Box, Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select, TextField} from "@mui/material";
import { getPartialPayoutAction, previewPayoutAction } from "../../../redux/api/api";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";

const PreviewPayout = () => {
  const dispatch = useDispatch()
  const location = useLocation();
  const { totalPendingPayout, FromData,ToData  } = location.state || {};
  const navigate = useNavigate();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { previewData } = useSelector((state) => state.PreviewPayoutStore);
  const { partialData } = useSelector((state) => state.PartialPayoutDataStore);
  const accessToken = localStorage.getItem("accesstoken");
  const community_id = localStorage.getItem("selectedCommunity");
  const [showTable, setShowTable] = useState(false); 
  const [searchValue, setSearchValue] = useState("");
  const [formData, setFormData] = useState({
    action: "preview",
    distribution_rule: "equalshare",
    tip_from_date: FromData, 
    tip_to_date: ToData, 
    membertype: "active",
    except_members: [],
  });
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; 
  const [localPartailData, setLocalPartailData] = useState(0); 
  const [sliderValues, setSliderValues] = useState([]); 
  const [sliderIndex, setSliderIndex] = useState(0); 
  const slideValueNew = sliderValues[sliderIndex];


  const handleOpenDialog = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handleCloseDialog = () => {
    setIsPopupOpen(false);
  };

  const handleHomeClick = () => {
    navigate("/dashboard");
  };

  const backToPayout = () => {
    navigate("/manage-payout");
  };

  const handlePreviewClick = () => {
    setShowTable(true);
     let payload = {
      action: formData.action,
      distribution_rule:formData.distribution_rule,
      tip_from_date: formData.tip_from_date,
      tip_to_date: slideValueNew,
      membertype: formData.membertype,
      except_members: formData.except_members
    }
    if (accessToken && community_id) {
      dispatch(previewPayoutAction({ accessToken, community_id, payload }));
    }
  };

  const handleBackClick = () => {
    setShowTable(false);
  };

  const handleSelectChange = (key, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]: value,
    }));

  if (key === "distribution_rule") {
    setCurrentPage(1); 
    // fetchPreviewData(value); 
  }
  };

// End Pagination Refresh after select dropdown other option


  const filteredData = previewData?.members
  ? previewData?.members?.filter((item) =>
      item?.mobile_number?.toLowerCase()?.includes((searchValue || "")?.toLowerCase())
    )
  : [];

const handleExcludeChange = (value) => {
  setFormData((prevData) => {
    const currentMembers = [...prevData.except_members];
    if (currentMembers.includes(value)) {
      return {
        ...prevData,
        except_members: currentMembers?.filter((member) => member !== value),
      };
    } else {
      return {
        ...prevData,
        except_members: [...currentMembers, value],
      };
    }
  });
};

useEffect(() => {
    const payload = { ...formData };
    if (accessToken && community_id) {
      dispatch(previewPayoutAction({ accessToken, community_id, payload }));
    }
}, [dispatch, accessToken, community_id]);

const handleQueueForExecutionClick = async () => {
  setFormData((prevData) => ({
    ...prevData,
    action: "save", 
  }));

const payload = {
    action: "save",
    distribution_rule: formData?.distribution_rule,
    tip_from_date: formData?.tip_from_date,
    tip_to_date: slideValueNew,
    membertype: formData?.membertype,
    except_members: formData?.except_members,
};

if (accessToken && community_id) {
    try {
      const result = await dispatch(previewPayoutAction({ accessToken, community_id, payload })).unwrap();
      toast.success(result?.detail);
      setTimeout(() => {
        navigate("/manage-payout")
      },5000)
    } catch (error) {
      toast.error(error?.detail || "An error occurred while processing the payout.");
    }
  }
};

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentPreviewPayoutList = previewData?.members?.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(previewData?.members?.length / itemsPerPage);

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };
 
  useEffect(() => {
    if (accessToken && community_id) {
      dispatch(getPartialPayoutAction({ accessToken, community_id }));
    }
  }, [accessToken, community_id, dispatch]);
  
  useEffect(() => {
    if (partialData) {
      const availableDates = partialData?.progress_payout?.map(payout => payout?.tip_to_date);
      setSliderValues(availableDates);
      const lastIndex = availableDates?.length - 1;
      setSliderIndex(lastIndex);
      const initialAmount = partialData?.progress_payout[lastIndex]?.amount;
      setLocalPartailData(initialAmount);
    }
  }, [partialData]);
  
  const handleSliderChange = (event) => {
    const selectedIndex = parseInt(event.target.value, 10);
    setSliderIndex(selectedIndex); 
    const selectedDate = sliderValues[selectedIndex];
    const selectedAmount = getAmountForDate(selectedDate); 
    setLocalPartailData(selectedAmount); 
  };
  
  const getAmountForDate = (selectedDate) => {
    const selectedPayout = partialData?.progress_payout?.find(payout => selectedDate === payout?.tip_to_date);
    return selectedPayout ? selectedPayout?.amount : 0; 
  };

  return (
    <>
      <ToastContainer />
      <div className="previewMainPage mainLayoutPage">
        <SideBar />
        <div className="dashboard-container borderFormat">
          <div className="header">
            <div>
              <div className="titleheading-content">
                Preview Payout
                <div className="subtitle">
                  <div className="home-text" onClick={handleHomeClick}>
                    <img src={upicon} alt="upIcon" className="home-text" /> Home /
                  </div>
                  <div className="home-text" onClick={backToPayout}>
                    Payout /
                  </div>
                  <div className="current-page-text">Preview Payout</div>
                </div>
              </div>
              <div className="tabletScreenHeader">
                <Header />
              </div>
            </div>
            <div className="dropdownMain mainDropdownTablet">
              <div className="mediumScreenHeader">
                <div className="header-button">
                  {" "}
                  <SelectCommunity />
                </div>
                <button className="onboard-button" onClick={handleOpenDialog}>
                  <span>Onboard New Staff</span>
                  <img src={userinvite} alt="logoUser" />
                </button>
              </div>
            </div>
          </div>
          <div className="top-line"></div>

          <div className="previewDetailMain">
            <div className="subPreviewDetail">
            <div className="initiatePayoutHeading">Initiate Payout</div>
              <div>
                <div className="popupPendingAmount">
                  <div className="popupAmountLabel">Pending Payout Amount:</div>
                  <div className="popupAmount">
                    <b>${totalPendingPayout || 0}</b>
                  </div>
                  <div style={{paddingLeft:'20px'}}>
                      <div className="price-range-container">
                        <div className="range-slider">
                          <input
                            type="range"
                            min="0"
                            max={sliderValues?.length - 1}
                            step="1"
                            value={sliderIndex}
                            className="slider-thumb"
                            onChange={handleSliderChange}
                            style={{
                              width: '300px',
                              cursor: 'pointer',
                              height: '12px',
                              background: `linear-gradient(
                                to right,
                                #007bff ${(sliderIndex / (sliderValues?.length - 1)) * 100}%,
                                #ddd ${(sliderIndex / (sliderValues?.length - 1)) * 100}%
                              )`,
                            }}
                          />
                          <div
                            className="slider-fill"
                            style={{
                              width: `${(sliderIndex / (sliderValues?.length - 1)) * 100}%`,
                            }}
                          ></div>
                        </div>
                        <p>Partial Payout Amount: ${localPartailData / 100}</p>
                      </div>


                    </div>
                </div>
                
                <div style={{display:'flex',width:'100%'}}>
                <div className="popupDistribution">
                  <div className="popupDistributionLabel" style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                    Distribution Rule:
                  </div>
                  <Box sx={{ minWidth: 200, height: 40 }}>
                    <FormControl fullWidth style={{height:"40px"}}>
                      <Select value={formData?.distribution_rule}
                          onChange={(e) => handleSelectChange("distribution_rule", e.target.value)}
                          sx={{ height: 40}}>
                          <MenuItem value="equalshare">Equal Share</MenuItem>
                          <MenuItem value="yougetyourcollection">You Get Your Collection</MenuItem>
                          <MenuItem value="custom">Custom</MenuItem>
                        </Select>
                    </FormControl>
                  </Box>
                </div>
                <div className="popupStaffInclude">
                  <div className="popupStaffLabel" style={{display:'flex', justifyContent:'center', alignItems:'center'}}>Staff To Include:</div>
                  <Box sx={{ minWidth: 200 }}>
                    <FormControl fullWidth>
                       <Select value={formData?.membertype}
                          onChange={(e) => handleSelectChange("membertype", e.target.value)}  sx={{ height: 40}}>
                          <MenuItem value="active">Active</MenuItem>
                          <MenuItem value="inactive">Inactive</MenuItem>
                          <MenuItem value="all">All</MenuItem>
                        </Select>
                    </FormControl>
                  </Box>
                </div>

                <div className="excludeStaff">
                  <div className="popupExcludeLabel" style={{width:'35%', display:'flex', justifyContent:'center', alignItems:'center'}}>Exclude Staff:</div>
                  <Box sx={{ minWidth: 200 }}>
                    <FormControl fullWidth >
                    <InputLabel id="exclude-select-small-label">Select</InputLabel>
                      <Select
                       sx={{ height: 40}}
                        labelId="exclude-select-small-label"
                        id="exclude-select-small-label"
                        label="Select"
                        multiple
                          value={formData?.except_members}
                          renderValue={(selected) => {
                            const selectedMobileNumbers = (filteredData || [])
                              .filter((item) => selected?.includes(item?.member_id))
                              .map((item) => item?.mobile_number);
                            return selectedMobileNumbers?.join(", ");
                          }}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxWidth:'210px',
                                maxHeight: '400px',
                                overflow: 'auto', 
                              },
                            },
                          }}
                        >
                          <MenuItem>
                            <TextField
                              placeholder="Search by mobile"
                              value={searchValue}
                              onChange={(e) => setSearchValue(e.target.value)}
                              fullWidth
                              InputProps={{
                                style: { height: '30px' }, 
                              }}
                            />
                          </MenuItem>
                          {filteredData?.length > 0 ? (
                            filteredData.map((item) => (
                              <MenuItem key={item?.member_id} value={item?.member_id} >
                                <Checkbox
                                  checked={formData.except_members.includes(item?.member_id)}
                                  onChange={() => handleExcludeChange(item?.member_id)}
                                 
                                />
                                <ListItemText sx={{width:'120px'}} primary={item?.mobile_number} secondary={item?.name} />
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem disabled>No members found</MenuItem>
                          )}
                        </Select>
                    </FormControl>
                  </Box>
                </div>
                </div>
              </div>
              <div className="popupButtonSection">
                <button className="cancelledPayoutBtn" onClick={() => navigate('/manage-payout')}>Cancel</button>
                <button className="applyButton" onClick={handlePreviewClick}>Preview</button>
              </div>
            </div>
          </div>
          {showTable && (
          <div className="table-responsive deviceTable">
                  <table className="table tablebordered table-hover mt-4">
                    <thead className="table-header" style={{borderRadius:'8px'}}>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Mobile Number</th>
                        <th scope="col">Net Payout due as per criteria above</th>
                        <th scope="col">Total Tips collected via this user</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                    {currentPreviewPayoutList?.length > 0 ? (
                        currentPreviewPayoutList?.map((item) => (
                          <tr key={item?.member_id}>
                            <td className="td-default-device">
                              {item.status === true ? (
                                <>
                                  <span
                                    className="green-dot"
                                    style={{ position: "relative", top: "1px" }}
                                    title="Active"
                                  ></span>
                                </>
                              ) : (
                                <span
                                  className="red-dot"
                                  style={{ position: "relative", top: "1px" }}
                                  title="Inactive"
                                ></span>
                              )}{" "}
                              {item?.name || "NA"}
                            </td>
                            <td className="td-default-device">{item?.mobile_number || "NA"}</td>
                            <td className="td-default-device">${item?.net / 100 || 0}</td>
                            <td className="td-default-device">${item?.tip_amount / 100 || 0}</td>
                            <td className="td-default-device">
                              <div>                              
                                {item?.status ? "Active" : "Inactive"}
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="5" style={{ textAlign: "center" }}>
                            Data Not Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>

                  <div className="pagination mt-4">
                    <button onClick={handlePreviousPage} disabled={currentPage === 1} className="nextPageButton" style={{backgroundColor:'#00c2d1',borderRadius:'8px'}}>
                      Previous
                    </button>
                    <span style={{paddingTop:"5px"}}>
                      Page {currentPage}
                    </span>
                    <button onClick={handleNextPage}  className="nextPageButton"     disabled={currentPreviewPayoutList?.length === 0 || previewData?.members?.length <= itemsPerPage || currentPage === totalPages}
                    >
                      Next
                    </button>
                  </div>

                  <div className="popupButtonSection">
                    <button className="cancelledPayoutBtn" onClick={handleBackClick}>Back</button>
                    <button className="applyButton" onClick={handleQueueForExecutionClick}>Queue For Execution</button>
                  </div>
            </div>
            )}

           
          <div style={{marginTop:'20px'}}>
            <Footer />
          </div>
        </div>
        <PopupDialog
          open={isPopupOpen}
          onClose={handleCloseDialog}
          title="Add New User" >
          <AddUserForm
            accessToken={accessToken}
            community_id={community_id}
            cancelHandler={handleCloseDialog}
          />
        </PopupDialog>
      </div>
    </>
  );
};

export default PreviewPayout;
