import { useState, useEffect, useRef } from "react";
import SideBar from "../../components/SideBar/SideBar";
import Header from "../../components/Header/Header";
import SelectCommunity from "../../components/SelectCommunity/SelectCommunity";
import { useDispatch, useSelector } from "react-redux";
import { getBankDetailProfile, profilePictureUploadAction, UpdateMemberInfoAction } from "../../redux/api/api";
import "../../pages/MyProfile/myprofile.css";
import Footer from "../../components/Footer/Footer";
import { useNavigate } from "react-router-dom";
import upicon from "../../assets/images/Up.png";
import userinvite from "../../assets/images/userinvite.png";
import PopupDialog from "../../components/Popup/PopupDialog";
import AddUserForm from "../../components/UserForm/AddUserForm";
import { toast, ToastContainer } from "react-toastify";
import { useReactToPrint } from "react-to-print";
import printerIcon from "../../assets/images/printer_icon.png"

const MyProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.memberInfoStore);
  const [isModalOpen, setModalOpen] = useState(false);
  const accessToken = localStorage.getItem("accesstoken");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const community_id = localStorage.getItem("selectedCommunity");
  const [addProfile, setAddProfile] = useState({
    name: "",
    email: "",
    mobile_number: "",

  });
  const [previewImage, setPreviewImage] = useState("");
  const fileInputBgRef = useRef(null); 

  const [isEditableProfile, setIsEditableProfile] = useState(false);
  const { bankDetailProfile } = useSelector((state) => state.GetBankDetailSingleProfileStore);
  const managed_by = localStorage.getItem("manage_by")
  const [bankdata, setBankData]= useState()
  const qrCodeData = data?.communities[0]?.qr_code_url
  const profilePic = data?.profile_url
  const componentRef = useRef();
  const [selectedFileOne, setSelectedFileOne] = useState(null); 
  const fileInputRef = useRef(null); 
  const [selectedFileName, setSelectedFileName] = useState('');

  useEffect(() => {
    dispatch(getBankDetailProfile({ accessToken }));
  }, [dispatch, accessToken, community_id]);

  useEffect(() => {
    if (data) {
      setAddProfile({
        name: data.name || "",
        email: data.email || "",
        mobile_number: data.mobile_number || "",
      });
    }
  }, [data]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAddProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const handleEditProfileClick = (e) => {
    e.preventDefault();
    setIsEditableProfile(true);
  };

  const handleSaveProfileClick = async (e) => {
    e.preventDefault();
   
    const response = await dispatch(UpdateMemberInfoAction({  accessToken, payload: addProfile,}));
    if (UpdateMemberInfoAction.fulfilled.match(response)) {
      toast.success(response.payload?.detail);
    }
    else {
      toast.error(response.payload?.detail || "Failed to update the Tip Amount.");
    }
    setIsEditableProfile(false);
  };

  const handleCancelProfileClick = (e) => {
    e.preventDefault();
    setIsEditableProfile(false);
  };

  const handleHomeClick = () => {
    navigate("/dashboard");
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFileName(file.name); // Update the file name state
      setSelectedFileOne(file);
  
      // Update the preview image
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewImage(reader.result); // Update the preview image state
      };
      reader.readAsDataURL(file);
    }
  };


  
  const handleProfilePhotoUpload = async () => {
    if (!selectedFileOne) {
      toast('Please select a file first!');
      return;
    }
  
    const payload = new FormData();
    payload.append('file', selectedFileOne);
    try {
      const result = await dispatch(profilePictureUploadAction({ accessToken, payload })).unwrap();
      toast('File uploaded successfully!');
  
      // Clear file input and file name
      setSelectedFileOne(null);
      setSelectedFileName('');
      if (fileInputBgRef.current) {
        fileInputBgRef.current.value = '';
      }
    } catch (error) {
      toast('Failed to upload file.');
    }
  };



  const handleOpenDialog = () => {
    setIsPopupOpen(!isPopupOpen);
  };
  const handleCloseDialog = () => {
    setIsPopupOpen(false);
  };

  const AddNewAccount = () => {
    navigate("/add-new-bank-account")
  }

  useEffect(() => {
    if(bankDetailProfile){
      setBankData(bankDetailProfile[0])
    }
  },[bankDetailProfile])

 
  const handleSavePdf = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Qr_Code_${
      community_id || "Unknown"
    }.pdf`, 
  });

  return (
    <>
      <div className="MyProfileMainPage mainLayoutPage">
        <ToastContainer /> 

        <SideBar />
        <div className="dashboard-container" style={{marginTop:'10px',marginRight:'10px',marginBottom:'10px', backgroundColor:"#f6f6f3",borderRadius:"20px"}} >
          <div className="header">
          <div >
            <div className="titleheading-content">Profile
              <div className="subtitle">
                <div className="home-text" onClick={handleHomeClick}>
                  <img src={upicon} alt='upIcon' /> Home /</div>
                <div className="current-page-text">Profile</div>
              </div>
            </div>
            <div className="tabletScreenHeader">
              <Header />
            </div>
          </div>
          <div className="dropdownMain mainDropdownTablet">
            <div className="mediumScreenHeader">

              <div className="header-button" > <SelectCommunity /></div>
              <button className="onboard-button" onClick={handleOpenDialog}>
                <span>Onboard New Staff</span>
                <img src={userinvite}  alt='logoUser' />
              </button>
            </div>
          </div>
         </div>
         
          <div className="top-line"></div>
          <div className="mainMyProfile">
            <div className="mainUserAdd" style={{ height: "25%" }}>
              <div className="userDetailHeading">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div className="subheader">Profile Details</div>
                  <div style={{ display: "flex" }}>
                    <div>
                      <div>
                        {isEditableProfile ? (
                          <div className="saveCancel">
                            <button
                              onClick={handleSaveProfileClick}
                              className="editBtnUser saveBtnAlign"
                              style={{ position: "relative", right: "20px",  marginLeft: "15px",}} >
                              Save
                            </button>
                            <button
                              onClick={handleCancelProfileClick}
                              className="btn btn-secondary" >
                              Cancel
                            </button>
                          </div>
                        ) : (
                          <button
                            onClick={handleEditProfileClick}
                            className="editBtnUser" >
                            Edit
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="row" style={{height:'auto'}}>
                  <div className="col-lg-3">
                    <div
                      className="card mt-4 profile-card"
                      style={{
                        height: "230px",
                        width: "220px", 
                        margin: "0 auto", 
                        position: "relative", 
                      }}>
                      <div className="card-body">
                        <div className="profile-image-container"
                          style={{ position: "relative" }} >
                          <img src={profilePic ||
                              "https://bootdey.com/img/Content/avatar/avatar6.png"
                            }
                            className="profile-image"
                            alt="Profile"
                            style={{ width: "100%", height: "auto" }}
                          />
                          <input
                            type="file"
                            accept="image/*"
                            className="file-input"
                            ref={fileInputBgRef} 
                            onChange={handleFileChange} />
                          <div className="overlay">
                            <span>Browse Photo</span>
                          </div>
                        </div>
                      
                      </div>
                      <div>
                          {selectedFileName && (
                            <span className="profileSelectText">
                              Selected File: <strong>{selectedFileName}</strong>
                            </span>
                          )}
                        </div>
                      <div>
                    </div>
                    </div>
                    <div style={{paddingTop:'30px',paddingLeft:'11%'}}>
                        <button className="editBtnUser" onClick={handleProfilePhotoUpload}>Upload</button>
                      </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="row">
                      <div className="col-lg-8">
                    <div className="form-group p-2">
                      <label htmlFor="name">Name:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        value={addProfile.name}
                        placeholder="Enter your first Name"
                        onChange={handleInputChange}
                        disabled={!isEditableProfile} />
                    </div>
                    </div>
                    </div>          
                    <div className="row">
                      <div className="col-lg-8">
                        <div className="form-group p-2">
                          <label htmlFor="email">Email:</label>
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            value={addProfile.email}
                            placeholder="Enter email"
                            onChange={handleInputChange}
                            disabled={!isEditableProfile} />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-8">
                        <div className="form-group p-2">
                          <label htmlFor="phone">Phone:</label>
                          <input
                            type="text"
                            className="form-control"
                            name="mobile_number"
                            value={addProfile.mobile_number}
                            placeholder="Enter your Phone"
                            onChange={handleInputChange}
                            disabled />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                  <div className="qrcodeLabel">
                    <div className="qrcodeSubLabel" >QR Code:</div>
                    <div>
                    <button
                      onClick={handleSavePdf}
                        style={{
                          backgroundColor: "transparent",
                          marginRight: "-22px",
                        }} >
                        <img src={printerIcon} alt="printerIcon" width={35} height={35}/>
                      </button>
                    </div>
                    </div>
                  <div ref={componentRef} className="container">
                    {qrCodeData ? (
                        <div onClick={() => setModalOpen(true)} style={{ cursor: "pointer" }}>
                            <img src={qrCodeData}  alt="QR Code" style={{width: '200px', height: '200px' }}  />
                        </div>
                        ) : ("NA")}
                    {isModalOpen && (
                        <div className="popup-overlay-profile" onClick={() => setModalOpen(false)}>
                          <div className="popup-modal-profile" onClick={(e) => e.stopPropagation()}>
                            <img
                              src={qrCodeData}
                              alt="QR Code"
                              style={{ maxWidth: "300px", height: "auto" }} />
                            <button className="close-button-profile" onClick={() => setModalOpen(false)}>
                              &times;
                            </button>
                          </div>
                        </div>
                      )}
                   </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Bank Details */}
          {managed_by  === "requiteplatform" && (
          <div className="mainMyProfileBankDetail mt-3">
            <div className="mainUserAdd" style={{ height: "15%" }}>
              <div className="userDetailHeading">
                <div style={{ display: "flex", justifyContent: "space-between" }} >
                  <div className="subheader">Bank Account Details</div>
                  <div style={{ display: "flex" }}>
                        <button onClick={AddNewAccount} className="editBtnUser">
                          Add
                        </button>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <div className="p-2" style={{display:'flex'}}>
                     <div className="col-lg-4">Account Number:</div>
                     <div className="col-lg-4">{bankdata?.account_number || "NA"}
                  </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="p-2" style={{display:'flex'}}>
                    <div className="col-lg-4">Bank Holder Name:</div>
                    <div className="col-lg-4">{bankdata?.account_name || "NA"}</div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                    <div className="p-2" style={{display:'flex'}}>
                      <div className="col-lg-4">Bank Name:</div>
                      <div className="col-lg-4">{bankdata?.bank_name || "NA"}</div>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="p-2" style={{display:'flex'}}>
                      <div className="col-lg-4">Routing Number:</div>
                      <div className="col-lg-4">{bankdata?.routing_number || "NA"}</div>
                    </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                <div className="p-2" style={{display:'flex'}}>
                      <div className="col-lg-4">Account Type:</div>
                      <div className="col-lg-4">{bankdata?.account_type || "NA"}</div>
                    </div>
                </div>
              </div>
            </div>
          </div>
          )}
          <div className="mt-4">
          <Footer />
          </div>
        </div>
        <PopupDialog
        open={isPopupOpen}
        onClose={handleCloseDialog}
        title="Onboard New Staff">
        <AddUserForm accessToken={accessToken} community_id={community_id} cancelHandler={handleCloseDialog} />
          </PopupDialog>
      </div>
    </>
  );
};

export default MyProfile;